<div class="app-header fixed-top w-100">
    <!-- menu mobile -->
    <nav class="menu-mobile d-block d-lg-none">
        <button
            type="button"
            id="sidebarCollapse"
            class="navbar-toggle collapsed"
            (click)="collapsedMobileMenu = !collapsedMobileMenu"
        >
            <img src="assets/icons/ico_menu.svg" width="24" height="24" alt="" />
        </button>

        <div class="d-lg-none">
            <a *ngIf="!collapsedMobileMenu" (click)="collapsedMobileMenu = true" class="collapse-menu-btn">
                <img src="assets/icons/ico-menu-collapse.svg" class="mx-2" />
            </a>
            <ul class="menu-mobile-wrapper collapsed" [ngClass]="{ collapsed: collapsedMobileMenu }">
                <li *ngIf="member">
                    <i class="icon-member-white"></i>
                    <span>{{ member?.full_name }}</span>
                </li>
                <li *ngIf="!member" (click)="signup(); collapsedMobileMenu = true">
                    <i class="icon-member-white"></i>
                    <span>เข้าสู่ระบบ</span>
                </li>
                <li (click)="collapsedMobileMenu = true" routerLinkActive="active" [routerLink]="['/sale']">
                    <i class="icon-online-booking-white"></i>
                    <span>Online Booking</span>
                </li>
                <li
                    *ngIf="member"
                    (click)="collapsedMobileMenu = true"
                    routerLinkActive="active"
                    [routerLink]="['/order/history']"
                >
                    <i class="icon-order-history-white"></i>
                    <span>ประวัติการจอง</span>
                </li>
                <li
                    *ngIf="member"
                    (click)="collapsedMobileMenu = true"
                    routerLinkActive="active"
                    [routerLink]="['/member/list']"
                >
                    <i class="icon-membership"></i>
                    <span>จัดการสมาชิก</span>
                </li>
                <li *ngIf="member" (click)="logout(); collapsedMobileMenu = true">
                    <i class="icon-logout-white"></i>
                    <span>ออกจากระบบ</span>
                </li>
            </ul>
        </div>
    </nav>

    <div class="app-header-menu py-1">
        <!-- mobile logo -->
        <div *ngIf="supplierInfo$ | async as supplierInfo" class="logo-mobile text-center py-2 d-block d-lg-none">
            <img class="logo-image" [src]="supplierInfo?.image_path" alt="" />
        </div>

        <div class="container-fluid px-0">
            <div class="d-none d-lg-block">
                <div class="d-flex align-items-center justify-content-between w-100">
                    <ul class="menu-info">
                        <li routerLinkActive="active" [routerLink]="['/sale']">
                            <span class="color-white twp-text-l">Online Booking</span>
                        </li>
                        <li *ngIf="member" routerLinkActive="active" [routerLink]="['/order/history']">
                            <span class="color-white twp-text-l">ประวัติการจอง</span>
                        </li>
                        <li *ngIf="member" routerLinkActive="active" [routerLink]="['/member/list']">
                            <span class="color-white twp-text-l">จัดการสมาชิก</span>
                        </li>
                    </ul>
                    <div class="user-info-wrapper">
                        <div *ngIf="member" class="user-info" (click)="showUserMenu = !showUserMenu">
                            <span class="username color-white twp-text-l">{{ member?.full_name }}</span>
                            <img class="avatar" src="assets/images/avatar_login.png" alt="" />
                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </div>
                        <div *ngIf="!member" class="user-info">
                            <span (click)="signup()" class="login color-white twp-text-l">เข้าสู่ระบบ</span>
                        </div>
                        <div *ngIf="showUserMenu" id="user-menu">
                            <a (click)="logout()" class="d-block">ออกจากระบบ</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end of app-header-menu -->

    <ng-container *ngIf="isHeaderShowBanner">
        <div
            *ngIf="showHeaderBannerWhenScroll"
            class="app-header-ws"
            style="background-image: url('assets/images/bg.jpg'); background-size: cover; background-position: center"
        >
            <div class="container-fluid px-0" *ngIf="supplierInfo$ | async as supplierInfo">
                <!--logo desktop -->
                <div class="logo d-none d-lg-block">
                    <div class="d-flex align-items-center">
                        <span class="mr-2 rounded-circle logo-wrapper">
                            <img class="mh-100" [src]="supplierInfo?.image_path" alt="" />
                        </span>
                        <span class="logo-name twp-text-xxl-bold">
                            {{ supplierInfo?.name_en }}
                        </span>
                    </div>
                </div>

                <!-- *ngIf="showFilter" -->
                <div *ngIf="isHeaderShowSearchCountrires" class="search ml-auto py-3 px-3">
                    <div class="twp-text-xxxl-bold color-blue-tourwow d-block d-lg-none text-center">
                        Online booking
                    </div>
                    <div class="logo d-block d-lg-none text-center my-2">
                        <span class="logo-name ml-3 twp-text-l-bold">{{ supplierInfo?.name_en }}</span>
                    </div>
                    <!--  -->
                    <div>
                        <div
                            class="search-dropdownlist position-relative pull-right d-inline-flex align-items-center mb-1"
                            #dropdownList
                        >
                            <input
                                class="search-input twp-text-xl p-3 border-0"
                                type="text"
                                placeholder="เลือกประเทศที่ต้องการค้นหา"
                                [value]="dropdownLabel"
                                (click)="toggleDropdown()"
                                readonly
                            />

                            <div
                                class="search-selection d-inline-flex align-items-center justify-content-center"
                                (click)="toggleDropdown()"
                            >
                                <img src="assets/icons/arrow-down.svg" alt="" width="16" height="10" />
                            </div>

                            <ul class="w-100 mt-0 bg-white position-absolute" [hidden]="!showDropdownItems">
                                <li (click)="selectDropdownItem(null)">
                                    <div class="twp-text-xl p-3">ทุกประเทศ</div>
                                </li>
                                <li *ngFor="let country of countries$ | async" (click)="selectDropdownItem(country)">
                                    <div class="twp-text-xl p-3">{{ country.name_th }}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!--  -->
                    <div>
                        <div
                            class="search-dropdownlist position-relative pull-right d-inline-flex align-items-center"
                            #dropdownList
                        >
                            <input
                                class="search-input twp-text-xl p-3 border-0"
                                type="text"
                                placeholder="เลือกโปรแกรมที่ต้องการค้นหา"
                                [value]="dropdownProgramLabel"
                                (click)="toggleProgramDropdown()"
                                readonly
                            />

                            <div
                                class="search-selection d-inline-flex align-items-center justify-content-center"
                                (click)="toggleProgramDropdown()"
                            >
                                <img src="assets/icons/arrow-down.svg" alt="" width="16" height="10" />
                            </div>

                            <ul class="w-100 mt-0 bg-white position-absolute" [hidden]="!showDropdownProgramItems">
                                <li (click)="selectDropdownProgramItem(null)">
                                    <div class="twp-text-xl p-3" style="line-height: 20px">โปรแกรมทัวร์ทั้งหมด</div>
                                </li>
                                <li
                                    *ngFor="let program of programs$ | async | sort: 'tour_code'"
                                    (click)="selectDropdownProgramItem(program)"
                                >
                                    <div class="twp-text-xl p-3" style="line-height: 20px">
                                        {{ program.tour_code }}: {{ program.name }}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="power-by ml-auto d-block d-lg-none text-center mt-3 text-white">
                        <span class="twp-text-m">Power by</span>&nbsp;<img
                            src="assets/images/logotwp-w-01.png"
                            width="78"
                            height="19"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- end of app-header-ws -->

        <div class="app-header-filter bg-white">
            <div class="app-header-filter-inner">
                <div class="booking-category d-flex">
                    <div class="d-none d-lg-block" *ngIf="supplierInfo$ | async as supplierInfo">
                        <div class="d-flex align-items-center h-100">
                            <span
                                class="mr-2 rounded-circle booking-category-logo-wrapper"
                                *ngIf="!showHeaderBannerWhenScroll"
                            >
                                <img class="mh-100" [src]="supplierInfo?.image_path" alt="" />
                            </span>
                            <span class="booking-category-label twp-text-xxl-bold">
                                Online Booking
                                {{ isHeaderShowSearchResultTabs ? ':' : '' }}
                            </span>
                        </div>
                    </div>
                </div>
                <!-- powerby -->
                <div class="power-by ml-auto d-none d-lg-block">
                    <span class="twp-text-m">Power by</span>&nbsp;<img
                        src="assets/images/logo-twp-top.svg"
                        width="91"
                        height="21"
                        alt="Tourwow Pro Online Booking {{ version }}"
                    />
                </div>
            </div>
        </div>

        <div class="app-header-filter bg-light" *ngIf="isHeaderShowCountrySelector">
            <div class="app-header-filter-inner d-block">
                <app-country-selector></app-country-selector>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isHeaderShowBreadcrumb">
        <div class="order-booking-nav box">
            <app-breadcrumb-v2 [supplierInfo$]="supplierInfo$" [breadcrumbText]="breadcrumbText"></app-breadcrumb-v2>
        </div>
    </ng-container>
</div>
