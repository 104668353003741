<!-- add-home -->
<div class="home-filter-result color-blue-tourwow twp-text-l-bold py-2 mb-3 px-4 mt-4">
    <div class="row">
        <div class="col-12">
            ผลการค้นหา:
            <span *ngIf="(searchCriteria$ | async) && (searchCriteria$ | async).countryText">
                {{ (searchCriteria$ | async).countryText }},
            </span>
            <span *ngIf="joinTourTotal$ | async"> {{ productCate }} พบ {{ joinTourTotal$ | async }} โปรแกรม </span>
            <span class="d-none" *ngIf="periodTotal"> {{ periodTotal }} พีเรียด </span>
        </div>
    </div>
</div>

<div
    class="program-container"
    infiniteScroll
    [infiniteScrollDistance]="0.1"
    [infiniteScrollThrottle]="1000"
    (scrolled)="onScrollDown()"
>
    <div class="" *ngFor="let program of joinTourList$ | async; let programIndex = index">
        <app-program-box [program]="program" [programIndex]="programIndex"></app-program-box>
    </div>
</div>
