import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-quantity-textbox-v2',
    templateUrl: './quantity-textbox-v2.component.html',
    styleUrls: ['./quantity-textbox-v2.component.scss'],
})
export class QuantityTextboxComponentV2 {
    @Input() quantity: number;
    @Input() min = 0;
    @Input() max = 9999;
    @Input() disabled = false;
    @Input() hidden = false;
    @Input() maxlength = 10;
    @Output() quantityChange = new EventEmitter();

    constructor() {}

    add(): void {
        if (this.quantity < this.max && !this.disabled) {
            this.quantity++;
            this.quantityChange.emit(this.quantity);
        }
    }

    subtract(): void {
        if (this.quantity > this.min && !this.disabled) {
            this.quantity--;
            this.quantityChange.emit(this.quantity);
        }
    }

    keydown(e: any): void {
        if (+e > this.max) {
            e = this.max;
        }

        this.quantity = +e;
        this.quantityChange.emit(+this.quantity);
    }
}
