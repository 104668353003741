import { ISignupAgency } from 'app/shared/models/signup-agency';

export interface SignupState {
    agency: ISignupAgency;
    isLoading?: boolean;
    isSignupCompleted?: boolean;
    error?: any;
}

export const initialState: SignupState = {
    agency: null,
    isLoading: false,
    isSignupCompleted: false,
    error: null,
};
