import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './reducer';
import { SupplierStoreEffects } from './effects';

export const FEATURE_NAME = 'supplier';
@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(FEATURE_NAME, reducer),
        EffectsModule.forFeature([SupplierStoreEffects]),
    ],
    providers: [SupplierStoreEffects],
})
export class SupplierStoreModule {}
