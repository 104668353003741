import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

import { IBankAccount } from 'app/shared/models/bank-account.model';
import { IPayment } from 'app/shared/models/payment.model';
import { FileUploadService } from 'app/services/file-upload.service';
import { PaymentService } from 'app/services/payment.service';
import { AlertModalIcons, AlertModalComponent } from 'app/components/modal/alert-modal/alert-modal.component';
import { take } from 'rxjs/operators';
import { ActionLoadOrder } from 'app/root-store/order-store/actions';
import { Store } from '@ngrx/store';
import { State } from 'app/root-store/state';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Instalment } from 'app/shared/models/instalment.model';
@Component({
    selector: 'app-payment-modal',
    templateUrl: './payment-modal.component.html',
    styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent implements OnInit {
    payment: IPayment;
    instalments: Instalment[];
    banks: IBankAccount[];
    orderId: any;

    invoiceCode: any;
    programName: string;
    passengerCount: number;
    customerContact: string;

    form: UntypedFormGroup;
    confirmCheckbox = false;

    image: any;
    temporaryImageId: any;
    uploadingImage = false;
    uploadImageInvalidType = false;
    uploadImageOversize = false;
    uploadingImageWarning = false;
    submitted = false;

    public PAYMENT_TYPE_CASH = 'cash';
    public PAYMENT_TYPE_CHEQUE = 'cheque';
    public PAYMENT_TYPE_CREDIT_CARD = 'credit';
    public PAYMENT_TYPE_TRANSFER = 'transfer';

    constructor(
        private fb: UntypedFormBuilder,
        private bsModalRef: BsModalRef,
        private paymentService: PaymentService,
        private modalService: BsModalService,
        private fileUploadService: FileUploadService,
        private ngxUiLoaderService: NgxUiLoaderService,
        public store: Store<State>
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            payment_method: new UntypedFormControl('transfer'),
            bank_accounts_id: new UntypedFormControl(null, Validators.required),
            total: new UntypedFormControl(null, [Validators.required, Validators.min(1)]),
            payment_installments_ordinal: new UntypedFormControl(1),
            paid_at: new UntypedFormControl(null),
            note: new UntypedFormControl(null),
        });
    }

    closeModal() {
        this.bsModalRef.hide();
    }

    onConfirmCheckboxChange() {
        if (this.confirmCheckbox) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    confirm() {
        this.submitted = true;
        if (this.form.valid) {
            let body = Object.assign(this.form.value, {
                temp_slip_id: this.temporaryImageId || null,
            });
            if (body.paid_at) {
                body.paid_at = moment(body.paid_at).format('YYYY-MM-DD');
            }

            this.ngxUiLoaderService.start();
            this.paymentService.createPaymentTransaction(this.orderId, body).subscribe(
                (resp) => {
                    if (resp.status === 'success') {
                        this.closeModal();
                        this.showModalPaymentSuccess();
                        this.modalService.onHide.pipe(take(1)).subscribe((_) => {
                            this.store.dispatch(new ActionLoadOrder(this.orderId));
                        });
                        this.ngxUiLoaderService.stop();
                    } else {
                        this.ngxUiLoaderService.stop();
                        this.showModalPaymentFail();
                    }
                },
                (_) => {
                    this.ngxUiLoaderService.stop();
                    this.showModalPaymentFail();
                }
            );
        }
    }

    private showModalPaymentSuccess() {
        const initialState = {
            description: 'บันทึกข้อมูลการชำระเงินเรียบร้อยแล้ว',
            icon: AlertModalIcons.SUCCESS,
        };
        this.modalService.show(AlertModalComponent, Object.assign({ initialState }, { class: 'modal-sm' }));

        // this.store.dispatch(new ActionLoadOrder(this.order.proOrderId));
    }
    private showModalPaymentFail() {
        const initialState = {
            description: 'บันทึกข้อมูลการชำระเงินไม่สำเร็จ',
            icon: AlertModalIcons.ALERT,
        };
        this.modalService.show(AlertModalComponent, Object.assign({ initialState }, { class: 'modal-sm' }));
    }

    public onUploadImage(event) {
        if (!this.confirmCheckbox) {
            const file: File = event.target.files[0];
            const myReader: FileReader = new FileReader();
            const isTypeOk = file.type.indexOf('jpeg') !== -1 || file.type.indexOf('png') !== -1;

            this.uploadImageInvalidType = false;
            this.uploadImageOversize = false;

            if (isTypeOk) {
                myReader.onloadend = (e) => {
                    if (file.size / 1024 >= 10240) {
                        this.uploadImageOversize = true;
                        return;
                    }

                    const base64 = myReader.result;
                    const originalFile = file;
                    this.uploadingImage = true;
                    this.fileUploadService.upload(base64, originalFile.name).subscribe((response) => {
                        if (response.status === 'success') {
                            this.temporaryImageId = response.data.id;
                            this.image = myReader.result;
                        }
                        this.uploadingImage = false;
                    });
                };
                myReader.readAsDataURL(file);
            } else {
                this.uploadImageInvalidType = true;
            }
        }
    }

    removeFile() {
        if (!this.confirmCheckbox) {
            this.image = null;
            this.temporaryImageId = null;
            this.uploadingImage = false;
            this.uploadImageInvalidType = false;
            this.uploadImageOversize = false;
            this.uploadingImageWarning = false;
        }
    }
}
