<div class="app-root">
    <app-header
        [isHeaderShowBreadcrumb]="isHeaderShowBreadcrumb"
        [isHeaderShowBanner]="isHeaderShowBanner"
        [isHeaderShowSearchCountrires]="isHeaderShowSearchCountrires"
        [isHeaderShowSearchResultTabs]="isHeaderShowSearchResultTabs"
        [isHeaderShowCountrySelector]="isHeaderShowCountrySelector"
    >
    </app-header>

    <div id="wrapper" class="wrapper">
        <router-outlet></router-outlet>
    </div>
    <app-footer *ngIf="isFooterShow"></app-footer>
</div>
