import { Action } from '@ngrx/store';

export enum ActionTypes {
    LOAD_COUNTRY_DEALS = '[Promotion] Load Country Deals',
    LOAD_COUNTRY_DEALS_SUCCESS = '[Promotion] Load Country Deals Success',
    LOAD_PROGRAM_DEALS = '[Promotion] Load Program Deals',
    LOAD_PROGRAM_DEALS_SUCCESS = '[Promotion] Load Program Deals Success',
    LOAD_PROMOTIONS = '[Promotion] Load Promotions',
    LOAD_PROMOTIONS_SUCCESS = '[Promotion] Load Promotions Success',
    FAILURE = '[Promotion] Failure',
}

export class ActionLoadCountryDeals implements Action {
    readonly type = ActionTypes.LOAD_COUNTRY_DEALS;
    constructor(public clearList = false) {}
}

export class ActionLoadCountryDealsSuccess implements Action {
    readonly type = ActionTypes.LOAD_COUNTRY_DEALS_SUCCESS;
    constructor(public payload: { items: any[] }, public clearList = false) {}
}

export class ActionLoadProgramDeals implements Action {
    readonly type = ActionTypes.LOAD_PROGRAM_DEALS;
    constructor(public criteria, public clearList = false) {}
}

export class ActionLoadProgramDealsSuccess implements Action {
    readonly type = ActionTypes.LOAD_PROGRAM_DEALS_SUCCESS;
    constructor(public payload: { items: any[] }, public clearList = false) {}
}

export class ActionLoadPromotions implements Action {
    readonly type = ActionTypes.LOAD_PROMOTIONS;
    constructor(public criteria, public clearList = false) {}
}

export class ActionLoadPromotionsSuccess implements Action {
    readonly type = ActionTypes.LOAD_PROMOTIONS_SUCCESS;
    constructor(public payload: { items: any[]; total: number }, public clearList = false) {}
}

export class ActionFailure implements Action {
    readonly type = ActionTypes.FAILURE;
    constructor(public error: any) {}
}

export type Actions =
    | ActionLoadCountryDeals
    | ActionLoadCountryDealsSuccess
    | ActionLoadProgramDeals
    | ActionLoadProgramDealsSuccess
    | ActionLoadPromotions
    | ActionLoadPromotionsSuccess
    | ActionFailure;
