import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CountrySelectorService {
    private _countriesChanged$ = new BehaviorSubject<any[]>([]);
    private _countrySelected$ = new Subject<string>();
    private _countries: any[];
    private _selectedCountry: any;

    public countriesChanged$ = this._countriesChanged$.asObservable();
    public countrySelected$ = this._countrySelected$.asObservable();

    private _programsChanged$ = new BehaviorSubject<any[]>([]);
    private _programSelected$ = new Subject<string>();
    private _programs: any[];
    private _selectedProgram: any;

    public programsChanged$ = this._programsChanged$.asObservable();
    public programSelected$ = this._programSelected$.asObservable();

    public addCountries(listView: any[]): void {
        let preValue = [{ id: null, name: 'ทุกประเทศ' }];
        this._countries = preValue.concat(listView);
        this._countriesChanged$.next(this._countries.slice());
    }
    public broadcastCountryId(country: any): void {
        this._selectedCountry = country;
        this._countrySelected$.next(country.id ? country.id + '' : '');
    }

    public addPrograms(listView: any[]): void {
        let preValue = [
            { id: null, name: `โปรแกรมทัวร์${this._selectedCountry.id ? this._selectedCountry.name : ''}ทั้งหมด` },
        ];
        this._programs = preValue.concat(listView);
        this._programsChanged$.next(this._programs.slice());
    }
    public broadcastProgramId(program: any): void {
        this._selectedProgram = program;
        this._programSelected$.next(program.id ? program.id + '' : '');
    }
}
