import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap, take } from 'rxjs/operators';
import { AlertModalComponent, AlertModalIcons } from 'app/components/modal/alert-modal/alert-modal.component';
import { AuthService } from 'app/services/auth.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Store, select } from '@ngrx/store';
import { State } from 'app/root-store/state';
import { selectSupplierInfo } from 'app/root-store/supplier-store/selectors';
import { filter } from 'rxjs/operators';
import { SUPPLIER } from 'app/constants/supplier.constant';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
    form: UntypedFormGroup;
    username: any;
    submitted = false;
    passwordNotMatch = false;
    code: string;

    constructor(
        private store: Store<State>,
        private router: Router,
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private ngxUiLoaderService: NgxUiLoaderService,
        private modalService: BsModalService,
        private authService: AuthService
    ) {}

    ngOnInit() {
        this.route.queryParamMap.subscribe(
            (email) => {
                this.username = email;
            },
            () => {
                this.codeTimeOut();
            }
        );

        this.form = this.fb.group({
            password: [null, Validators.required],
            confirmPassword: [null, Validators.required],
        });
    }

    codeTimeOut() {
        const initialState = {
            description: 'ขออภัยไม่สามารถทำรายการได้',
            icon: AlertModalIcons.ALERT,
        };

        this.modalService.onHide.subscribe(() => {
            this.router.navigate(['/']);
        });
    }

    submit() {
        this.submitted = true;
        if (this.form.get('password').value !== this.form.get('confirmPassword').value) {
            this.passwordNotMatch = true;
        } else {
            this.passwordNotMatch = false;
            if (this.form.valid) {
                this.ngxUiLoaderService.start();
            }
        }
    }

    private redirectToDefaultPage() {
        this.store
            .pipe(
                select(selectSupplierInfo),
                filter((val) => val !== null),
                take(1)
            )
            .subscribe((res) => {
                switch (res.default_tab) {
                    case SUPPLIER.DEFAULT_TAB.DEAL:
                        this.router.navigateByUrl('/sale/promotions');
                        break;
                    case SUPPLIER.DEFAULT_TAB.JOIN:
                        this.router.navigateByUrl('/sale');
                        break;
                }
            });
    }
}
