import { Component, Input } from '@angular/core';
import { IPayment } from 'app/shared/models/payment.model';
import { PaymentService } from 'app/services/payment.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertModalIcons, AlertModalComponent } from 'app/components/modal/alert-modal/alert-modal.component';
import { PAYMENT_DEFAULT_SLIP } from 'app/constants/payment.constant';

@Component({
    selector: 'app-payment-history',
    templateUrl: './payment-history.component.html',
    styleUrls: ['./payment-history.component.scss'],
})
export class PaymentHistoryComponent {
    @Input() orderId: any;
    @Input() payment: IPayment;
    PAYMENT_TYPE_CASH = 'cash';
    PAYMENT_TYPE_CHEQUE = 'cheque';
    PAYMENT_TYPE_CREDIT_CARD = 'credit';
    PAYMENT_TYPE_TRANSFER = 'transfer';

    constructor(
        private paymentService: PaymentService,
        private modalService: BsModalService,
        private ngxUiLoaderService: NgxUiLoaderService
    ) {}

    deletePaymentTransaction(paymentId) {
        this.ngxUiLoaderService.start();
        this.paymentService.deletePaymentTransaction(this.orderId, paymentId).subscribe(
            (resp) => {
                this.ngxUiLoaderService.stop();
                if (resp.status === 'success') {
                    this.showModalDeletePaymentSuccess();
                    this.payment.transections = this.payment.transections.filter((p) => p.id !== paymentId);
                } else {
                    this.showModalDeletePaymentFail();
                }
            },
            (_) => {
                this.ngxUiLoaderService.stop();
                this.showModalDeletePaymentFail();
            }
        );
    }

    displaySlipImage(slipPath: string): void {
        let image = new Image();
        image.src = slipPath || PAYMENT_DEFAULT_SLIP;
        let win = window.open('');
        win.document.write(image.outerHTML);
        win.document.close();
    }

    private showModalDeletePaymentSuccess() {
        const initialState = {
            description: 'ลบข้อมูลการชำระเงินเรียบร้อยแล้ว',
            icon: AlertModalIcons.SUCCESS,
        };
        this.modalService.show(AlertModalComponent, Object.assign({ initialState }, { class: 'modal-sm' }));
    }

    private showModalDeletePaymentFail() {
        const initialState = {
            description: 'ลบข้อมูลการชำระเงินไม่สำเร็จ',
            icon: AlertModalIcons.ALERT,
        };
        this.modalService.show(AlertModalComponent, Object.assign({ initialState }, { class: 'modal-sm' }));
    }
}
