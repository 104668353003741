import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TemplateModule } from 'app/components/template/template.module';

// Modals
import { LoginModalComponent } from 'app/components/modal/login-modal/login-modal.component';
import { AlertModalComponent } from 'app/components/modal/alert-modal/alert-modal.component';
import { PreviewPdfModalComponent } from 'app/shared/components/preview-pdf-modal/preview-pdf-modal.component';
import { CancelOrderModalComponent } from 'app/components/modal/cancel-order-modal/cancel-order-modal.component';
import { InputEmailContactModalComponent } from 'app/components/modal/input-email-contact-modal/input-email-contact-modal.component';

// Inputs
import { QuantityTextboxComponent } from 'app/components/input/quantity-textbox/quantity-textbox.component';
import { QuantityTextboxComponentV2 } from 'app/components/input/quantity-textbox-v2/quantity-textbox-v2.component';

// Pipes
import { SafePipe } from 'app/shared/pipes/report.pipe';

// Modules
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxCurrencyModule } from 'ngx-currency';
import { ClipboardModule } from 'ngx-clipboard';
import { DeviceDetectorService } from 'ngx-device-detector';

// Third Party
import { TabsModule } from 'ngx-bootstrap/tabs';
import { Base64Util } from 'app/shared/utils/base64.util';

// Pipes
import {
    DatePeriodPipe,
    ThaiDateRangePipe,
    ThaiDateFormatPipe,
    ThaiMonthDatePipe,
    ThaiMonthDatetimePipe,
} from 'app/shared/pipes/thaidate.pipe';
import { CurrencyNumberPipe, MycurrencyPipe } from 'app/shared/pipes/money.pipe';
import { StarComponent } from 'app/shared/components/star/star.component';
import { RouterModule } from '@angular/router';
import {
    NextPeriodMonthlyPipe,
    NextPeriodMonthlyLengthPipe,
    NextPeriodMonthlyListPipe,
    ProgramGoFlightNumberPipe,
    ProgramBackFlightNumberPipe,
    ReOrderPeriodSKUsPipe,
    FilterPeriodPriceSKUsPipe,
} from 'app/shared/pipes/period.pipe';
import { SortPipe } from 'app/shared/pipes/sort.pipe';
import { MobileNumberFormatPipe } from 'app/shared/pipes/tel-format.pipe';
import { PhoneNumberPipe } from 'app/shared/pipes/phone-number.pipe';

// Component Temporary
import { OnlynumberDirective, MycurrencyDirective } from 'app/shared/directives/only-number.directive';
import { PaymentModalComponent } from './order/components/modal/payment-modal/payment-modal.component';
import { PaymentHistoryComponent } from './order/components/payment-history/payment-history.component';
import { PaymentHistoryModalComponent } from './order/components/modal/payment-history-modal/payment-history-modal.component';
import { TourwowDatePickerComponent } from 'app/shared/components/datepicker/datepicker.component';
import { CountrySelectorComponent } from 'app/shared/components/country-selector/country-selector.component';
import { ProgramListComponent } from './sale/components/program-list/program-list.component';
import { ProgramBoxComponent } from './sale/components/program-box/program-box.component';
import { PeriodBoxComponent } from './sale/components/period-box/period-box.component';

@NgModule({
    declarations: [
        QuantityTextboxComponent,
        QuantityTextboxComponentV2,
        StarComponent,
        // Modals
        LoginModalComponent,
        AlertModalComponent,
        SafePipe,
        PreviewPdfModalComponent,
        CancelOrderModalComponent,
        InputEmailContactModalComponent,
        // Pipes
        DatePeriodPipe,
        ThaiDateRangePipe,
        ThaiDateFormatPipe,
        ThaiMonthDatetimePipe,
        CurrencyNumberPipe,
        MycurrencyPipe,
        PhoneNumberPipe,
        ThaiMonthDatePipe,
        NextPeriodMonthlyPipe,
        NextPeriodMonthlyLengthPipe,
        NextPeriodMonthlyListPipe,
        ProgramGoFlightNumberPipe,
        ProgramBackFlightNumberPipe,
        ReOrderPeriodSKUsPipe,
        FilterPeriodPriceSKUsPipe,
        SortPipe,
        MobileNumberFormatPipe,
        OnlynumberDirective,
        MycurrencyDirective,
        // Component Temp
        ProgramListComponent,
        ProgramBoxComponent,
        PeriodBoxComponent,
        PaymentModalComponent,
        PaymentHistoryModalComponent,
        PaymentHistoryComponent,
        TourwowDatePickerComponent,
        CountrySelectorComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TemplateModule,
        InfiniteScrollModule,
        BsDatepickerModule.forRoot(),
        TabsModule.forRoot(),
        ModalModule,
        NgxCurrencyModule,
        ClipboardModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
        TabsModule,
        ModalModule,
        InfiniteScrollModule,
        QuantityTextboxComponent,
        QuantityTextboxComponentV2,
        PreviewPdfModalComponent,
        StarComponent,
        ClipboardModule,
        // Pipes
        DatePeriodPipe,
        ThaiDateRangePipe,
        ThaiDateFormatPipe,
        ThaiMonthDatetimePipe,
        CurrencyNumberPipe,
        MycurrencyPipe,
        PhoneNumberPipe,
        SafePipe,
        ThaiMonthDatePipe,
        NextPeriodMonthlyPipe,
        NextPeriodMonthlyLengthPipe,
        NextPeriodMonthlyListPipe,
        ProgramGoFlightNumberPipe,
        ProgramBackFlightNumberPipe,
        ReOrderPeriodSKUsPipe,
        FilterPeriodPriceSKUsPipe,
        SortPipe,
        MobileNumberFormatPipe,
        OnlynumberDirective,
        MycurrencyDirective,
        // Component Temp
        ProgramListComponent,
        ProgramBoxComponent,
        PeriodBoxComponent,
        PaymentModalComponent,
        PaymentHistoryModalComponent,
        TourwowDatePickerComponent,
        CountrySelectorComponent,
    ],
    providers: [DecimalPipe, ThaiDateFormatPipe, ThaiMonthDatetimePipe, Base64Util, DeviceDetectorService]
})
export class SharedModule {}
