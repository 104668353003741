import { Action } from '@ngrx/store';
import { Order } from 'app/shared/models/order.model';

export enum ActionTypes {
    LOAD_ORDER = '[Order] Load Order',
    LOAD_ORDER_SUCCESS = '[Order] Load Order Success',
    LOAD_ORDER_HISTORY = '[Order] Load Order History',
    LOAD_ORDER_HISTORY_SUCCESS = '[Order] Load Order History Success',
    ORDER_DRAFT_CHANGE = '[Order] Order Draft Change',
    PERIOD_DRAFT_CHANGE = '[Order] Period Draft Change',
    CONFIRM_CREATE_ORDER = '[Order] Confirm Create Order',
    CREATE_ORDER = '[Order] Create Order',
    CREATE_ORDER_SUCCESS = '[Order] Create Order Success',
    CREATE_ORDER_FAILED = '[Order] Create Order Failed',
    CANCEL_ORDER = '[Order] Cancel Order.',
    CANCEL_ORDER_SUCCESS = '[Order] Cancel Order Success.',
    FAILURE = '[Order] Failure',
}

export class ActionLoadOrder implements Action {
    readonly type = ActionTypes.LOAD_ORDER;
    constructor(public id: any) {}
}

export class ActionLoadOrderSuccess implements Action {
    readonly type = ActionTypes.LOAD_ORDER_SUCCESS;
    constructor(public payload: { order: Order }) {}
}

export class ActionLoadOrderHistory implements Action {
    readonly type = ActionTypes.LOAD_ORDER_HISTORY;
    constructor(public criteria, public clearList = false, public orderStatus = null) {}
}

export class ActionLoadOrderHistorySuccess implements Action {
    readonly type = ActionTypes.LOAD_ORDER_HISTORY_SUCCESS;
    constructor(public payload: { total: number; orders: Order[] }, public clearList = false) {}
}

export class ActionOrderDraftChange implements Action {
    readonly type = ActionTypes.ORDER_DRAFT_CHANGE;
    constructor(public payload: any) {}
}

export class ActionPeriodDraftChange implements Action {
    readonly type = ActionTypes.PERIOD_DRAFT_CHANGE;
    constructor(public payload: any) {}
}

export class ActionConfirmCreateOrder implements Action {
    readonly type = ActionTypes.CONFIRM_CREATE_ORDER;
    constructor(public payload: boolean) {}
}

export class ActionCreateOrder implements Action {
    readonly type = ActionTypes.CREATE_ORDER;
    constructor(public payload: any) {}
}

export class ActionCreateOrderSuccess implements Action {
    readonly type = ActionTypes.CREATE_ORDER_SUCCESS;
    constructor(public payload: { orderId: number; orderMessage: string }) {}
}

export class ActionCreateOrderFailed implements Action {
    readonly type = ActionTypes.CREATE_ORDER_FAILED;
    constructor(public payload: { info: any }) {}
}

export class ActionCancelOrder implements Action {
    readonly type = ActionTypes.CANCEL_ORDER;
    constructor(public id: number) {}
}

export class ActionCancelOrderSuccess implements Action {
    readonly type = ActionTypes.CANCEL_ORDER_SUCCESS;
    constructor(public id: number) {}
}

export class ActionFailure implements Action {
    readonly type = ActionTypes.FAILURE;
    constructor(public error: any) {}
}

export type Actions =
    | ActionLoadOrder
    | ActionLoadOrderSuccess
    | ActionLoadOrderHistory
    | ActionLoadOrderHistorySuccess
    | ActionOrderDraftChange
    | ActionPeriodDraftChange
    | ActionConfirmCreateOrder
    | ActionCreateOrder
    | ActionCreateOrderSuccess
    | ActionCreateOrderFailed
    | ActionCancelOrder
    | ActionCancelOrderSuccess
    | ActionFailure;
