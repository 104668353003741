import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { IPayment } from 'app/shared/models/payment.model';
@Component({
    selector: 'app-payment-history-modal',
    templateUrl: './payment-history-modal.component.html',
    styleUrls: ['./payment-history-modal.component.scss'],
})
export class PaymentHistoryModalComponent {
    payment: IPayment;
    orderId: any;
    invoiceCode: any;
    programName: string;
    passengerCount: number;
    customerContact: string;

    PAYMENT_TYPE_CASH = 'cash';
    PAYMENT_TYPE_CHEQUE = 'cheque';
    PAYMENT_TYPE_CREDIT_CARD = 'credit';
    PAYMENT_TYPE_TRANSFER = 'transfer';

    constructor(private bsModalRef: BsModalRef) {}

    closeModal() {
        this.bsModalRef.hide();
    }
}
