import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/root-store/state';
import { ActionLoadSupplierInfo } from 'app/root-store/supplier-store/actions';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
    public isExpandedNav = true;
    public title = 'Tourwow Pro Online Booking';
    public isHeaderShowBreadcrumb: boolean = true;
    public isHeaderShowBanner: boolean = false;
    public isHeaderShowSearchCountrires: boolean = false;
    public isHeaderShowSearchResultTabs: boolean = false;
    public isHeaderShowCountrySelector: boolean = false;
    public isFooterShow: boolean = true;
    public isOrderBookingBarShow: boolean = false;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(public store: Store<State>, private route: ActivatedRoute, private router: Router) {
        //
    }

    ngOnInit() {
        this.store.dispatch(new ActionLoadSupplierInfo());
        this.subscribeRouteNavigationEnd();
    }

    private subscribeRouteNavigationEnd(): void {
        this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((e) => {
            if (e instanceof NavigationEnd) {
                let route = this.route.firstChild;
                let child = route;

                while (child) {
                    if (child.firstChild) {
                        child = child.firstChild;
                        route = child;
                    } else {
                        child = null;
                    }
                }

                if (route) {
                    this.setHeaderDisplay(route.data['_value']);
                }
            }
        });

        // Get data on first time route.
        this.getDataActivateRoute();
    }

    private getDataActivateRoute(): void {
        let route = this.route.firstChild;
        let child = route;

        while (child) {
            if (child.firstChild) {
                child = child.firstChild;
                route = child;
            } else {
                child = null;
            }
        }
        if (route) {
            this.setHeaderDisplay(route.data['_value']);
        }
    }

    private setHeaderDisplay(data: any): void {
        this.isHeaderShowBreadcrumb = data['isHeaderShowBreadcrumb'];
        this.isHeaderShowBanner = data['isHeaderShowBanner'];
        this.isHeaderShowSearchCountrires = data['isHeaderShowSearchCountrires'];
        this.isHeaderShowSearchResultTabs = data['isHeaderShowSearchResultTabs'];
        this.isFooterShow = data['isFooterShow'];
        this.isOrderBookingBarShow = data['isOrderBookingBarShow'];
        this.isHeaderShowCountrySelector = data['isHeaderShowCountrySelector'];
    }

    public expandedNav(expandMenu: boolean): void {
        this.isExpandedNav = expandMenu;
    }
}
