import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { State } from 'app/root-store/state';
import {
    selectProductSummaries,
    selectJoinTours,
    selectJoinToursTotal,
    selectSearchCriteria,
} from 'app/root-store/sale-store/selectors';
import { takeUntil, map, filter, take } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { EventEmitter } from '@angular/core';

@Component({
    selector: 'app-program-list',
    templateUrl: './program-list.component.html',
    styleUrls: ['./program-list.component.scss'],
})
export class ProgramListComponent implements OnInit, OnDestroy {
    @Input() productCate: string;
    @Input() productCateTotal: number;
    @Output() scrollDowned = new EventEmitter<boolean>();

    public joinTourList$: Observable<any[]>;
    public joinTourTotal$: Observable<number>;
    public searchCriteria$: Observable<any>;

    public periodTotal: number;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(public store: Store<State>) {}

    ngOnInit() {
        this.joinTourList$ = this.store.pipe(
            select(selectJoinTours),
            filter((data) => data != null && data.length),
            map((data) => {
                this.periodTotal = 0;
                data.forEach((element) => {
                    this.periodTotal += +element.periods.length;
                });

                return data;
            }),
            takeUntil(this.unsubscribe$)
        );

        this.joinTourTotal$ = this.store.pipe(select(selectJoinToursTotal), takeUntil(this.unsubscribe$));

        this.searchCriteria$ = this.store.pipe(select(selectSearchCriteria), takeUntil(this.unsubscribe$));

        /// Selcet Join tour
        this.store
            .pipe(
                select(selectProductSummaries),
                filter((data) => data != null),
                map((data) => {
                    const cateJoin = data.category_products.find((elm) => elm.pro_category_sub_products_id === 1);
                    this.productCate = cateJoin.category_product_name;
                    this.productCateTotal = cateJoin.total;
                }),
                take(1)
            )
            .subscribe();
    }

    onScrollDown() {
        this.scrollDowned.emit(true);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
