export const ONLINE_BOOKING_PREFIX = 'onlinebooking/{supplier_slug}/';
export const ENDPOINTS: any = {
    AGENCY: {
        GET_LISTINGISTING: ONLINE_BOOKING_PREFIX + 'agencies/listing',
        GET_INFO: ONLINE_BOOKING_PREFIX + 'agencies/members/info',
        VERIFY_EMAIL_CONTACT: ONLINE_BOOKING_PREFIX + 'agencies/members/verify/email',
        UPDATE_EMAIL_CONTACT: ONLINE_BOOKING_PREFIX + 'agencies/members/update/email',
        PUT: ONLINE_BOOKING_PREFIX + 'agencies/{agency_id}',
    },
    USER: {
        GET: ONLINE_BOOKING_PREFIX + 'members',
    },
    PRODUCT: {
        PERIOD: {
            GET_OPTIONS: ONLINE_BOOKING_PREFIX + 'products/joins/{program_id}/periods/options',
            GET_DETAIL: ONLINE_BOOKING_PREFIX + 'products/joins/{program_id}/periods/{period_id}',
        },
        JOINS: {
            GET_ACTIVE: ONLINE_BOOKING_PREFIX + 'products/joins',
            GET_LISTING: ONLINE_BOOKING_PREFIX + 'products/joins/listing',
            GET_PDF_BASE64: ONLINE_BOOKING_PREFIX + 'products/joins/{program_id}/file/pdf',
            GET_PICTURE_BASE64: ONLINE_BOOKING_PREFIX + 'products/joins/{program_id}/file/picture',
        },
        SUMMARIES: ONLINE_BOOKING_PREFIX + 'products/summaries',
    },
    ORDER: {
        GET: ONLINE_BOOKING_PREFIX + 'orders/{order_id}',
        POST: ONLINE_BOOKING_PREFIX + 'orders',
        CANCEL: ONLINE_BOOKING_PREFIX + 'orders/{order_id}/cancel',
        GET_LISTING: ONLINE_BOOKING_PREFIX + 'orders/listing',
        FILE: ONLINE_BOOKING_PREFIX + 'orders/{order_id}/invoice/file',
        FILE_ACTIVE: ONLINE_BOOKING_PREFIX + 'orders/{order_id}/invoice/file/active',
        PAYMENT: {
            POST: ONLINE_BOOKING_PREFIX + 'orders/{order_id}/payments/transections',
            DELETE: ONLINE_BOOKING_PREFIX + 'orders/{order_id}/payments/transections/{payment_id}',
        },
    },
    SUPPLIER: {
        GET_INFO: ONLINE_BOOKING_PREFIX + 'info',
        CONFIG_ORDER_INFO: ONLINE_BOOKING_PREFIX + 'info/orders',
    },
    COUNTRY: {
        GET_LISTING: ONLINE_BOOKING_PREFIX + 'countries/products',
    },
    REGISTER: {
        SIGNUP: ONLINE_BOOKING_PREFIX + 'agencies/signup',
        CREATE_PASSWORD: ONLINE_BOOKING_PREFIX + 'password',
        GET_AGENCY_INFO: ONLINE_BOOKING_PREFIX + 'agencies/info',
        GET_AGENCY_MEMBER: ONLINE_BOOKING_PREFIX + 'agencies/members/{token}',
        PUT_AGENCY_MEMBER_CREDENTIAL: ONLINE_BOOKING_PREFIX + 'agencies/members/{member_id}/credential',
    },
    FILE_UPLOAD: {
        POST: ONLINE_BOOKING_PREFIX + 'files/temporary/upload',
    },
    PROMOTION: {
        GET_COUNTRY_LISTING: ONLINE_BOOKING_PREFIX + 'countries/deals',
        GET_PROGRAM_LISTING: ONLINE_BOOKING_PREFIX + 'promotions/deals/joins/options',
        GET_LISTING: ONLINE_BOOKING_PREFIX + 'promotions/deals/listing',
    },
    AUTH: {
        LOGIN: ONLINE_BOOKING_PREFIX + 'auth/login',
    },
    MEMBER: {
        GET_LISTING: ONLINE_BOOKING_PREFIX + 'agencies/members/listing',
        GET_INFO: ONLINE_BOOKING_PREFIX + 'agencies/members/info',
        DELETE: ONLINE_BOOKING_PREFIX + 'agencies/members/{member_id}',
        POST: ONLINE_BOOKING_PREFIX + 'agencies/members',
        PUT: ONLINE_BOOKING_PREFIX + 'agencies/members/{member_id}',
    },
};
