import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ENDPOINTS } from 'app/constants/endpoint';
import { environment } from '../../environments/environment';
import { TourwowBaseService } from './tourwow-base.service';
import { HttpService } from './base/http.service';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { MemberFormValues } from 'app/modules/member/components/member-form/member-form.component';

@Injectable({
    providedIn: 'root',
})
export class MemberService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router,
        private baseService: TourwowBaseService
    ) {
        super(platformId, http, router, baseService);
    }

    public getListing(): Observable<any> {
        const url =
            environment.apiProUrl + ENDPOINTS.MEMBER.GET_LISTING.replace('{supplier_slug}', this.baseService.slugUri);

        return this.get(url, true);
    }

    public getInfo(): Observable<any> {
        const url =
            environment.apiProUrl + ENDPOINTS.MEMBER.GET_INFO.replace('{supplier_slug}', this.baseService.slugUri);

        return this.get(url, true);
    }

    public create(body: MemberFormValues): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.MEMBER.POST.replace('{supplier_slug}', this.baseService.slugUri);
        return this.post(url, body, true);
    }

    public update(memberId: number, body: MemberFormValues): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.MEMBER.PUT.replace('{supplier_slug}', this.baseService.slugUri).replace('{member_id}', memberId);
        return this.put(url, body, true);
    }

    public cancel(memberId: number): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.MEMBER.DELETE.replace('{supplier_slug}', this.baseService.slugUri).replace(
                '{member_id}',
                memberId
            );
        return this.delete(url, true);
    }
}
