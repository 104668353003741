import { ActionReducerMap } from '@ngrx/store';
import { reducer as agencyReducer } from './agency-store/reducer';
import { reducer as authReducer } from './auth/reducer';
import { reducer as joinProgramReducer } from './join-program-store/reducer';
import { reducer as orderReducer } from './order-store/reducer';
import { reducer as saleReducer } from './sale-store/reducer';
import { reducer as promotionReducer } from './promotion-store/reducer';
import { reducer as signupReducer } from './signup/reducer';
import { reducer as supplierReducer } from './supplier-store/reducer';
import { reducer as userReducer } from './user-store/reducer';
import { reducer as MemberReducer } from './member-store/reducer';

export interface IAppState {
    agency: any;
    auth: any;
    joinProgram: any;
    order: any;
    sale: any;
    promotion: any;
    signup: any;
    supplier: any;
    user: any;
    member: any;
}

export const reducers: ActionReducerMap<IAppState, any> = {
    agency: agencyReducer,
    auth: authReducer,
    joinProgram: joinProgramReducer,
    order: orderReducer,
    sale: saleReducer,
    promotion: promotionReducer,
    signup: signupReducer,
    supplier: supplierReducer,
    user: userReducer,
    member: MemberReducer,
};
