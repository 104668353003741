import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'mobileNumberFormat',
})
export class MobileNumberFormatPipe implements PipeTransform {
    transform(mobileNumber: string): string {
        if (!mobileNumber) return mobileNumber;
        let mobileNumberNewFormat: string;
        const mobileNumberLength = mobileNumber.length;
        if (mobileNumberLength < 9 || mobileNumberLength > 10) {
            return mobileNumber;
        }

        const pattern = {
            '9digit': /^(\d{2})(\d{3})(\d{4})$/,
            '10digit': /^(\d{3})(\d{3})(\d{4})$/,
        };
        const match = mobileNumber.match(pattern[mobileNumberLength + 'digit']);
        match.shift(); // remove full match number array[0];
        mobileNumberNewFormat = match.join('-');
        return mobileNumberNewFormat;
    }
}
