import { Action } from '@ngrx/store';
import { AuthenticatedAgencyMember, AuthenticatedUser } from 'app/shared/models/auth.model';

export enum ActionTypes {
    ON_LOGIN = '[Auth] On Login',
    ON_LOGIN_SUCCESS = '[Auth] On Login Success',
    ON_LOGIN_FAILURE = '[Auth] On Login Failure',
    ON_LOGOUT = '[Auth] On Logout',
    ON_LOGOUT_SUCCESS = '[Auth] On Logout Success',
    CHECK_AUTHENTICATION = '[User] Check Authentication',
    CHECK_AUTHENTICATION_SUCCESS = '[User] Check Authentication Success',
    CHECK_AUTHENTICATION_FAILURE = '[User] Check Authentication Failure',
}

export class ActionOnLogin implements Action {
    readonly type = ActionTypes.ON_LOGIN;
    constructor(
        public payload: {
            username: any;
            password: any;
            actionAfterLogin: () => any;
        }
    ) {}
}

export class ActionOnLoginSuccess implements Action {
    readonly type = ActionTypes.ON_LOGIN_SUCCESS;
    constructor(
        public payload: {
            currentUser: AuthenticatedUser;
            currentAgencyMember: AuthenticatedAgencyMember;
            actionAfterLogin: () => any;
        }
    ) {}
}

export class ActionOnLoginFailure implements Action {
    readonly type = ActionTypes.ON_LOGIN_FAILURE;
    constructor(public payload: { error: any }) {}
}

export class ActionOnLogout implements Action {
    readonly type = ActionTypes.ON_LOGOUT;
    constructor() {}
}

export class ActionOnLogoutSuccess implements Action {
    readonly type = ActionTypes.ON_LOGOUT_SUCCESS;
    constructor() {}
}

export class ActionCheckAuthentication implements Action {
    readonly type = ActionTypes.CHECK_AUTHENTICATION;
}

export class ActionCheckAuthenticationSuccess implements Action {
    readonly type = ActionTypes.CHECK_AUTHENTICATION_SUCCESS;
    constructor(
        public payload: {
            currentUser: AuthenticatedUser;
            currentAgencyMember: AuthenticatedAgencyMember;
        }
    ) {}
}

export class ActionCheckAuthenticationFailure implements Action {
    readonly type = ActionTypes.CHECK_AUTHENTICATION_FAILURE;
    constructor(public error: any) {}
}

export type Actions =
    | ActionOnLogin
    | ActionOnLoginSuccess
    | ActionOnLoginFailure
    | ActionOnLogout
    | ActionOnLogoutSuccess
    | ActionCheckAuthentication
    | ActionCheckAuthenticationSuccess
    | ActionCheckAuthenticationFailure;
