import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { ENDPOINTS } from 'app/constants/endpoint';
import { environment } from 'environments/environment';
import { TourwowBaseService } from './tourwow-base.service';
import { HttpService } from './base/http.service';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SupplierService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router,
        private baseService: TourwowBaseService
    ) {
        super(platformId, http, router, baseService);
    }

    public getInfo(): Observable<any> {
        const url =
            environment.apiProUrl + ENDPOINTS.SUPPLIER.GET_INFO.replace('{supplier_slug}', this.baseService.slugUri);
        return this.get(url, true);
    }

    public getConfigOrderInfo(): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.SUPPLIER.CONFIG_ORDER_INFO.replace('{supplier_slug}', this.baseService.slugUri);
        return this.get(url, true);
    }
}
