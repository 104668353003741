export class MemberListing {
    members: MemberItem[];

    deserialize(object: any): MemberListing {
        this.members = object.map((x) => new MemberItem().deserialize(x));
        return this;
    }
}

export class MemberItem {
    id: number;
    firstName: string;
    lastName: string;
    nickName: string;
    fullName: string;
    roleSlug: string;
    tel: string;
    channelObStatusCode: string;
    email: string;
    deleted: boolean;

    username: string;
    password: string;

    deserialize(object: any): MemberItem {
        this.id = object.id;
        this.firstName = object.first_name;
        this.lastName = object.last_name;
        this.nickName = object.nick_name;
        this.fullName = this.firstName;
        if (this.lastName) {
            this.firstName += ` ${this.lastName}`;
        }
        if (this.nickName) {
            this.fullName += ` (${this.nickName})`;
        }

        this.roleSlug = object.role_slug;
        this.tel = object.tel;
        this.channelObStatusCode = object.channel_ob_status_code;
        this.email = object.email;
        this.deleted = object.deleted;

        this.username = object.user.username;
        this.password = object.user.password;

        return this;
    }

    isAdmin(): boolean {
        return this.roleSlug === 'admin';
    }
}
