import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
    Actions as UserActions,
    ActionTypes as UserActionTypes,
    ActionFailure,
    ActionLoadUser,
    ActionLoadUserSuccess,
} from './actions';
import { UserService } from 'app/services/user.service';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class UserStoreEffects {
    constructor(private actions$: Actions, private userService: UserService) {}

    
    loadUser = createEffect(() => () =>
        this.actions$.pipe(
            ofType<UserActions>(UserActionTypes.LOAD_USER),
            switchMap((action: ActionLoadUser) => {
                return this.userService.getUsers(action.payload.statusCode).pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }
                        return new ActionLoadUserSuccess({
                            users: resp.data.users,
                        });
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));
}
