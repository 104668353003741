import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
    Actions as JoinProgramActions,
    ActionTypes as JoinProgramActionTypes,
    ActionFailure,
    ActionLoadJoinProgram,
    ActionLoadJoinProgramSuccess,
    ActionLoadJoinProgramWithFirstPeriod,
    ActionLoadJoinProgramWithFirstPeriodSuccess,
    ActionLoadPeriodOptions,
    ActionLoadPeriodOptionsSuccess,
} from './actions';
import { ProductService } from 'app/services/product.service';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { SupplierService } from 'app/services/supplier.service';

@Injectable()
export class JoinProgramStoreEffects {
    constructor(
        private actions$: Actions,
        private productService: ProductService,
        private supplierService: SupplierService
    ) {}

    
    loadJoinProgramWithFirstPeriod = createEffect(() => () =>
        this.actions$.pipe(
            ofType<JoinProgramActions>(JoinProgramActionTypes.LOAD_JOIN_PROGRAM_WITH_FIRST_PERIOD),
            switchMap((action: ActionLoadJoinProgramWithFirstPeriod) => {
                return this.productService.getJoinPeriodOptions(action.programId).pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }

                        return {
                            periods: resp.data,
                        };
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            }),
            switchMap((data: any) => {
                const firstPeriods = data.periods.length > 0 ? data.periods[0] : null;
                if (firstPeriods === null) {
                    return of(new ActionFailure('Program not found.'));
                }

                return this.productService.getJoinPeriodDetail(firstPeriods.pro_programs_id, firstPeriods.id).pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }
                        return new ActionLoadJoinProgramWithFirstPeriodSuccess({
                            joinProgram: resp.data,
                            periods: data.periods,
                        });
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));

    
    loadJoinProgram = createEffect(() => () =>
        this.actions$.pipe(
            ofType<JoinProgramActions>(JoinProgramActionTypes.LOAD_JOIN_PROGRAM),
            switchMap((action: ActionLoadJoinProgram) => {
                return this.productService.getJoinPeriodDetail(action.programId, action.periodId).pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }
                        return new ActionLoadJoinProgramSuccess({
                            joinProgram: resp.data,
                        });
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));

    
    loadPeriodOptions = createEffect(() => () =>
        this.actions$.pipe(
            ofType<JoinProgramActions>(JoinProgramActionTypes.LOAD_PERIOD_OPTIONS),
            switchMap((action: ActionLoadPeriodOptions) => {
                return this.productService.getJoinPeriodOptions(action.programId).pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }

                        return new ActionLoadPeriodOptionsSuccess({
                            periods: resp.data,
                        });
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));
}
