<div class="home-program-box bg-white px-4 pb-0 pb-lg-4 mb-3 mb-lg-3 mx-3 mx-lg-0">
    <div class="row">
        <div class="col-12 pt-0 pt-lg-4 pb-0 pb-lg-2 program-banner" #programbanner>
            <div class="row program-banner-inner">
                <!-- desktop image -->
                <div class="col-lg-4 d-none d-lg-block program-img">
                    <div class="tour-thumbnail">
                        <img
                            [src]="program.banner_preview"
                            alt="JOIN TOUR PROGRAM"
                            class="thumbnail"
                            (click)="openModal(previewImgTemplate)"
                        />
                    </div>
                </div>
                <!-- mobile image -->
                <div class="col-md-12 d-lg-none d-md-block program-img text-center px-0 px-lg-3 my-0 my-lg-3 mt-2">
                    <div class="tour-thumbnail">
                        <img
                            [src]="program.banner_preview"
                            alt="JOIN TOUR PROGRAM"
                            class="thumbnail-mobile"
                            (click)="openModal(previewImgTemplate)"
                        />
                    </div>
                </div>

                <div class="col-lg-8 col-md-12 program-detail">
                    <!-- title -->
                    <h2 class="col-md-12 px-0 color-text-title twp-text-xl-bold mb-2 mt-3 mt-md-0">
                        {{ program.tour_code }} {{ program.name }}
                    </h2>

                    <!-- location -->
                    <div class="home-location flex-wrap d-flex mb-3">
                        <div class="pl-0 pr-4 d-flex align-items-center">
                            <i class="icon-location" aria-hidden="true"></i>
                            <span class="twp-text-l px-2">{{ getCountriesText(program.countries) }}</span>
                        </div>
                        <div class="pl-0 pr-4 d-flex align-items-center">
                            <i class="icon-calendar" aria-hidden="true"></i>
                            <span class="twp-text-l px-2"
                                >{{ program.duration_day }} วัน {{ program.duration_night }} คืน</span
                            >
                        </div>
                        <div class="pl-0 pr-4 d-flex align-items-center">
                            <i class="icon-flight-go" aria-hidden="true"></i>
                            <span class="twp-text-l px-2">{{ program.periods | ProgramGoFlightNumber }}</span>
                        </div>
                        <div class="pl-0 pr-4 d-flex align-items-center">
                            <i class="icon-flight-return" aria-hidden="true"></i>
                            <span class="twp-text-l px-2">{{ program.periods | ProgramBackFlightNumber }}</span>
                        </div>
                        <a
                            (click)="openModal(downloadTemplate, 'modal-sm bottom-display')"
                            class="download d-md-block d-lg-none twp-text-l px-2"
                            >Download ></a
                        >
                    </div>

                    <!-- download -->
                    <div class="d-none d-lg-block mb-2">
                        <div class="icon-download d-flex align-items-center">
                            <div class="mr-2" [ngClass]="{ 'has-file': program.program_doc }">
                                <a
                                    class="word d-flex align-items-center justify-content-center py-1"
                                    [href]="program.program_doc"
                                    [download]="program.program_doc_file_name"
                                >
                                    <i class="icon-word-file" aria-hidden="true"></i>
                                    <span>word</span>
                                </a>
                            </div>
                            <div class="mr-2" [ngClass]="{ 'has-file': program.program_pdf_file_name }">
                                <a
                                    class="pdf d-flex align-items-center justify-content-center py-1"
                                    (click)="downloadPdf(program.id, program.program_pdf_file_name)"
                                >
                                    <i class="icon-pdf-file" aria-hidden="true"></i>
                                    <span>pdf</span>
                                </a>
                            </div>
                            <div class="mr-2" [ngClass]="{ 'has-file': program.program_picture_file_name }">
                                <a
                                    class="image d-flex align-items-center justify-content-center py-1"
                                    [download]="program.program_picture_file_name"
                                    (click)="downloadPicture(program.id, program.program_picture_file_name)"
                                >
                                    <i class="icon-img-file" aria-hidden="true"></i>
                                    <span>image</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="highlight-text" *ngIf="program.hilight_description">
                        <strong class="twp-text-l-bold">Highlight โปรแกรม</strong>
                        <p class="twp-text-l" [innerHTML]="program.hilight_description"></p>
                    </div>

                    <div class="preview-pdf-link" *ngIf="program.program_pdf">
                        <span (click)="previewPDF()">ดูข้อมูลโปรแกรมทัวร์</span>
                    </div>

                    <!-- <div class="deal-head"><i class="icon-fire"></i>Deal</div>
          <div class="deal-box" *ngFor="let deal of program.deals;let dealIndex = index">
            <app-period-box [period]="deal" [periodIndex]="dealIndex">
            </app-period-box>
          </div> -->
                </div>
            </div>
        </div>
        <div class="col-12 px-0 px-lg-3 program-detail">
            <!-- select -->
            <div class="home-select">
                <div class="d-flex flex-row period-date-group justify-content-between justify-content-sm-start">
                    <label
                        for="home-select-period1"
                        class="checkbox d-flex align-items-center justify-content-center btn btn-period-date color-text-title p-2 mr-md-2"
                        [ngClass]="{ 'btn-period-date-active': !nextPeriodMonth[programIndex] }"
                        (click)="nextPeriodMonth[programIndex] = null; showMorePeriod[programIndex] = false"
                    >
                        <input value="" class="d-none" autocomplete="off" id="home-select-period" type="checkbox" />
                        <div class="icon icon-check position-relative">
                            <img
                                class="position-absolute d-none"
                                style="left: 0"
                                width="12"
                                height="12"
                                src="assets/icons/ico-check.svg"
                            />
                        </div>
                        <div class="d-none d-lg-block">พีเรียดทั้งหมด</div>
                        <div class="d-block d-lg-none">ทั้งหมด</div>
                        <div class="period-number">({{ program.periods.length }})</div>
                    </label>
                    <label
                        for="home-select-period2"
                        class="checkbox d-flex align-items-center justify-content-center btn btn-period-date color-text-title p-2 mr-md-2"
                        [ngClass]="{ 'btn-period-date-active': nextPeriodMonth[programIndex] === '0' }"
                        (click)="nextPeriodMonth[programIndex] = '0'; showMorePeriod[programIndex] = false"
                    >
                        <input
                            value=""
                            class="d-none"
                            autocomplete="off"
                            id="home-select-period"
                            type="checkbox"
                            name=""
                        />
                        <div class="icon icon-check position-relative">
                            <img
                                class="position-absolute d-none"
                                style="left: 0"
                                width="12"
                                height="12"
                                src="assets/icons/ico-check.svg"
                            />
                        </div>
                        <div>{{ 0 | nextPeriodMonthly }}</div>
                        <div class="period-number">({{ program.periods | nextPeriodMonthlyLength: 0 }})</div>
                    </label>
                    <label
                        for="home-select-period3"
                        class="checkbox d-flex align-items-center justify-content-center btn btn-period-date color-text-title p-2 mr-md-2"
                        [ngClass]="{ 'btn-period-date-active': nextPeriodMonth[programIndex] === '1' }"
                        (click)="nextPeriodMonth[programIndex] = '1'; showMorePeriod[programIndex] = false"
                    >
                        <input
                            value=""
                            class="d-none"
                            autocomplete="off"
                            id="home-select-period"
                            type="checkbox"
                            name=""
                        />
                        <div class="icon icon-check position-relative">
                            <img
                                class="position-absolute d-none"
                                style="left: 0"
                                width="12"
                                height="12"
                                src="assets/icons/ico-check.svg"
                            />
                        </div>
                        <div>{{ 1 | nextPeriodMonthly }}</div>
                        <div class="period-number">({{ program.periods | nextPeriodMonthlyLength: 1 }})</div>
                    </label>
                    <label
                        for="home-select-period4"
                        class="checkbox d-flex align-items-center justify-content-center btn btn-period-date color-text-title p-2 mr-md-2"
                        [ngClass]="{ 'btn-period-date-active': nextPeriodMonth[programIndex] === '2' }"
                        (click)="nextPeriodMonth[programIndex] = '2'; showMorePeriod[programIndex] = false"
                    >
                        <input
                            value=""
                            class="d-none"
                            autocomplete="off"
                            id="home-select-period"
                            type="checkbox"
                            name=""
                        />
                        <div class="icon icon-check position-relative">
                            <img
                                class="position-absolute d-none"
                                style="left: 0"
                                width="12"
                                height="12"
                                src="assets/icons/ico-check.svg"
                            />
                        </div>
                        <div>{{ 2 | nextPeriodMonthly }}</div>
                        <div class="period-number">({{ program.periods | nextPeriodMonthlyLength: 2 }})</div>
                    </label>
                </div>
            </div>
            <!-- end select -->
            <!-- period box -->

            <!-- css hot deal -->
            <!--
            <ng-container *ngIf="!nextPeriodMonth[programIndex]">
                <div class="period-box  hotdeal   position-relative  my-3" *ngFor="let period of program.deals ;let i = index">
                    <span class="tag  tag-hotdeal  d-none  position-absolute  twp-text-m-bold">
                        <img width="15"  height="15" class="mr-1" src="assets/icons/ico-deal.svg">
                    </span>
                    <app-period-box [period]="period" *ngIf="i<5"></app-period-box>
                </div>
                <div class="see-period">
                    <button type="button" class="btn  btn-see-more" *ngIf="program.periods.length>5 && !showMorePeriod[programIndex]" (click)="showMorePeriod[programIndex]=true">
                        <i class="icon-expand"></i>
                        <span>ดูเพิ่มเติม {{program.periods.length-5}} พีเรียด</span>
                    </button>
                </div>

                <ng-container *ngFor="let period of program.periods ;let i = index">
                    <div class="period-box  my-3" *ngIf="i>=5">
                        <app-period-box [period]="period" *ngIf="i>=5 && showMorePeriod[programIndex]">
                        </app-period-box>
                    </div>
                </ng-container>

            </ng-container>-->
            <!-- css hot deal -->

            <ng-container *ngIf="!nextPeriodMonth[programIndex]">
                <ng-container *ngFor="let deal of program.deals; let i = index">
                    <div class="period-box hotdeal position-relative my-3">
                        <span class="tag tag-hotdeal d-none position-absolute twp-text-m-bold">
                            <img width="15" height="15" class="mr-1" src="assets/icons/ico-deal.svg" />Deal
                        </span>
                        <app-period-box [period]="deal"></app-period-box>
                    </div>
                </ng-container>
                <!-- <div class="deal-box" *ngFor="let deal of program.deals;let dealIndex = index">
                <app-period-box [period]="deal" [periodIndex]="dealIndex">
                </app-period-box>
            </div> -->

                <ng-container *ngFor="let period of program.periods; let i = index">
                    <ng-container *ngIf="!period.deal">
                        <div *ngIf="i < 5" class="period-box position-relative my-3">
                            <app-period-box [period]="period"></app-period-box>
                        </div>
                    </ng-container>
                </ng-container>

                <div class="see-period text-center">
                    <button
                        type="button"
                        class="btn btn-see-more"
                        *ngIf="program.periods.length > 5 && !showMorePeriod[programIndex]"
                        (click)="showMorePeriod[programIndex] = true"
                    >
                        <i class="icon-expand mr-2"></i>
                        <span class="color-orange-text-note">ดูเพิ่มเติม {{ program.periods.length - 5 }} พีเรียด</span>
                    </button>
                </div>

                <ng-container *ngFor="let period of program.periods; let i = index">
                    <ng-container *ngIf="!period.deal">
                        <div class="period-box my-3" *ngIf="i >= 5 && showMorePeriod[programIndex]">
                            <app-period-box [period]="period"></app-period-box>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="nextPeriodMonth[programIndex]">
                <ng-container
                    *ngFor="
                        let deal of program.deals | nextPeriodMonthlyList: nextPeriodMonth[programIndex];
                        let i = index
                    "
                >
                    <div class="period-box hotdeal position-relative my-3">
                        <span class="tag tag-hotdeal d-none position-absolute twp-text-m-bold">
                            <img width="15" height="15" class="mr-1" src="assets/icons/ico-deal.svg" />Deal
                        </span>
                        <app-period-box [period]="deal"></app-period-box>
                    </div>
                </ng-container>

                <ng-container
                    *ngFor="
                        let period of program.periods | nextPeriodMonthlyList: nextPeriodMonth[programIndex];
                        let i = index
                    "
                >
                    <div class="period-box position-relative my-3" *ngIf="!period.deal">
                        <app-period-box [period]="period" *ngIf="i <= 5"></app-period-box>
                    </div>
                </ng-container>

                <div class="see-period">
                    <!-- *ngIf="getPeriodByMonthList(program.periods,nextPeriodMonth[programIndex]).length>5 && !showMorePeriod[programIndex]" -->
                    <button type="button" class="btn btn-see-more" (click)="showMorePeriod[programIndex] = true">
                        <i class="icon-expand"></i>
                        <span>ดูเพิ่มเติม {{ program.periods.length - 5 }} พีเรียด</span>
                    </button>
                </div>
                <ng-container
                    *ngFor="
                        let period of program.periods | nextPeriodMonthlyList: nextPeriodMonth[programIndex];
                        let i = index
                    "
                >
                    <ng-container *ngIf="!period.deal">
                        <div class="period-box position-relative" *ngIf="i >= 5">
                            <app-period-box [period]="period" *ngIf="i >= 5 && showMorePeriod[programIndex]">
                            </app-period-box>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #previewImgTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">รูปภาพ</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img [src]="program.banner_download" alt="JOIN TOUR PROGRAM" class="thumbnail" />
    </div>
</ng-template>

<ng-template #downloadTemplate>
    <div class="modal-header">
        <h4 class="twp-text-xxl color-text-title">Download Program</h4>
        <button class="close pull-right" type="button" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="twp-text-l color-text-title">{{ program.tour_code }} {{ program.name }}</p>
        <!-- download -->
        <div class="home-program-box mb-5" style="box-shadow: none">
            <div class="icon-download d-flex align-items-center">
                <div class="mr-2" [ngClass]="{ 'has-file': program.program_doc }">
                    <a
                        class="word d-flex align-items-center justify-content-center py-1"
                        [href]="program.program_doc"
                        [download]="program.program_doc_file_name"
                    >
                        <i class="icon-word-file" aria-hidden="true"></i>
                        <span>word</span>
                    </a>
                </div>
                <div class="mr-2" [ngClass]="{ 'has-file': program.program_pdf_file_name }">
                    <a
                        class="pdf d-flex align-items-center justify-content-center py-1"
                        (click)="downloadPdf(program.id, program.program_pdf_file_name)"
                    >
                        <i class="icon-pdf-file" aria-hidden="true"></i>
                        <span>pdf</span>
                    </a>
                </div>
                <div class="mr-2" [ngClass]="{ 'has-file': program.program_picture_file_name }">
                    <a
                        class="image d-flex align-items-center justify-content-center py-1"
                        [download]="program.program_picture_file_name"
                        (click)="downloadPicture(program.id, program.program_picture_file_name)"
                    >
                        <i class="icon-img-file" aria-hidden="true"></i>
                        <span>image</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<app-preview-pdf-modal #previewPdfModal title="ข้อมูลโปรแกรมทัวร์"></app-preview-pdf-modal>
