import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { SignupService } from 'app/services/signup.service';
import {
    ActionSignup,
    ActionTypes,
    ActionSignupSuccess,
    ActionFailure,
    ActionCreatePassword,
    ActionCreatePasswordSuccess,
    ActionLoadAgency,
    ActionLoadAgencySuccess,
} from './actions';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { of } from 'rxjs';

@Injectable()
export class SignupStoreEffects {
    constructor(private router: Router, private actions$: Actions, private service: SignupService) {}

    
    signup = createEffect(() => () =>
        this.actions$.pipe(
            ofType<ActionSignup>(ActionTypes.SIGNUP),
            switchMap((action: ActionSignup) => {
                return this.service.signup(action.payload.body).pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }
                        // resp.data.token = 'MTU4MTA3MTU3NmZmOTdiZmE3MWQ';
                        return new ActionSignupSuccess({
                            token: resp.data.token,
                            has_password: resp.data.has_password,
                        });
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));

    
    signupSuccess = createEffect(() => () =>
        this.actions$.pipe(
            ofType<ActionSignupSuccess>(ActionTypes.SIGNUP_SUCCESS),
            tap((action: ActionSignupSuccess) => {
                if (action.payload.has_password) {
                    this.router.navigate(['/signup/completed']);
                } else {
                    this.router.navigate(['/signup/step2/' + action.payload.token]);
                }
            })
        ), { dispatch: false });

    
    createPassword = createEffect(() => () =>
        this.actions$.pipe(
            ofType<ActionCreatePassword>(ActionTypes.CREATE_PASSWORD),
            switchMap((action: ActionCreatePassword) => {
                return this.service.createPassword(action.payload.body).pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }
                        return new ActionCreatePasswordSuccess();
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));

    
    loadAgency = createEffect(() => () =>
        this.actions$.pipe(
            ofType<ActionLoadAgency>(ActionTypes.LOAD_AGENCY),
            switchMap((action: ActionLoadAgency) => {
                return this.service.getAgencyInfo(action.criteria).pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }
                        return new ActionLoadAgencySuccess({
                            agency: {
                                aliasName: resp.data.alias_name,
                                companyName: resp.data.company_name,
                                address: resp.data.address,
                                tel: resp.data.tel,
                                tourismLicense: resp.data.tourism_license,
                                email: resp.data.email,
                            },
                        });
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));
}
