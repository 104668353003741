import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CountrySelectorService } from 'app/shared/components/country-selector/country-selector.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
    selector: 'app-country-selector',
    templateUrl: './country-selector.component.html',
    styleUrls: ['./country-selector.component.scss'],
    host: {
        '(document:click)': 'onDocumentClick($event)',
    },
})
export class CountrySelectorComponent implements OnInit {
    @ViewChild('countriesBox') _countriesBox: ElementRef;
    @ViewChild('programsBox') _programsBox: ElementRef;

    public countries: any[];
    public selectedCountry: any;

    public programs: any[];
    public isRequestingPrograms: boolean;

    public isShowModal: boolean;

    private _isShowArrow: boolean;
    public get isShowArrow(): boolean {
        return this._isShowArrow;
    }

    private _isLeftArrowDisable: boolean;
    public get isLeftArrowDisable(): boolean {
        return this._isLeftArrowDisable;
    }

    private _isRightArrowDisable: boolean;
    public get isRightArrowDisable(): boolean {
        return this._isRightArrowDisable;
    }

    constructor(private router: Router, private countrySelectorService: CountrySelectorService) {}

    ngOnInit(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.selectedCountry = null;
                this.countries = [];
                this._isShowArrow = false;
            }
        });

        this.countrySelectorService.countriesChanged$.subscribe((listView) => {
            this.countries = listView;
            setTimeout(() => {
                this.decideToDisplayArrowButton();
                this.decideToDisableMoveLeftButton();
            }, 500);
        });

        this.countrySelectorService.programsChanged$.subscribe((listView) => {
            this.isRequestingPrograms = false;
            this.programs = listView;
        });
    }

    public arrowClick(position: string): void {
        let box = null; //$(this._countriesBox.nativeElement);
        let result = box.width() / 2;
        if (position === 'left') {
            result = -(result - box.scrollLeft());
        } else {
            result = result + box.scrollLeft();
        }

        box.animate({ scrollLeft: result }, 299);

        setTimeout(() => {
            this.decideToDisableMoveLeftButton();
            this.decideToDisableMoveRightButton();
        }, 300);
    }

    public countryFilter(event: any, country: any): void {
        this.programs = [];
        this.isRequestingPrograms = true;
        let arrowGap = 50;
        // $(this._programsBox.nativeElement).css('left', `calc(${event.pageX}px - ${arrowGap}px)`);
        this.selectedCountry = country.id;
        this.countrySelectorService.broadcastCountryId(country);
        setTimeout(() => (this.isShowModal = true), 100);
    }
    public programFilter(program: any): void {
        this.countrySelectorService.broadcastProgramId(program);
    }

    private decideToDisplayArrowButton(): void {
        this._isShowArrow = this.getTotalCountryWidth() > this.getCountryWrapperWidth();
    }
    private decideToDisableMoveLeftButton(): void {
        this._isLeftArrowDisable = this.getCountryScrollLeftPosition() === 0;
    }
    private decideToDisableMoveRightButton(): void {
        let scrollWidth = null; //$(this._countriesBox.nativeElement).get(0).scrollWidth;
        let scrollPosition = this.getCountryScrollLeftPosition();
        this._isRightArrowDisable = scrollWidth - scrollPosition === this.getCountryWrapperWidth();
    }
    private getCountryWrapperWidth(): number {
        let box = null; //$(this._countriesBox.nativeElement);
        let wrapperWidth = box.outerWidth();

        return wrapperWidth;
    }
    private getTotalCountryWidth(): number {
        let box = null; //$(this._countriesBox.nativeElement);
        let totalWidth = 0;
        box.children().each((i, e) => {
            // totalWidth += $(e).outerWidth();
        });

        return totalWidth;
    }
    private getCountryScrollLeftPosition(): number {
        let box = null; //$(this._countriesBox.nativeElement);
        return box.scrollLeft();
    }

    private onDocumentClick(event: any): void {
        if (this.isShowModal) this.isShowModal = false;
    }
}
