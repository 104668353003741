import { initialState, AuthState } from './state';
import { Actions, ActionTypes } from './actions';

export function reducer(state: AuthState = initialState, action: Actions): AuthState {
    switch (action.type) {
        case ActionTypes.ON_LOGIN:
            return {
                ...state,
                isAuth: false,
                currentUser: null,
                currentAgencyMember: null,
                error: null,
            };
        case ActionTypes.ON_LOGIN_SUCCESS:
            return {
                ...state,
                isAuth: true,
                currentUser: action.payload.currentUser,
                currentAgencyMember: action.payload.currentAgencyMember,
                error: null,
            };
        case ActionTypes.ON_LOGIN_FAILURE:
            return {
                ...state,
                isAuth: false,
                error: action.payload.error,
            };
        case ActionTypes.ON_LOGOUT:
            return {
                ...state,
                isAuth: false,
                currentUser: null,
                currentAgencyMember: null,
            };
        case ActionTypes.CHECK_AUTHENTICATION_SUCCESS:
            return {
                ...state,
                isAuth: true,
                currentUser: action.payload.currentUser,
                currentAgencyMember: action.payload.currentAgencyMember,
                error: null,
            };
        case ActionTypes.CHECK_AUTHENTICATION_FAILURE:
            return {
                ...state,
                isAuth: false,
                currentUser: null,
                currentAgencyMember: null,
                error: action.error,
            };

        default:
            return state;
    }
}
