import * as moment from 'moment';

export class MomentUtil {
    static toBuddhistYear(m, format) {
        const christianYear = m.format('YYYY');
        const buddhishYear = (parseInt(christianYear) + 543).toString();
        return m
            .format(format.replace('YYYY', buddhishYear).replace('YY', buddhishYear.substring(2, 4)))
            .replace(christianYear, buddhishYear);
    }

    static toCountdownAsSeconds(dt) {
        const s = moment.duration(moment(dt.split(' ')[0] + ' 23:59:59').diff(moment())).asSeconds();
        return s > 0 ? s : 0;
    }
}
