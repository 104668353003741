<!-- add-footer -->
<footer class="app-footer text-center mt-4" *ngIf="supplierInfo$ | async as supplierInfo">
    <div class="footer-top text-white d-flex align-items-center justify-content-center flex-column">
        <div class="twp-text-l">
            {{ supplierInfo?.name_en }} เลขที่ใบอนุญาต {{ supplierInfo?.tourism_license }} <br class="d-block" />ติดต่อ
            {{ supplierInfo?.tel }} อีเมล {{ supplierInfo?.email }}
        </div>
    </div>
    <div class="blue-row">
        <span>Online Booking by</span>
        <img src="assets/images/tourwow_pro_logo_footer.png" alt="Tourwow Pro Online Booking" />
    </div>
</footer>
