import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { ENDPOINTS } from 'app/constants/endpoint';
import { environment } from '../../environments/environment';
import { TourwowBaseService } from './tourwow-base.service';
import { HttpService } from './base/http.service';
import { Router } from '@angular/router';
import { switchMap, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SignupService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router,
        private baseService: TourwowBaseService
    ) {
        super(platformId, http, router, baseService);
    }

    public signup(body: any): Observable<any> {
        const url =
            environment.apiProUrl + ENDPOINTS.REGISTER.SIGNUP.replace('{supplier_slug}', this.baseService.slugUri);
        return this.post(url, body, true);
    }

    public createPassword(body: any): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.REGISTER.CREATE_PASSWORD.replace('{supplier_slug}', this.baseService.slugUri);
        return this.post(url, body, true);
    }

    public getAgencyInfo(criteria): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.REGISTER.GET_AGENCY_INFO.replace('{supplier_slug}', this.baseService.slugUri) +
            '?' +
            this.getQueryString(criteria);
        return this.get(url, true);
    }

    public getAgencyInfoMember(token): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.REGISTER.GET_AGENCY_MEMBER.replace('{supplier_slug}', this.baseService.slugUri).replace(
                '{token}',
                token
            );
        return this.get(url, true);
    }

    public updateAgencyInfoMember(memberId: number, body: { username: string; password: string }): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.REGISTER.PUT_AGENCY_MEMBER_CREDENTIAL.replace(
                '{supplier_slug}',
                this.baseService.slugUri
            ).replace('{member_id}', memberId);
        return this.put(url, body, true);
    }
}
