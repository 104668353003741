import { Sales } from 'app/shared/models/sales';

export interface SaleState {
    productSummaries: any[];
    joinTours: any[];
    joinToursTotal: number;
    searchCriteria: any;
    isLoading?: boolean;
    stopLoadingJoinTours?: boolean;
    error?: any;
}

export const initialState: SaleState = {
    productSummaries: null,
    joinTours: null,
    joinToursTotal: null,
    searchCriteria: null,
    isLoading: false,
    stopLoadingJoinTours: false,
    error: null,
};
