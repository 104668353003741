import { Order } from 'app/shared/models/order.model';
import { Period } from 'app/shared/models/products/period.model';

export interface OrderState {
    orderHistory: Order[];
    totalOrderHistory: number;
    stopLoadingorderHistory: boolean;
    order: Order;
    orderDraft: Order;
    periodDraft: Period;
    confirmCreateOrder: boolean;
    isCreateOrderSuccess?: number;
    createOrderSuccessMessage?: string;
    isCancelOrderSuccess?: boolean;
    isLoading?: boolean;
    error?: any;
}

export const initialState: OrderState = {
    orderHistory: null,
    totalOrderHistory: null,
    stopLoadingorderHistory: false,
    order: null,
    orderDraft: null,
    confirmCreateOrder: null,
    periodDraft: null,
    isCreateOrderSuccess: null,
    isCancelOrderSuccess: null,
    isLoading: false,
    error: null,
};
