export class AuthenticatedUser {
    id: number;
    roleSlug: string;

    deserialize(data: any): this {
        this.id = data.id;
        this.roleSlug = data.roles_slug;
        return this;
    }

    isAdmin(): boolean {
        return this.roleSlug === 'admin';
    }
}

export class AuthenticatedAgencyMember {
    id: number;
    firstName: string;
    lastName: string;
    nickName: string;
    channelObStatusCode: string;
    email: string;
    agencyEmail: string;

    deserialize(data: any): this {
        this.id = data.id;
        this.firstName = data.first_name;
        this.lastName = data.last_name;
        this.nickName = data.nick_name;
        this.channelObStatusCode = data.channel_ob_status_code;
        this.email = data.email;
        this.agencyEmail = data.agency_email;
        return this;
    }
}
