import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SaleState } from './state';
import { FEATURE_NAME } from './sale-store.module';

export const selectSaleState = createFeatureSelector<SaleState>(FEATURE_NAME);

export const selectProductSummaries = createSelector(
    selectSaleState,
    (state: SaleState): any => state.productSummaries
);

export const selectStopLoadingJoinTours = createSelector(selectSaleState, (state: SaleState): any => {
    if (state) {
        return state.stopLoadingJoinTours;
    }
});

export const selectJoinTours = createSelector(selectSaleState, (state: SaleState): any =>
    state !== undefined ? state.joinTours : undefined
);

export const selectJoinToursTotal = createSelector(selectSaleState, (state: SaleState): any => state.joinToursTotal);

export const selectSearchCriteria = createSelector(selectSaleState, (state: SaleState): any => state.searchCriteria);

export const selectSearchCountryCriteria = createSelector(selectSaleState, (state: SaleState): any =>
    state.searchCriteria ? state.searchCriteria.country : null
);

export const selectIsLoading = createSelector(selectSaleState, (state: SaleState): any => state.isLoading);

export const selectFailure = createSelector(selectSaleState, (state: SaleState): any => state.error);
