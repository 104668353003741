import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { State } from 'app/root-store/state';
import { selectSupplierInfo } from 'app/root-store/supplier-store/selectors';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    supplierInfo$: Observable<any>;
    constructor(public store: Store<State>) {}

    ngOnInit() {
        this.supplierInfo$ = this.store.pipe(
            select(selectSupplierInfo),
            filter((data) => data),
            take(1)
        );
    }
}
