import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './reducer';
import { UserStoreEffects } from './effects';

export const FEATURE_NAME = 'user';
@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(FEATURE_NAME, reducer),
        EffectsModule.forFeature([UserStoreEffects]),
    ],
    providers: [UserStoreEffects],
})
export class UserStoreModule {}
