import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './reducer';
import { MemberStoreEffects } from './effects';

export const FEATURE_NAME = 'member';
@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(FEATURE_NAME, reducer),
        EffectsModule.forFeature([MemberStoreEffects]),
    ],
    providers: [MemberStoreEffects],
})
export class MemberStoreModule {}
