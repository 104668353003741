import { Component } from '@angular/core';

@Component({
    selector: 'app-star',
    template: `<label class="star">*</label>`,
    styles: ['.star{color:#e12d2d}'],
})
export class StarComponent {
    constructor() {}
}
