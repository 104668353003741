export class MemberInfo {
    id: number;
    firstName: string;
    lastName: string;
    nickName: string;
    fullName: string;
    channelObStatusCode: string;
    suppliers: string[];

    agencyId: number;
    agencyCompanyType: number;
    agencyCompanyName: string;
    agencyEmail: string;
    agencyTel: string;
    agencyAddress: string;
    agencyCompanyLicense: string;
    agencyTourismLicense: string;
    agencyCompanyLicenseDocument: string;
    agencyTourismLicenseDocument: string;
    agencyIsVerified: boolean;

    deserialize(object: any): MemberInfo {
        this.id = object.id;
        this.firstName = object.first_name;
        this.lastName = object.last_name;
        this.nickName = object.nick_name;
        this.fullName = object.full_name;
        this.channelObStatusCode = object.channel_ob_status_code;
        this.suppliers = object.suppliers;

        if (object.agency) {
            this.agencyId = object.agency.id;
            this.agencyCompanyType = object.agency.company_type;
            this.agencyCompanyName = object.agency.company_name;
            this.agencyEmail = object.agency.email;
            this.agencyTel = object.agency.tel;
            this.agencyAddress = object.agency.address;
            this.agencyCompanyLicense = object.agency.company_license;
            this.agencyTourismLicense = object.agency.tourism_license;
            this.agencyCompanyLicenseDocument = object.agency.company_license_document_path;
            this.agencyTourismLicenseDocument = object.agency.tourism_license_document_path;
            this.agencyIsVerified = object.agency.is_verified;
        }

        return this;
    }
}
