import { Component, TemplateRef, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/root-store/state';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { JoinProgram } from 'app/shared/models/products/join-program.model';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-main-template-v2',
    templateUrl: './main-template.component.html',
    styleUrls: ['./main-template.component.scss'],
})
export class MainTemplateComponent implements OnDestroy {
    program = {
        id: null,
        program_doc_file_name: null,
        program_pdf_file_name: null,
        program_picture_file_name: null,
    };

    joinProgram: JoinProgram;
    hideHighlight = true;
    modalRef: BsModalRef;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(public store: Store<State>, private modalService: BsModalService) {}

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    openPreviewImageModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }
}
