import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { State } from 'app/root-store/state';
import { ActionUpdateContactEmail, ActionVerifyContactEmail } from 'app/root-store/agency-store/actions';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { selectHasContactEmail } from 'app/root-store/agency-store/selectors';
import { Subject } from 'rxjs';
import { selectCurrentAgencyMember } from 'app/root-store/auth/selectors';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-input-email-contact-modal',
    templateUrl: './input-email-contact-modal.component.html',
    styleUrls: ['./input-email-contact-modal.component.scss'],
})
export class InputEmailContactModalComponent implements OnInit, OnDestroy {
    public form: UntypedFormGroup;
    public submitted = false;
    public onConfirmEmailSuccess: Subject<boolean>;
    public email: string;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(public store: Store<State>, public bsModalRef: BsModalRef, private fb: UntypedFormBuilder) {}

    ngOnInit() {
        this.store
            .select(selectCurrentAgencyMember)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((agencyMember) => {
                if (this.form) {
                    this.form.patchValue({ email: agencyMember?.agencyEmail });
                }
            });

        this.onConfirmEmailSuccess = new Subject();
        this.form = this.fb.group({
            email: [
                null,
                [
                    Validators.required,
                    Validators.email,
                    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
                ],
            ],
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public confirm(): void {
        this.submitted = true;
        if (this.form.valid) {
            const email = this.form.get('email').value;
            this.store.dispatch(new ActionUpdateContactEmail(email));
            this.store.pipe(select(selectHasContactEmail)).subscribe((value) => {
                if (value) {
                    this.onConfirmEmailSuccess.next(true);
                    this.bsModalRef.hide();
                }
            });
        }
    }
}
