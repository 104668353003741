import { initialState, SupplierState } from './state';
import { Actions, ActionTypes } from './actions';

export function reducer(state: SupplierState = initialState, action: Actions): SupplierState {
    switch (action.type) {
        case ActionTypes.LOAD_ORDER_INFO:
            return {
                ...state,
                info: null,
                isLoading: true,
                error: null,
            };
        case ActionTypes.LOAD_ORDER_INFO_SUCCESS:
            return {
                ...state,
                info: action.payload.info,
                isLoading: false,
                error: null,
            };
        case ActionTypes.LOAD_SUPPLIER_INFO:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case ActionTypes.LOAD_SUPPLIER_INFO_SUCCESS:
            return {
                ...state,
                info: action.payload.info,
                isLoading: false,
                error: null,
            };
        case ActionTypes.FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
}
