import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import * as moment from 'moment';
import { BsLocaleService, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

export const DATEPICKER_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TourwowDatePickerComponent),
    multi: true,
};

@Component({
    selector: 'app-datepicker',
    template: `
        <div class="datepicker">
            <input
                type="text"
                class="{{ className }} display"
                [placeholder]="placeholder"
                [ngModel]="model | thaiMonthDate: true:false:true"
                [disabled]="isDisabled"
                [readonly]="!isDisabled"
                (click)="dp.toggle()"
            />
            <input
                type="text"
                class="model"
                #dp="bsDatepicker"
                bsDatepicker
                [bsConfig]="bsConfig"
                [(ngModel)]="model"
            />
            <i class="fa fa-calendar" aria-hidden="true"></i>
        </div>
    `,
    styleUrls: ['./datepicker.component.scss'],
    providers: [DATEPICKER_VALUE_ACCESSOR],
})
export class TourwowDatePickerComponent implements ControlValueAccessor {
    // result of the model will be string
    // ex. 2020-02-01
    private _model: string;

    public get model(): any {
        return this._model;
    }
    public set model(date: any) {
        let currentValue = this._model ? moment(this._model).toDate() : undefined;
        if (date !== currentValue) {
            this._model = date;
            this.changed.forEach((f) => f(this.castToString(date)));
        }
    }
    @Input() placeholder: string = '';
    @Input() min: Date;
    @Input() max: Date;
    @Input() className: string = 'input';

    isDisabled: boolean = false;
    bsConfig: BsDatepickerConfig = new BsDatepickerConfig();

    private changed = [];
    private touched = [];

    constructor(private localeService: BsLocaleService) {
        // change language date picker
        this.localeService.use('thbe');
        this.bsConfig.containerClass = 'theme-default';
    }

    writeValue(date: any): void {
        if (date === null) {
            this.model = undefined;
            return;
        }
        this.model = date;
    }
    registerOnChange(fn: any): void {
        this.changed.push(fn);
    }
    registerOnTouched(fn: any): void {
        this.touched.push(fn);
    }
    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    private castToString(date: any): string {
        let dateMoment = moment(date || null);
        if (dateMoment.isValid()) {
            return dateMoment.format('YYYY-MM-DD');
        }
        return date;
    }
}
