import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ENDPOINTS } from 'app/constants/endpoint';

import { environment } from 'environments/environment';
import { HttpService } from './base/http.service';
import { Router } from '@angular/router';
import { TourwowBaseService } from './tourwow-base.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PromotionService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router,
        private baseService: TourwowBaseService
    ) {
        super(platformId, http, router, baseService);
    }

    public getCountryListing(): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.PROMOTION.GET_COUNTRY_LISTING.replace('{supplier_slug}', this.baseService.slugUri);
        return this.get(url, true);
    }

    public getProgramListing(criteria: any): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.PROMOTION.GET_PROGRAM_LISTING.replace('{supplier_slug}', this.baseService.slugUri) +
            '?' +
            this.getQueryString(criteria);
        return this.get(url, true);
    }

    public getListing(criteria: any): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.PROMOTION.GET_LISTING.replace('{supplier_slug}', this.baseService.slugUri) +
            '?' +
            this.getQueryString(criteria);
        return this.get(url, true);
    }
}
