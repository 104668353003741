export const SUPPLIER: any = {
    TAX: {
        NO_TAX: 1,
        VAT: 2,
        WITHHOLDING_TAX: 3,
        INCLUDE_VAT_WITH_TAX: 4,
        INCLUDE_VAT_WITHOUT_TAX: 5,
    },
    DEFAULT_TAB: {
        DEAL: 'deal',
        JOIN: 'join',
    },
};
