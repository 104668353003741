import { Action } from '@ngrx/store';

export enum ActionTypes {
    LOAD_SUPPLIER_INFO = '[Supplier] Load supplier info.',
    LOAD_SUPPLIER_INFO_SUCCESS = '[Supplier] Load supplier info success.',
    LOAD_ORDER_INFO = '[Supplier] Load order info.',
    LOAD_ORDER_INFO_SUCCESS = '[Supplier] Load order info success.',
    FAILURE = '[Supplier] Failure.',
}

export class ActionLoadOrderInfo implements Action {
    readonly type = ActionTypes.LOAD_ORDER_INFO;
    constructor() {}
}

export class ActionLoadOrderInfoSuccess implements Action {
    readonly type = ActionTypes.LOAD_ORDER_INFO_SUCCESS;
    constructor(public payload: { info: any }) {}
}

export class ActionLoadSupplierInfo implements Action {
    readonly type = ActionTypes.LOAD_SUPPLIER_INFO;
    constructor() {}
}

export class ActionLoadSupplierInfoSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUPPLIER_INFO_SUCCESS;
    constructor(public payload: { info: any }) {}
}

export class ActionFailure implements Action {
    readonly type = ActionTypes.FAILURE;
    constructor(public error: any) {}
}

export type Actions =
    | ActionLoadOrderInfo
    | ActionLoadOrderInfoSuccess
    | ActionLoadSupplierInfo
    | ActionLoadSupplierInfoSuccess
    | ActionFailure;
