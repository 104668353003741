import { initialState, AgencyState } from './state';
import { Actions, ActionTypes } from './actions';

export function reducer(state: AgencyState = initialState, action: Actions): AgencyState {
    switch (action.type) {
        case ActionTypes.LOAD_AGENCY:
            return {
                ...state,
                isLoading: true,
                agency: null,
                error: null,
            };
        case ActionTypes.LOAD_AGENCY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                agency: action.payload.agency,
                error: null,
            };
        case ActionTypes.VERIFY_CONTACT_EMAIL:
            return {
                ...state,
                isLoading: true,
                hasContactEmail: false,
                error: null,
            };
        case ActionTypes.VERIFY_CONTACT_EMAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasContactEmail: action.payload.hasEmailContact,
                error: null,
            };
        case ActionTypes.UPDATE_CONTACT_EMAIL:
            return {
                ...state,
                isLoading: true,
                hasContactEmail: false,
                error: null,
            };
        case ActionTypes.UPDATE_CONTACT_EMAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasContactEmail: true,
                error: null,
            };
        case ActionTypes.FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
}
