import { Action } from '@ngrx/store';
import { ISignupAgency } from 'app/shared/models/signup-agency';

export enum ActionTypes {
    SIGNUP = '[Signup] Signup',
    SIGNUP_SUCCESS = '[Signup] Signup Success',
    CREATE_PASSWORD = '[Signup] Create Password',
    CREATE_PASSWORD_SUCCESS = '[Signup] Create Password Success',
    LOAD_AGENCY = '[Signup] Load Agency',
    LOAD_AGENCY_SUCCESS = '[Signup] Load Agency Success',
    FAILURE = '[Signup] Failure',
}

export class ActionSignup implements Action {
    readonly type = ActionTypes.SIGNUP;
    constructor(public payload: { body: any }) {}
}

export class ActionSignupSuccess implements Action {
    readonly type = ActionTypes.SIGNUP_SUCCESS;
    constructor(public payload: { token: any; has_password: boolean }) {}
}

export class ActionCreatePassword implements Action {
    readonly type = ActionTypes.CREATE_PASSWORD;
    constructor(public payload: { body: any }) {}
}

export class ActionCreatePasswordSuccess implements Action {
    readonly type = ActionTypes.CREATE_PASSWORD_SUCCESS;
    constructor() {}
}

export class ActionLoadAgency implements Action {
    readonly type = ActionTypes.LOAD_AGENCY;
    constructor(public criteria: any) {}
}

export class ActionLoadAgencySuccess implements Action {
    readonly type = ActionTypes.LOAD_AGENCY_SUCCESS;
    constructor(public payload: { agency: ISignupAgency }) {}
}

export class ActionFailure implements Action {
    readonly type = ActionTypes.FAILURE;
    constructor(public error: any) {}
}

export type Actions =
    | ActionSignup
    | ActionSignupSuccess
    | ActionCreatePassword
    | ActionCreatePasswordSuccess
    | ActionLoadAgency
    | ActionLoadAgencySuccess
    | ActionFailure;
