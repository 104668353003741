import { Component } from '@angular/core';

@Component({
    selector: 'app-main-template',
    templateUrl: './main-template.component.html',
    styleUrls: ['./main-template.component.scss'],
})
export class MainTemplateComponent {
    constructor() {}
}
