import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
    Actions as SaleActions,
    ActionTypes as OrderActionTypes,
    ActionLoadJoinTourList,
    ActionLoadJoinTourListSuccess,
    ActionFailure,
    ActionLoadProductSummaries,
    ActionLoadProductSummariesSuccess,
} from './actions';
import { SaleService } from 'app/services/sale.service';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class SaleStoreEffects {
    constructor(private actions$: Actions, private saleService: SaleService) {}

    
    loadJoinTours = createEffect(() => () =>
        this.actions$.pipe(
            ofType<SaleActions>(OrderActionTypes.LOAD_JOIN_TOUR_LIST),
            switchMap((action: ActionLoadJoinTourList) => {
                return this.saleService.getJoinsList(action.criteria).pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }

                        return new ActionLoadJoinTourListSuccess(
                            {
                                list: resp.data.data,
                                total: resp.data.total,
                            },
                            action.clearList
                        );
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));

    
    loadProductSummaries = createEffect(() => () =>
        this.actions$.pipe(
            ofType<SaleActions>(OrderActionTypes.LOAD_PRODUCT_SUMMARIES),
            switchMap((action: ActionLoadProductSummaries) => {
                return this.saleService.getProductSummaries().pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }
                        return new ActionLoadProductSummariesSuccess({
                            list: resp.data,
                        });
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));
}
