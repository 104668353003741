import { initialState, OrderState } from './state';
import { Actions, ActionTypes } from './actions';
import { Order } from 'app/shared/models/order.model';

export function reducer(state: OrderState = initialState, action: Actions): OrderState {
    switch (action.type) {
        case ActionTypes.LOAD_ORDER:
            return {
                ...state,
                isLoading: true,
                order: null,
                orderDraft: null,
                error: null,
            };
        case ActionTypes.LOAD_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                order: action.payload.order,
                orderDraft: action.payload.order,
                error: null,
            };

        case ActionTypes.LOAD_ORDER_HISTORY:
            return {
                ...state,
                isLoading: true,
                totalOrderHistory: 0,
                orderHistory: state.orderHistory || [],
                error: null,
            };
        case ActionTypes.LOAD_ORDER_HISTORY_SUCCESS:
            if (!state.orderHistory || action.clearList) {
                state.orderHistory = [];
            }

            state.orderHistory = state.orderHistory.concat(action.payload.orders);

            return {
                ...state,
                isLoading: false,
                stopLoadingorderHistory: action.payload.orders.length === 0 ? true : false,
                totalOrderHistory: action.payload.total,
                orderHistory: state.orderHistory,
                error: null,
            };
        case ActionTypes.ORDER_DRAFT_CHANGE:
            return {
                ...state,
                orderDraft: action.payload,
            };
        case ActionTypes.PERIOD_DRAFT_CHANGE:
            return {
                ...state,
                periodDraft: action.payload,
            };
        case ActionTypes.CONFIRM_CREATE_ORDER:
            return {
                ...state,
                confirmCreateOrder: action.payload,
                isCreateOrderSuccess: null,
            };
        case ActionTypes.CREATE_ORDER:
            return {
                ...state,
                order: null,
                isCreateOrderSuccess: null,
                isLoading: true,
                error: null,
            };
        case ActionTypes.CREATE_ORDER_SUCCESS:
            return {
                ...state,
                isCreateOrderSuccess: action.payload.orderId,
                createOrderSuccessMessage: action.payload.orderMessage,
                isLoading: false,
                error: null,
            };
        case ActionTypes.CREATE_ORDER_FAILED:
            return {
                ...state,
                isCreateOrderSuccess: -1,
                isLoading: false,
                error: action.payload.info,
            };
        case ActionTypes.CANCEL_ORDER:
            return {
                ...state,
                isCancelOrderSuccess: false,
                isLoading: true,
                error: null,
            };
        case ActionTypes.CANCEL_ORDER_SUCCESS:
            // Update status cancel on order history list.
            if (state.orderHistory) {
                state.orderHistory.map((order: any) => {
                    if (order.id === action.id) {
                        order.order_status_key = 'canceled';
                        order.order_status_text = 'ยกเลิก';
                    }
                });
            }

            return {
                ...state,
                isCancelOrderSuccess: true,
                isLoading: false,
                error: null,
            };
        case ActionTypes.FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
}
