import { initialState, SignupState } from './state';
import { Actions, ActionTypes } from './actions';

export function reducer(state: SignupState = initialState, action: Actions): SignupState {
    switch (action.type) {
        case ActionTypes.SIGNUP:
            return {
                ...state,
                isLoading: true,
                isSignupCompleted: false,
                error: null,
            };
        case ActionTypes.SIGNUP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSignupCompleted: true,
                error: null,
            };
        case ActionTypes.CREATE_PASSWORD:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case ActionTypes.CREATE_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        case ActionTypes.LOAD_AGENCY:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case ActionTypes.LOAD_AGENCY_SUCCESS:
            return {
                ...state,
                agency: action.payload.agency,
                isLoading: false,
                error: null,
            };
        case ActionTypes.FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
}
