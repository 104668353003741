<div class="modal-header">
    <h4 class="modal-title twp-text-xl-bold text-center color-red-warning w-100">ยกเลิกรายการจอง</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div id="detail">
        <div class="d-flex">
            <strong class="d-inline-block w-25 twp-text-m color-text-normal">วันที่จอง</strong>
            <span>{{ model.createdAt }}</span>
        </div>
        <div class="d-flex">
            <strong class="d-inline-block w-25 twp-text-m color-text-normal">สินค้า</strong>
            <span>{{ model.itemDetail }}</span>
        </div>
        <div class="d-flex">
            <strong class="d-inline-block w-25 twp-text-m color-text-normal">Invoice</strong>
            <span>{{ model.totalPrice }}</span>
        </div>
        <div class="d-flex">
            <strong class="d-inline-block w-25 twp-text-m color-text-normal">ผู้เดินทาง</strong>
            <span>{{ model.numberOfPassenger }} คน</span>
        </div>
        <div class="d-flex">
            <strong class="d-inline-block w-25 twp-text-m color-text-normal">สถานะ</strong>
            <span>{{ model.status }}</span>
        </div>
    </div>
</div>
<div class="modal-footer" style="border-top: 0px">
    <button
        type="button"
        class="btn btn-primary twp-text-l"
        style="width: 150px; height: 35px"
        (click)="onCancelOrder()"
    >
        ยืนยันการยกเลิก
    </button>
    <button
        (click)="bsModalRef.hide()"
        type="button"
        class="btn btn-close twp-text-l"
        style="width: 150px; height: 35px"
    >
        ปิด
    </button>
</div>
