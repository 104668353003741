<div class="row period-detail m-0">
    <div
        class="col-8 col-lg-2 period-detail-date p-3"
        [ngClass]="{ disabled: period.quantity_remain <= 0 || period.sell_status_code !== 1 }"
    >
        <div class="">
            <div class="d-flex flex-row pt-2">
                <div
                    class="d-block d-lg-none twp-text-l mr-2"
                    *ngIf="period.go_transportation && period.go_transportation.code"
                >
                    <span>{{ period.go_transportation?.code }}</span>
                </div>
                <div class="period twp-text-l-bold pb-2">{{ period.start_at | thaidaterange: period.end_at }}</div>
            </div>
            <!-- mobile commission -->
            <div class="d-block d-lg-none">
                <span
                    class="commission-label twp-text-m pr-1"
                    *ngIf="authenticated && period.is_display_commission && period.sell_status_code === 1"
                >
                    คอม

                    <span class="commission-value">
                        {{ period.commission_company | number }}
                        <ng-container *ngIf="period.commission_company && period.commission_seller">
                            + {{ period.commission_seller | number }}
                        </ng-container>
                    </span>
                </span>
                <span *ngIf="period.sell_status_code === 1" class="wp-text-m ml-2"
                    >Size
                    <span class="twp-text-l"
                        >{{ period.quantity }}
                        <ng-container *ngIf="period.quantity_tour_lead && period.quantity_tour_lead > 0">
                            + {{ period.quantity_tour_lead }}</ng-container
                        >
                    </span>
                </span>
            </div>
            <!-- dekstop -->
            <div class="labels">
                <div class="d-none d-lg-block">
                    <span class="tg pr-1" *ngIf="period.go_transportation && period.go_transportation.code">
                        <span class="tag">{{ period.go_transportation?.code }}</span>
                    </span>
                    <span *ngIf="period.sell_status_code === 1" class="size pr-1">
                        <span class="tag twp-text-m"
                            >Size
                            <ng-container *ngIf="period.quantity_tour_lead && period.quantity_tour_lead > 0">
                                {{ period.quantity }} + {{ period.quantity_tour_lead }}</ng-container
                            >
                            <ng-container *ngIf="!period.quantity_tour_lead">{{ period.quantity }}</ng-container>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <!-- select room desktop -->
    <div
        class="col-5 d-none d-lg-block p-3"
        [ngClass]="{ disabled: period.quantity_remain <= 0 || period.sell_status_code !== 1 }"
    >
        <div class="d-inline-flex align-items-center period-detail-skus-box flex-column w-100">
            <div class="w-100 row">
                <ng-container *ngFor="let skus of period.skus | reOrderPeriodSKUs; let i = index">
                    <ng-container class="" *ngIf="i <= 2">
                        <div class="col-4 btn-skus-price">
                            <button
                                *ngIf="skus.price && +skus.price > 0"
                                type="button"
                                class="btn btn-skus d-flex align-items-stretch justify-content-around"
                            >
                                <div class="icon-wrapper-{{ skus.sku }}">
                                    <i [className]="'icon-price-' + skus.sku"></i>
                                </div>
                                <div class="price ml-2">
                                    <div class="twp-text-l" *ngIf="skus.compare_price && +skus.compare_price > 0">
                                        <div class="color-red-warning">{{ skus.price | currencyNumber2Decimal }}</div>
                                        <div
                                            class="twp-text-s color-grey-text-normal text-right"
                                            style="text-decoration: line-through; line-height: 0.8"
                                        >
                                            {{ skus.compare_price | currencyNumber2Decimal: true }}
                                        </div>
                                    </div>
                                    <div class="wp-text-l" *ngIf="!skus.compare_price || +skus.compare_price === 0">
                                        {{ skus.price | currencyNumber2Decimal }}
                                    </div>
                                </div>
                            </button>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="(period.skus | filterPeriodPriceSKUs).length > 3">
                    <div class="ml-auto">
                        <button
                            type="button"
                            class="btn btn-show-skus twp-text-s h-100"
                            (click)="showSkus[periodIndex] = !showSkus[periodIndex]"
                        >
                            <i
                                class="fa fa-2x"
                                [ngClass]="{
                                    'fa-angle-down': !showSkus[periodIndex],
                                    'fa-angle-up': showSkus[periodIndex]
                                }"
                            ></i>
                        </button>
                    </div>
                </ng-container>
            </div>
            <div class="w-100 row">
                <ng-container *ngFor="let skus of period.skus | reOrderPeriodSKUs; let i = index">
                    <ng-container *ngIf="i > 2 && showSkus[periodIndex]">
                        <div class="mt-2 col-4 btn-skus-price">
                            <button
                                *ngIf="skus.price && +skus.price > 0"
                                type="button"
                                class="btn btn-skus d-flex justify-content-around"
                            >
                                <div class="icon-wrapper-{{ skus.sku }}">
                                    <i [className]="'icon-price-' + skus.sku"></i>
                                </div>
                                <div class="price twp-text-l ml-2">
                                    <div class="twp-text-l" *ngIf="skus.compare_price && +skus.compare_price > 0">
                                        <div class="color-red-warning">{{ skus.price | currencyNumber2Decimal }}</div>
                                        <div
                                            class="twp-text-s color-grey-text-normal text-right"
                                            style="text-decoration: line-through; line-height: 0.8"
                                        >
                                            {{ skus.compare_price | currencyNumber2Decimal: true }}
                                        </div>
                                    </div>
                                    <div class="wp-text-l" *ngIf="!skus.compare_price || +skus.compare_price === 0">
                                        {{ skus.price | currencyNumber2Decimal }}
                                    </div>
                                </div>
                            </button>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="col-4 col-lg-5 d-inline-flex period-detail-booking justify-content-between p-3">
        <div
            class="d-none d-lg-block"
            [ngClass]="{ disabled: period.quantity_remain <= 0 || period.sell_status_code !== 1 }"
        >
            <div
                class="commission p-2"
                *ngIf="authenticated && period.is_display_commission && period.sell_status_code === 1"
            >
                <ng-container *ngIf="period.commission_company && period.commission_seller">
                    <span class="commission-label twp-text-m pr-1">คอม</span>
                    <span class="commission-value"
                        >{{ period.commission_company | number }} + {{ period.commission_seller | number }}</span
                    >
                </ng-container>
                <ng-container *ngIf="!period.commission_seller">
                    <span class="commission-label twp-text-m pr-1">คอม</span>
                    <span class="commission-value">{{ period.commission_company }}</span>
                </ng-container>
            </div>
        </div>
        <!-- remark desktop -->
        <div class="d-none d-lg-block">
            <div class="remark d-flex p-2 ml-2 mr-2" *ngIf="period.remark">
                <i class="icon-urgent"></i>
                <i class="d-none icon-urgent-red"></i>
                <div class="twp-text-m-bold">{{ period.remark }}</div>
            </div>
        </div>

        <div class="booking">
            <ng-container *ngIf="period.quantity_remain > 0 && period.sell_status_code === 1">
                <button
                    class="btn btn-period btn-period-available px-2 twp-text-xxl font-weight-bold"
                    (click)="reserve(period.pro_programs_id, period.id)"
                >
                    จอง
                </button>
                <div class="font-weight-bold twp-text-m text-center color-text-title mt-1">
                    (ว่าง {{ period.quantity_remain }})
                </div>
            </ng-container>

            <ng-container *ngIf="period.quantity_remain === 0 && period.sell_status_code === 1">
                <button class="font-weight-bold color-red btn btn-period btn-period-unavailable full px-2 twp-text-xxl">
                    เต็ม
                </button>
            </ng-container>

            <ng-container *ngIf="period.sell_status_code !== 1">
                <button
                    class="font-weight-bold color-white btn btn-period btn-period-unavailable px-2 twp-text-xxl"
                    [ngClass]="{ 'close-group-color': period.sell_status_code === 3 }"
                >
                    <ng-container *ngIf="period.sell_status_code === 1"> ว่าง </ng-container>
                    <ng-container *ngIf="period.sell_status_code === 3">
                        {{ period.sell_status_reason }}
                    </ng-container>
                </button>
            </ng-container>
        </div>
    </div>

    <!-- select room mobile -->
    <div class="col-12 d-block d-lg-none p-2">
        <div
            class="d-inline-flex align-items-center period-detail-skus-box flex-column w-100"
            [ngClass]="{ disabled: period.quantity_remain <= 0 || period.sell_status_code !== 1 }"
        >
            <!-- SKU 1-3 -->
            <div class="w-100 d-flex">
                <ng-container *ngFor="let skus of period.skus; let i = index">
                    <ng-container class="" *ngIf="i <= 2">
                        <button
                            *ngIf="skus.price && +skus.price > 0"
                            type="button"
                            class="btn btn-skus btn-skus-price d-flex align-items-stretch justify-content-around mr-2 pl-0"
                        >
                            <div class="icon-wrapper-{{ skus.sku }}">
                                <i [className]="'icon-price-' + skus.sku"></i>
                            </div>
                            <div class="price ml-2 py-1">
                                <div class="twp-text-m" *ngIf="skus.compare_price && +skus.compare_price > 0">
                                    <div class="color-red-warning">{{ skus.price | currencyNumber2Decimal }}</div>
                                    <div
                                        class="twp-text-s color-grey-text-normal text-right"
                                        style="text-decoration: line-through; line-height: 0.8"
                                    >
                                        {{ skus.compare_price | currencyNumber2Decimal: true }}
                                    </div>
                                </div>
                                <div class="twp-text-m" *ngIf="!skus.compare_price || +skus.compare_price === 0">
                                    {{ skus.price | currencyNumber2Decimal }}
                                </div>
                            </div>
                        </button>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="(period.skus | filterPeriodPriceSKUs).length > 3">
                    <button
                        type="button"
                        class="btn btn-show-skus twp-text-s d-flex"
                        (click)="showSkus[periodIndex] = !showSkus[periodIndex]"
                    >
                        <i
                            class="fa fa-2x"
                            [ngClass]="{
                                'fa-angle-down': !showSkus[periodIndex],
                                'fa-angle-up': showSkus[periodIndex]
                            }"
                        ></i>
                    </button>
                </ng-container>
            </div>
            <!-- SKU 3-6 -->
            <div class="w-100 d-flex">
                <ng-container *ngFor="let skus of period.skus; let i = index">
                    <ng-container *ngIf="i > 2 && showSkus[periodIndex]">
                        <button
                            type="button"
                            class="btn btn-skus btn-skus-price d-flex justify-content-around pl-0 mt-2 mr-2 has-more"
                        >
                            <div class="icon-wrapper-{{ skus.sku }}">
                                <i [className]="'icon-price-' + skus.sku"></i>
                            </div>
                            <div class="price twp-text-m ml-2 py-1">
                                <div class="twp-text-m" *ngIf="skus.compare_price && +skus.compare_price > 0">
                                    <div class="color-red-warning">{{ skus.price | currencyNumber2Decimal }}</div>
                                    <div
                                        class="twp-text-s color-grey-text-normal text-right"
                                        style="text-decoration: line-through; line-height: 0.8"
                                    >
                                        {{ skus.compare_price | currencyNumber2Decimal: true }}
                                    </div>
                                </div>
                                <div class="twp-text-m" *ngIf="!skus.compare_price || +skus.compare_price === 0">
                                    {{ skus.price | currencyNumber2Decimal }}
                                </div>
                            </div>
                        </button>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>

    <!-- remark mobile -->
    <div class="d-block d-lg-none my-2">
        <div class="remark d-flex p-2 ml-2 mr-2" *ngIf="period.remark">
            <i class="icon-urgent"></i>
            <i class="d-none icon-urgent-red"></i>
            <div class="twp-text-m-bold">{{ period.remark }}</div>
        </div>
    </div>
</div>
