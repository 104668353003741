import { initialState, SaleState } from './state';
import { Actions, ActionTypes } from './actions';

export function reducer(state: SaleState = initialState, action: Actions): SaleState {
    switch (action.type) {
        case ActionTypes.SEARCH_CRITERIA_CHANGE:
            return {
                ...state,
                isLoading: false,
                searchCriteria: action.criteria,
                error: null,
            };
        case ActionTypes.LOAD_JOIN_TOUR_LIST:
            return {
                ...state,
                isLoading: true,
                joinTours: state.joinTours || [],
                joinToursTotal: 0,
                error: null,
            };
        case ActionTypes.LOAD_JOIN_TOUR_LIST_SUCCESS:
            if (!state.joinTours || action.clearList) {
                state.joinTours = [];
            }
            state.joinTours = state.joinTours.concat(action.payload.list);
            return {
                ...state,
                isLoading: false,
                stopLoadingJoinTours: action.payload.list.length === 0 ? true : false,
                joinTours: state.joinTours,
                joinToursTotal: action.payload.total,
                error: null,
            };
        case ActionTypes.LOAD_PRODUCT_SUMMARIES:
            return {
                ...state,
                isLoading: true,
                productSummaries: null,
                error: null,
            };
        case ActionTypes.LOAD_PRODUCT_SUMMARIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                productSummaries: action.payload.list,
                error: null,
            };
        case ActionTypes.FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
}
