<div class="modal-header py-1">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div [formGroup]="form">
        <ng-container *ngIf="currentState === loginState.LOGIN">
            <h4 class="twp-text-xl-bold text-center">
                <i class="icon-member-modal text-center mb-2"></i><br />
                เข้าสู่ระบบ
            </h4>

            <div class="form-group mb-1">
                <label class="twp-text-s color-grey-text-description" for="email">Username</label>
                <input
                    type="email"
                    class="form-control"
                    formControlName="email"
                    [ngClass]="{ 'is-invalid': submitted && form.get('email').errors }"
                />
            </div>
            <div class="form-group" [ngClass]="{ 'mb-0': submitted && form.get('password').errors }">
                <label class="twp-text-s color-grey-text-description" for="password">Password</label>
                <input
                    type="password"
                    class="form-control"
                    formControlName="password"
                    [ngClass]="{ 'is-invalid': submitted && form.get('password').errors }"
                />
            </div>

            <div class="text-center" *ngIf="submitted && (form.invalid || cannotLogin)">
                <label class="form-check-label text-danger mb-2"
                    >ไม่สามารถเข้าสู่ระบบได้ เนื่องจาก Username หรือ Password ไม่ถูกต้อง</label
                >
            </div>

            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-primary btn-login twp-text-l w-100" (click)="login()">
                    เข้าสู่ระบบ
                </button>
            </div>
            <div class="d-flex justify-content-center signup-content">
                <u class="mr-3" (click)="signup()">สมัครสมาชิก</u>
                <u (click)="resetPassword()">ลืมรหัสผ่าน?</u>
            </div>
        </ng-container>
        <ng-container *ngIf="currentState === loginState.RESET_PASSWORD">
            <div *ngIf="supplierInfo$ | async as supplier" class="forget-password-modal">
                <img class="supplier-logo" [src]="supplier.image_path" alt="supplier.name_en" />
                <h4>ลืมรหัสผ่าน</h4>
                <div class="text">
                    กรุณาติดต่อ {{ supplier.name_th }} ({{ supplier.name_en }})<br />
                    โทร. {{ supplier.tel | phoneNumber }}
                </div>
                <button type="button" (click)="bsModalRef.hide()" class="btn btn-primary btn-close">ปิด</button>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="currentState === loginState.RESET_PASSWORD_SUCCESS">
        <div class="reset-password-success">
            <i class="icon-success-modal text-center"></i>
            <p>ระบบได้ทำการส่งอีเมลการตั้งรหัสผ่านใหม่ ไปยัง {{ form.get('email').value }} เรียบร้อยแล้ว</p>
        </div>
    </ng-container>

    <ng-container *ngIf="currentState === loginState.REQUIRE_LOGIN">
        <h4 class="twp-text-xl-bold text-center">
            <i class="icon-member-modal text-center mb-2"></i><br />
            กรุณาเข้าสู่ระบบเพื่อทำรายการจอง
        </h4>
        <div class="require-login" *ngIf="supplierInfo$ | async as supplierInfo">
            <p>
                กรณีที่ท่านยังไม่มีข้อมูล Log in กรุณา Sign up หรือ ติดต่อ {{ supplierInfo.name_en }} โทร.
                {{ supplierInfo.tel }}
            </p>
        </div>
        <div class="d-flex justify-content-center">
            <button
                type="button"
                class="btn btn-primary btn-login twp-text-l w-100"
                (click)="currentState = loginState.LOGIN"
            >
                เข้าสู่ระบบ
            </button>
        </div>
        <div class="d-flex justify-content-center signup-content">
            <u class="mr-3" (click)="signup()">สมัครสมาชิก</u>
        </div>
    </ng-container>
</div>
