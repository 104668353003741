<div class="modal-header py-1">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div [formGroup]="form">
        <h4 class="twp-text-xl-bold text-center">
            <i class="icon-member-modal text-center mb-2"></i>
        </h4>
        <div class="twp-text-xl-bold color-grey-text-title mb-3">กรุณากรอกอีเมลที่ใช้ติดต่อ - ส่งเอกสาร Invoice</div>
        <div class="form-group mb-1">
            <input
                type="email"
                class="form-control"
                formControlName="email"
                placeholder="อีเมลที่ใช้ติดต่อ - ส่งเอกสาร Invoice"
                [ngClass]="{ 'is-invalid': submitted && form.get('email').errors }"
            />
        </div>
        <div class="" *ngIf="submitted && form.invalid">
            <label class="form-check-label text-danger mb-2"> กรุณากรอกข้อมูลให้ถูกต้อง </label>
        </div>
        <div class="d-flex justify-content-center mt-3">
            <button type="button" class="btn btn-primary btn-login twp-text-l w-75" (click)="confirm()">
                ยืนยันข้อมูล
            </button>
        </div>
    </div>
</div>
