import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { MomentUtil } from '../utils/moment.util';

const thaiMonths = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
];

const thaiMonthsAbbreviation = [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.',
];

@Pipe({
    name: 'datePeriod',
})
export class DatePeriodPipe implements PipeTransform {
    transform(startDate: Date, endDate: Date, args?: any): any {
        let periodString = '';
        const start = moment(startDate);
        const end = moment(endDate);

        if (!start.isValid() || !end.isValid()) {
            return periodString;
        }

        if (start.month() === end.month() && start.year() === end.year()) {
            periodString = `${thaiMonthsAbbreviation[start.month()]} ${this.convertToBuddhistYear(start)}`;
        } else if (start.month() !== end.month() && start.year() !== end.year()) {
            periodString = `${thaiMonthsAbbreviation[start.month()]} ${this.convertToBuddhistYear(start)}
                            - ${thaiMonthsAbbreviation[end.month()]} ${this.convertToBuddhistYear(end)}`;
        } else {
            periodString = `${thaiMonthsAbbreviation[start.month()]}
                            - ${thaiMonthsAbbreviation[end.month()]} ${this.convertToBuddhistYear(end)}`;
        }
        return periodString;
    }

    private convertToBuddhistYear(m: moment.Moment): string {
        return (+m.format('YYYY') + 543).toString().slice(2);
    }
}

@Pipe({
    name: 'thaiMonthDate',
})
export class ThaiMonthDatePipe implements PipeTransform {
    transform(value: any, useThaiMonth?: boolean, includeTime?: boolean, includeYear?: boolean): any {
        if (!value) {
            return '';
        }
        const date = moment(value);
        if (!date.isValid()) {
            return '';
        }

        let dateFormat = includeTime ? 'DD MM YY เวลา HH:mm' : 'DD MM YY';
        dateFormat = includeYear ? dateFormat : 'DD/MM';
        const dateAsString = MomentUtil.toBuddhistYear(date, dateFormat);
        if (useThaiMonth) {
            const dateAsArray = dateAsString.split(' ');
            const monthIndex = dateAsArray['1'] - 1;
            const monthSelected = thaiMonthsAbbreviation[monthIndex];
            dateAsArray['1'] = monthSelected;
            const result = dateAsArray.join(' ');
            return result;
        }

        return dateAsString;
    }
}

@Pipe({
    name: 'thaidaterange',
})
export class ThaiDateRangePipe implements PipeTransform {
    transform(date1: string, date2: string): any {
        let result = '';
        const date1Obj = moment(date1);
        const date2Obj = moment(date2);
        if (!date1Obj.isValid() || !date2Obj.isValid()) {
            return result;
        }

        if (
            date1Obj.date() === date2Obj.date() &&
            date1Obj.month() === date2Obj.month() &&
            date1Obj.year() === date2Obj.year()
        ) {
            result = `${date2Obj.format('DD')} ${thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(
                date1Obj
            )}`;
        } else if (date1Obj.month() === date2Obj.month() && date1Obj.year() === date2Obj.year()) {
            result = `${date1Obj.format('DD')} - ${date2Obj.format('DD')} ${
                thaiMonthsAbbreviation[date1Obj.month()]
            } ${this.convertToBuddhistYear(date1Obj)}`;
        } else if (date1Obj.month() !== date2Obj.month() && date1Obj.year() !== date2Obj.year()) {
            result = `${date1Obj.format('DD')} ${thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(
                date1Obj
            )} - ${date2Obj.format('DD')} ${thaiMonthsAbbreviation[date2Obj.month()]} ${this.convertToBuddhistYear(
                date2Obj
            )}`;
        } else {
            result = `${date1Obj.format('DD')} ${thaiMonthsAbbreviation[date1Obj.month()]} - ${date2Obj.format('DD')} ${
                thaiMonthsAbbreviation[date2Obj.month()]
            } ${this.convertToBuddhistYear(date2Obj)}`;
        }
        return result;
    }

    private convertToBuddhistYear(date: any): string {
        return (+date.format('YYYY') + 543).toString().slice(2);
    }
}

@Pipe({
    name: 'thaiDateFormat',
})
export class ThaiDateFormatPipe implements PipeTransform {
    constructor() {
        // Update local month name
        moment.updateLocale('thbe', { months: thaiMonthsAbbreviation });
    }

    transform(date: string | number | Date, format: string = 'DD/MM/YY'): any {
        const result = '';
        const rawDate = moment(date);

        if (!rawDate.isValid()) {
            return result;
        }

        rawDate.add(543, 'years');
        return rawDate.format(format).toString();
    }

    private convertToBuddhistYear(date: any): string {
        return (+date.format('YYYY') + 543).toString().slice(2);
    }
}

@Pipe({
    name: 'thaiMonthDateTime',
})
export class ThaiMonthDatetimePipe implements PipeTransform {
    transform(value: any, useAbbr: boolean = false): any {
        if (!value) {
            return;
        }
        const date = moment(value);
        if (!date.isValid()) {
            return;
        }

        const thaiDate = date.add(543, 'y');
        const dateAsString = thaiDate.format('DD/MM/YY HH:mm');

        const dateAsArray = dateAsString.split('/');
        const monthIndex = Number(dateAsArray[1]) - 1;
        const monthSelected = useAbbr ? thaiMonthsAbbreviation[monthIndex] : thaiMonths[monthIndex];
        dateAsArray['1'] = monthSelected;
        const result = dateAsArray.join(' ');
        return result;
    }
}
