import { Action } from '@ngrx/store';
import { MemberFormValues } from 'app/modules/member/components/member-form/member-form.component';
import { MemberInfo } from 'app/shared/models/members/member-info.model';
import { MemberListing } from 'app/shared/models/members/member-listing.model';

export enum ActionTypes {
    LOAD_MEMBER_LISTING = '[Member] Load Member Listing',
    LOAD_MEMBER_LISTING_SUCCESS = '[Member] Load Member Listing Success',

    LOAD_MEMBER_INFO = '[Member] Load Member Info',
    LOAD_MEMBER_INFO_SUCCESS = '[Member] Load Member Info Success',

    CREATE_MEMBER = '[Member] Create Member',
    CREATE_MEMBER_SUCCESS = '[Member] Create Member Success',
    CREATE_MEMBER_FAILURE = '[Member] Create Member Failure',

    UPDATE_MEMBER = '[Member] Update Member',
    UPDATE_MEMBER_SUCCESS = '[Member] Update Member Success',
    UPDATE_MEMBER_FAILURE = '[Member] Update Member Failure',

    DELETE_MEMBER = '[Member] Delete Member',
    DELETE_MEMBER_SUCCESS = '[Member] Delete Member Success',
    DELETE_MEMBER_FAILURE = '[Member] Delete Member Failure',

    FAILURE = '[Member] Failure',
}

/** Member listing */
export class ActionLoadMemberListing implements Action {
    readonly type = ActionTypes.LOAD_MEMBER_LISTING;
    constructor() {}
}

export class ActionLoadMemberListingSuccess implements Action {
    readonly type = ActionTypes.LOAD_MEMBER_LISTING_SUCCESS;
    constructor(public listing: MemberListing) {}
}

/** Member info */
export class ActionLoadMemberInfo implements Action {
    readonly type = ActionTypes.LOAD_MEMBER_INFO;
    constructor() {}
}

export class ActionLoadMemberInfoSuccess implements Action {
    readonly type = ActionTypes.LOAD_MEMBER_INFO_SUCCESS;
    constructor(public info: MemberInfo) {}
}

/** Delete member */
export class ActionDeleteMember implements Action {
    readonly type = ActionTypes.DELETE_MEMBER;
    constructor(public payload: { memberId: number }) {}
}

export class ActionDeleteMemberSuccess implements Action {
    readonly type = ActionTypes.DELETE_MEMBER_SUCCESS;
    constructor(public payload: { memberId: number }) {}
}

export class ActionDeleteMemberFailure implements Action {
    readonly type = ActionTypes.DELETE_MEMBER_FAILURE;
    constructor(public payload: { memberId: number; error: any }) {}
}

/** Create member */
export class ActionCreateMember implements Action {
    readonly type = ActionTypes.CREATE_MEMBER;
    constructor(public payload: { requestBody: MemberFormValues }) {}
}

export class ActionCreateMemberSuccess implements Action {
    readonly type = ActionTypes.CREATE_MEMBER_SUCCESS;
    constructor(public payload: { memberId: number }) {}
}

export class ActionCreateMemberFailure implements Action {
    readonly type = ActionTypes.CREATE_MEMBER_FAILURE;
    constructor(public payload: { error: any }) {}
}

/** Update member */
export class ActionUpdateMember implements Action {
    readonly type = ActionTypes.UPDATE_MEMBER;
    constructor(public payload: { memberId: number; requestBody: MemberFormValues }) {}
}

export class ActionUpdateMemberSuccess implements Action {
    readonly type = ActionTypes.UPDATE_MEMBER_SUCCESS;
    constructor(public payload: { memberId: number }) {}
}

export class ActionUpdateMemberFailure implements Action {
    readonly type = ActionTypes.UPDATE_MEMBER_FAILURE;
    constructor(public payload: { error: any }) {}
}

/** etc */
export class ActionFailure implements Action {
    readonly type = ActionTypes.FAILURE;
    constructor(public error: any) {}
}

export type Actions =
    | ActionLoadMemberListing
    | ActionLoadMemberListingSuccess
    | ActionLoadMemberInfo
    | ActionLoadMemberInfoSuccess
    | ActionFailure
    | ActionDeleteMember
    | ActionDeleteMemberSuccess
    | ActionDeleteMemberFailure
    | ActionCreateMember
    | ActionCreateMemberSuccess
    | ActionCreateMemberFailure
    | ActionUpdateMember
    | ActionUpdateMemberSuccess
    | ActionUpdateMemberFailure;
