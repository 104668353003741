import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrderState } from './state';
import { FEATURE_NAME } from './order-store.module';

export const selectOrderState = createFeatureSelector<OrderState>(FEATURE_NAME);

export const selectOrder = createSelector(selectOrderState, (state: OrderState): any => state.order);

export const selectOrderDraft = createSelector(selectOrderState, (state: OrderState): any => state.orderDraft);

export const selectPeriodDraft = createSelector(selectOrderState, (state: OrderState): any => state.periodDraft);

export const selectConfirmCreateOrder = createSelector(
    selectOrderState,
    (state: OrderState): any => state.confirmCreateOrder
);

export const selectIsCreateOrderSuccess = createSelector(selectOrderState, (state: OrderState): any => {
    return {
        id: state.isCreateOrderSuccess,
        msg: state.createOrderSuccessMessage,
    };
});

export const selectCreateOrderSuccessMessage = createSelector(
    selectOrderState,
    (state: OrderState): any => state.createOrderSuccessMessage
);

export const selectIsCancelOrderSuccess = createSelector(
    selectOrderState,
    (state: OrderState): any => state.isCancelOrderSuccess
);

export const selectOrderHistory = createSelector(selectOrderState, (state: OrderState): any => state.orderHistory);

export const selectTotalOrderHistory = createSelector(
    selectOrderState,
    (state: OrderState): any => state.totalOrderHistory
);

export const selectIsLoading = createSelector(selectOrderState, (state: OrderState): any =>
    state !== undefined ? state.isLoading : undefined
);

export const selectFailure = createSelector(selectOrderState, (state: OrderState): any => state.error);

export const selectStopLoadingOrderHistory = createSelector(selectOrderState, (state: OrderState): any => {
    if (state) {
        return state.stopLoadingorderHistory;
    }
});
