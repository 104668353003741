<ng-template #modal>
    <div class="">
        <div class="modal-header">
            <h1 class="modal-title">{{ title }}</h1>
            <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <iframe id="" frameborder="0" [src]="resource | safe">
                <p>It appears your web browser does not support iframes.</p>
            </iframe>
        </div>
    </div>
</ng-template>
