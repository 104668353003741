<div class="container payment-container" *ngIf="payment.transections.length">
    <p>ชำระแล้ว {{ payment.transectionsCount }} ครั้ง ยอดรวม {{ payment.transectionsTotalPaid | number: '1.2-2' }}</p>
    <div class="d-none d-sm-block">
        <table class="table table-borderless">
            <thead>
                <tr>
                    <th scope="col">วันที่แจ้งชำระเงิน</th>
                    <th scope="col">ยอด</th>
                    <th scope="col">งวด</th>
                    <th scope="col">ชำระโดย</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let transaction of payment.transections">
                    <td>{{ transaction.createdAt | thaiMonthDate: true:false:true }}</td>
                    <td>
                        <a
                            *ngIf="transaction.paymentMethod === PAYMENT_TYPE_TRANSFER && transaction.slipPath"
                            target="_blank"
                            href="{{ transaction.slipPath }}"
                        >
                            <i class="icon-transfer"></i>
                        </a>
                        <i
                            *ngIf="!(transaction.paymentMethod === PAYMENT_TYPE_TRANSFER && transaction.slipPath)"
                            [ngClass]="{
                                'icon-credit-card': transaction.paymentMethod === PAYMENT_TYPE_CREDIT_CARD,
                                'icon-cheque': transaction.paymentMethod === PAYMENT_TYPE_CHEQUE,
                                'icon-cash': transaction.paymentMethod === PAYMENT_TYPE_CASH,
                                'icon-transfer': transaction.paymentMethod === PAYMENT_TYPE_TRANSFER,
                                disabled: transaction.paymentMethod === PAYMENT_TYPE_TRANSFER && !transaction.slipPath
                            }"
                        >
                        </i>
                        {{ transaction.total | number: '1.2-2' }}
                    </td>
                    <td>{{ transaction.paymentInstallmentsOrdinal }}</td>
                    <td [innerHTML]="transaction.transectionDescription"></td>
                    <td>
                        <i
                            class="fa fa-trash-o text-danger"
                            *ngIf="transaction.isEnebleDelete"
                            (click)="deletePaymentTransaction(transaction.id)"
                        ></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="d-sm-none d-block">
        <table class="table table-borderless">
            <thead>
                <tr>
                    <th scope="col">วันที่แจ้งชำระเงิน</th>
                    <th scope="col">ยอด</th>
                    <th scope="col">งวด</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let transaction of payment.transections">
                    <tr>
                        <td>{{ transaction.createdAt | thaiMonthDate: true:false:true }}</td>
                        <td>
                            <a
                                *ngIf="transaction.paymentMethod === PAYMENT_TYPE_TRANSFER && transaction.slipPath"
                                target="_blank"
                                href="{{ transaction.slipPath }}"
                            >
                                <i class="icon-transfer"></i>
                            </a>
                            <i
                                *ngIf="!(transaction.paymentMethod === PAYMENT_TYPE_TRANSFER && transaction.slipPath)"
                                [ngClass]="{
                                    'icon-credit-card': transaction.paymentMethod === PAYMENT_TYPE_CREDIT_CARD,
                                    'icon-cheque': transaction.paymentMethod === PAYMENT_TYPE_CHEQUE,
                                    'icon-cash': transaction.paymentMethod === PAYMENT_TYPE_CASH,
                                    'icon-transfer': transaction.paymentMethod === PAYMENT_TYPE_TRANSFER,
                                    disabled:
                                        transaction.paymentMethod === PAYMENT_TYPE_TRANSFER && !transaction.slipPath
                                }"
                            ></i>
                            &nbsp;{{ transaction.total | number: '1.2-2' }}
                        </td>
                        <td>{{ transaction.paymentInstallmentsOrdinal }}</td>
                        <td>
                            <i
                                class="fa fa-trash-o text-danger"
                                *ngIf="transaction.isEnebleDelete"
                                (click)="deletePaymentTransaction(transaction.id)"
                            ></i>
                        </td>
                    </tr>
                    <tr>
                        <td [innerHTML]="transaction.transectionDescription" colspan="3"></td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
