import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AgencyState } from './state';
import { FEATURE_NAME } from './agency-store.module';

export const selectAgencyState = createFeatureSelector<AgencyState>(FEATURE_NAME);

export const selectAgency = createSelector(selectAgencyState, (state: AgencyState): any => state.agency);

export const selectIsLoading = createSelector(selectAgencyState, (state: AgencyState): any => state.isLoading);

export const selectHasContactEmail = createSelector(
    selectAgencyState,
    (state: AgencyState): any => state.hasContactEmail
);

export const selectFailure = createSelector(selectAgencyState, (state: AgencyState): any => state.error);
