import { AuthenticatedAgencyMember } from './auth.model';

export class Member implements IMember {
    id: number;
    first_name: string;
    last_name: string;
    nick_name: string;
    full_name: string;
    channel_ob_status_code: string;
    suppliers: string[];
    expire_time: number;
    agency_email: string;

    deserialize(object: any): Member | null {
        if (!object) {
            return null;
        }

        Object.assign(this, object);
        this.full_name = this.getFullName(this.first_name, this.last_name, this.nick_name);
        return this;
    }

    fillFromAuthenticatedAgencyMember(agencyMember: AuthenticatedAgencyMember): this {
        this.id = agencyMember.id;
        this.first_name = agencyMember.firstName;
        this.last_name = agencyMember.lastName;
        this.nick_name = agencyMember.nickName;

        this.full_name = this.getFullName(this.first_name, this.last_name, this.nick_name);

        this.channel_ob_status_code = agencyMember.channelObStatusCode;
        this.agency_email = agencyMember.agencyEmail;
        this.suppliers = [];
        this.expire_time = null;
        return this;
    }

    private getFullName(firstName: string, lastName: string | null, nickName: string | null): string {
        let fullName = firstName;
        if (lastName) {
            fullName = `${firstName} ${lastName}`;
        }

        if (nickName) {
            fullName = `${firstName} ${lastName} (${this.nick_name})`;
        }
        return fullName;
    }
}

export interface IMember {
    id: number;
    first_name: string;
    last_name: string;
    nick_name: string;
    full_name: string;
    channel_ob_status_code: string;
    suppliers: string[];
    expire_time: number;

    deserialize(object: any): Member;
}
