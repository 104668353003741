export interface PromotionState {
    countries: any[];
    programs: any[];
    items: any[];
    totalItems: number;
    isLoading?: boolean;
    error?: any;
}

export const initialState: PromotionState = {
    countries: null,
    programs: null,
    items: null,
    totalItems: null,
    isLoading: false,
    error: null,
};
