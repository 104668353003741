import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { MainComponent } from './theme/main/main.component';
import { MainComponent as MainV2Component } from './theme/v2/main/main.component';
import { ResetPasswordComponent } from './modules/signup/pages/reset-password/reset-password.component';
import { UnauthorizedPageComponent } from './modules/auth/page/unauthorized-page/unauthorized-page.component';
import { PageNotFoundComponent } from './modules/auth/page/page-not-found/page-not-found.component';
import { ServerErrorPageComponent } from './modules/auth/page/server-error-page/server-error-page.component';
import { PAGE_NAME } from './constants/access_log.constant';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/sale',
    },
    {
        path: '',
        component: MainV2Component,
        children: [
            {
                path: 'order',
                loadChildren: () => import('./modules/order/order.module').then((m) => m.OrderModule),
            },
            {
                path: 'sale',
                loadChildren: () => import('./modules/sale/sale.module').then((m) => m.SaleModule),
            },
            {
                path: 'member',
                loadChildren: () => import('./modules/member/member.module').then((m) => m.MemberModule),
            },
            {
                path: 'signup',
                loadChildren: () => import('./modules/signup/signup.module').then((m) => m.SignupModule),
            },
            {
                path: 'reset-password',
                component: ResetPasswordComponent,
            },
        ],
    },
    {
        // Issue on SSR not known APP_BASE_HREF slug.
        path: ':supplier_slug',
        component: MainV2Component,
        children: [
            {
                path: 'order',
                loadChildren: () => import('./modules/order/order.module').then((m) => m.OrderModule),
            },
            {
                path: 'sale',
                loadChildren: () => import('./modules/sale/sale.module').then((m) => m.SaleModule),
            },
            {
                path: 'member',
                loadChildren: () => import('./modules/member/member.module').then((m) => m.MemberModule),
            },
            {
                path: 'signup',
                loadChildren: () => import('./modules/signup/signup.module').then((m) => m.SignupModule),
            },
            {
                path: 'reset-password',
                component: ResetPasswordComponent,
            },
        ],
    },
    {
        path: '401',
        component: UnauthorizedPageComponent,
        data: { page: PAGE_NAME.ERROR_PAGE_401 },
    },
    {
        path: '404',
        component: PageNotFoundComponent,
        data: { page: PAGE_NAME.ERROR_PAGE_404 },
    },
    {
        path: '500',
        component: ServerErrorPageComponent,
        data: { page: PAGE_NAME.ERROR_PAGE_500 },
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            relativeLinkResolution: 'legacy',
            initialNavigation: 'enabledBlocking',
            enableTracing: false,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
