import { IAgency } from 'app/shared/models/agency.model';

export interface AgencyState {
    agency: IAgency[];
    hasContactEmail: boolean;
    isLoading?: boolean;
    error?: any;
}

export const initialState: AgencyState = {
    agency: null,
    hasContactEmail: false,
    isLoading: false,
    error: null,
};
