<div class="container set-password-container">
    <div class="body-box">
        <div class="title">
            Online Booking
            <div class="powerd-by">
                Powered by
                <img src="assets/images/tourwow_pro_logo_footer.png" alt="Tourwow Pro Online Booking" />
            </div>
        </div>
        <!-- <p class="title"><i class="icon-order-history-white"></i> สมัครจองทัวร์ออนไลน์กับ JOYFUL HOLIDAYS CO., LTD.s</p> -->

        <div class="container form-container" [formGroup]="form">
            <p class="header">รีเซ็ตรหัสผ่านเพื่อเข้าสู่ระบบ Online booking</p>
            <div class="pt-4 pb-3">
                <div class="form-group row">
                    <label class="col-3 col-form-label">Email<app-star></app-star></label>
                    <label class="col-6 col-form-label">{{ username }}</label>
                </div>
                <div class="form-group row">
                    <label for="firstName" class="col-3 col-form-label">Password<app-star></app-star></label>
                    <div class="col-6">
                        <input
                            type="password"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && form.get('password').errors }"
                            formControlName="password"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label for="firstName" class="col-3 col-form-label">Confirm password<app-star></app-star></label>
                    <div class="col-6">
                        <input
                            type="password"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && form.get('confirmPassword').errors }"
                            formControlName="confirmPassword"
                        />
                    </div>
                </div>
            </div>
            <div class="text-center mb-2" *ngIf="submitted && (form.invalid || passwordNotMatch)">
                <label class="form-check-label text-danger"> กรุณากรอก Password ให้ถูกต้อง </label>
            </div>
            <div class="btn-wrapper text-center">
                <button class="btn btn-primary" type="button" (click)="submit()">ยืนยัน</button>
            </div>
        </div>
    </div>
</div>
