import { IPeriodOption } from 'app/shared/models/products/period-options.interface';

export interface JoinProgramState {
    joinProgram: any;
    periodOptions: IPeriodOption[];
    isLoading?: boolean;
    error?: any;
}

export const initialState: JoinProgramState = {
    joinProgram: null,
    periodOptions: [],
    isLoading: false,
    error: null,
};
