import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IAgency } from 'app/shared/models/agency.model';
import { ENDPOINTS } from 'app/constants/endpoint';

import { environment } from 'environments/environment';
import { HttpService } from './base/http.service';
import { Router } from '@angular/router';
import { TourwowBaseService } from './tourwow-base.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SaleService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router,
        private baseService: TourwowBaseService
    ) {
        super(platformId, http, router, baseService);
    }

    public getProductSummaries(): Observable<any> {
        const url =
            environment.apiProUrl + ENDPOINTS.PRODUCT.SUMMARIES.replace('{supplier_slug}', this.baseService.slugUri);
        return this.get(url, true);
    }

    public getJoinActive(criteria: any): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.PRODUCT.JOINS.GET_ACTIVE.replace('{supplier_slug}', this.baseService.slugUri) +
            '?' +
            this.getQueryString(criteria);
        return this.get(url, true);
    }

    public getJoinsList(criteria: any): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.PRODUCT.JOINS.GET_LISTING.replace('{supplier_slug}', this.baseService.slugUri) +
            '?' +
            this.getQueryString(criteria);
        return this.get(url, true);
    }

    public getAgencyList(): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.AGENCY.GET_LISTING;
        return this.get(url, true);
    }
}
