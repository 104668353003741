import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
    Actions as SupplierActions,
    ActionTypes as SupplierActionTypes,
    ActionFailure,
    ActionLoadOrderInfo,
    ActionLoadOrderInfoSuccess,
    ActionLoadSupplierInfo,
    ActionLoadSupplierInfoSuccess,
} from './actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { SupplierService } from 'app/services/supplier.service';
import { TourwowBaseService } from 'app/services/tourwow-base.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class SupplierStoreEffects {
    private supplierInfoKey = 'supplier_info_{supplier_slug}';
    private supplierInfoLocalStorageExpireHour = 24;
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        private actions$: Actions,
        private supplierService: SupplierService,
        private baseService: TourwowBaseService
    ) {}

    private getSupplierInfoFromLocalStorage(): any {
        const supplierInfoKey = this.supplierInfoKey.replace('{supplier_slug}', this.baseService.slugUri);
        let store = null;

        if (isPlatformBrowser(this.platformId)) {
            store = JSON.parse(localStorage.getItem(supplierInfoKey));
        }

        // Check expire
        if (store) {
            const now = new Date();
            const expire = new Date(store.expire_time);

            if (now > expire) {
                // expire
                const supplierInfoKey = this.supplierInfoKey.replace('{supplier_slug}', this.baseService.slugUri);
                localStorage.removeItem(supplierInfoKey);
                return null;
            }

            return store.data;
        }

        return null;
    }

    
    loadConfigOrder = createEffect(() => () =>
        this.actions$.pipe(
            ofType<SupplierActions>(SupplierActionTypes.LOAD_ORDER_INFO),
            switchMap((action: ActionLoadOrderInfo) => {
                return this.supplierService.getConfigOrderInfo().pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }

                        return resp.data;
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            }),
            switchMap((data: any) => {
                return this.supplierService.getInfo().pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }

                        return new ActionLoadOrderInfoSuccess({
                            info: Object.assign(data, resp.data),
                        });
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));

    
    loadSupplierInfo = createEffect(() => () =>
        this.actions$.pipe(
            ofType<SupplierActions>(SupplierActionTypes.LOAD_SUPPLIER_INFO),
            switchMap((action: ActionLoadSupplierInfo) => {
                const supplierInfo = this.getSupplierInfoFromLocalStorage();
                if (supplierInfo) {
                    return of(
                        new ActionLoadSupplierInfoSuccess({
                            info: supplierInfo,
                        })
                    );
                }

                return this.supplierService.getInfo().pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }

                        const supplierInfoKey = this.supplierInfoKey.replace(
                            '{supplier_slug}',
                            this.baseService.slugUri
                        );
                        const expire_time =
                            new Date().getTime() + this.supplierInfoLocalStorageExpireHour * 60 * 60 * 1000; // Current time + 1 hour.
                        const storage = {
                            data: resp.data,
                            expire_time: expire_time,
                        };
                        localStorage.setItem(supplierInfoKey, JSON.stringify(storage));
                        return new ActionLoadSupplierInfoSuccess({
                            info: resp.data,
                        });
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));
}
