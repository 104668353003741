import { Injectable } from '@angular/core';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
    Actions as PromotionActions,
    ActionTypes,
    ActionLoadPromotions,
    ActionLoadPromotionsSuccess,
    ActionFailure,
    ActionLoadCountryDeals,
    ActionLoadCountryDealsSuccess,
    ActionLoadProgramDeals,
    ActionLoadProgramDealsSuccess,
} from './actions';

import { PromotionService } from 'app/services/promotion.service';

@Injectable()
export class PromotionStoreEffects {
    constructor(private actions$: Actions, private promotionService: PromotionService) {}

    
    loadListing = createEffect(() => () =>
        this.actions$.pipe(
            ofType<PromotionActions>(ActionTypes.LOAD_PROMOTIONS),
            switchMap((action: ActionLoadPromotions) => {
                return this.promotionService.getListing(action.criteria).pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }

                        return new ActionLoadPromotionsSuccess(
                            {
                                items: resp.data.data,
                                total: resp.data.total,
                            },
                            action.clearList
                        );
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));

    
    loadCountryListing = createEffect(() => () =>
        this.actions$.pipe(
            ofType<PromotionActions>(ActionTypes.LOAD_COUNTRY_DEALS),
            switchMap((action: ActionLoadCountryDeals) => {
                return this.promotionService.getCountryListing().pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }

                        return new ActionLoadCountryDealsSuccess(
                            {
                                items: resp.data,
                            },
                            action.clearList
                        );
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));

    
    loadProgramListing = createEffect(() => () =>
        this.actions$.pipe(
            ofType<PromotionActions>(ActionTypes.LOAD_PROGRAM_DEALS),
            switchMap((action: ActionLoadProgramDeals) => {
                return this.promotionService.getProgramListing(action.criteria).pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }

                        return new ActionLoadProgramDealsSuccess(
                            {
                                items: resp.data,
                            },
                            action.clearList
                        );
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));
}
