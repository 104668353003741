import { Action } from '@ngrx/store';

export enum ActionTypes {
    LOAD_JOIN_PROGRAM_WITH_FIRST_PERIOD = '[Join Program] Load Join Program with First Period.',
    LOAD_JOIN_PROGRAM_WITH_FIRST_PERIOD_SUCCESS = '[Join Program] Load Join Program with First Period Success.',
    LOAD_JOIN_PROGRAM = '[Join Program] Load Join Program.',
    LOAD_JOIN_PROGRAM_SUCCESS = '[Join Program] Load Join Program Success.',
    LOAD_PERIOD_OPTIONS = '[Join Program] Load periods options.',
    LOAD_PERIOD_OPTIONS_SUCCESS = '[Join Program] Load periods options success.',
    FAILURE = '[Join Program] Failure.',
}

export class ActionLoadJoinProgramWithFirstPeriod implements Action {
    readonly type = ActionTypes.LOAD_JOIN_PROGRAM_WITH_FIRST_PERIOD;
    constructor(public programId: number) {}
}

export class ActionLoadJoinProgramWithFirstPeriodSuccess implements Action {
    readonly type = ActionTypes.LOAD_JOIN_PROGRAM_WITH_FIRST_PERIOD_SUCCESS;
    constructor(public payload: { joinProgram: any; periods: any[] }) {}
}

export class ActionLoadJoinProgram implements Action {
    readonly type = ActionTypes.LOAD_JOIN_PROGRAM;
    constructor(public programId: number, public periodId: number) {}
}

export class ActionLoadJoinProgramSuccess implements Action {
    readonly type = ActionTypes.LOAD_JOIN_PROGRAM_SUCCESS;
    constructor(public payload: { joinProgram: any }) {}
}

export class ActionLoadPeriodOptions implements Action {
    readonly type = ActionTypes.LOAD_PERIOD_OPTIONS;
    constructor(public programId: number) {}
}

export class ActionLoadPeriodOptionsSuccess implements Action {
    readonly type = ActionTypes.LOAD_PERIOD_OPTIONS_SUCCESS;
    constructor(public payload: { periods: any }) {}
}

export class ActionFailure implements Action {
    readonly type = ActionTypes.FAILURE;
    constructor(public error: any) {}
}

export type Actions =
    | ActionLoadJoinProgramWithFirstPeriod
    | ActionLoadJoinProgramWithFirstPeriodSuccess
    | ActionLoadJoinProgram
    | ActionLoadJoinProgramSuccess
    | ActionLoadPeriodOptions
    | ActionLoadPeriodOptionsSuccess
    | ActionFailure;
