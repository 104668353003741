import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { State } from 'app/root-store/state';
import { ActionLoadSupplierInfo } from 'app/root-store/supplier-store/actions';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { selectSupplierInfo } from 'app/root-store/supplier-store/selectors';
import { filter, take, takeUntil } from 'rxjs/operators';
import { SUPPLIER } from 'app/constants/supplier.constant';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
    public isExpandedNav = true;
    public title = 'Tourwow Pro Online Booking';
    public isHeaderShowBanner: boolean = false;
    public isHeaderShowSearchCountrires: boolean = false;
    public isHeaderShowSearchResultTabs: boolean = false;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        public store: Store<State>,
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private metaService: Meta
    ) {
        //
    }

    ngOnInit() {
        this.store.dispatch(new ActionLoadSupplierInfo());
        this.store
            .pipe(
                select(selectSupplierInfo),
                filter((val) => val !== null),
                take(1)
            )
            .subscribe((res) => {
                this.titleService.setTitle(res.name_en + ' - Online Booking');
                this.metaService.addTags([{ name: 'og:title', content: res.name_en + ' - Online Booking' }]);

                switch (res.default_tab) {
                    case SUPPLIER.DEFAULT_TAB.DEAL:
                        this.router.navigateByUrl('/sale/promotions');
                        break;
                    case SUPPLIER.DEFAULT_TAB.JOIN:
                        this.router.navigateByUrl('/sale');
                        break;
                }
            });
        this.subscribeRouteNavigationEnd();
    }

    private subscribeRouteNavigationEnd(): void {
        this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((e) => {
            if (e instanceof NavigationEnd) {
                let route = this.route.firstChild;
                let child = route;

                while (child) {
                    if (child.firstChild) {
                        child = child.firstChild;
                        route = child;
                    } else {
                        child = null;
                    }
                }

                this.setHeaderDisplay(route.data['_value']);
            }
        });

        // Get data on first time route.
        this.getDataActivateRoute();
    }

    private getDataActivateRoute(): void {
        let route = this.route.firstChild;
        let child = route;

        while (child) {
            if (child.firstChild) {
                child = child.firstChild;
                route = child;
            } else {
                child = null;
            }
        }

        if (route) {
            this.setHeaderDisplay(route.data['_value']);
        }
    }

    private setHeaderDisplay(data: any): void {
        this.isHeaderShowBanner = data['isHeaderShowBanner'];
        this.isHeaderShowSearchCountrires = data['isHeaderShowSearchCountrires'];
        this.isHeaderShowSearchResultTabs = data['isHeaderShowSearchResultTabs'];
    }

    public expandedNav(expandMenu: boolean): void {
        this.isExpandedNav = expandMenu;
    }
}
