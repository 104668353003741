import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ENDPOINTS } from 'app/constants/endpoint';
import { environment } from '../../environments/environment';
import { TourwowBaseService } from './tourwow-base.service';
import { HttpService } from './base/http.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class FileUploadService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router,
        private baseService: TourwowBaseService
    ) {
        super(platformId, http, router, baseService);
    }

    public upload(base64, fileName): Observable<any> {
        const url =
            environment.apiProUrl + ENDPOINTS.FILE_UPLOAD.POST.replace('{supplier_slug}', this.baseService.slugUri);
        let body = {
            file: base64,
            originalName: fileName,
            fileType: '',
        };
        return this.http.post(url, body);
    }
}
