import { initialState, UserState } from './state';
import { Actions, ActionTypes } from './actions';

export function reducer(state: UserState = initialState, action: Actions): UserState {
    switch (action.type) {
        case ActionTypes.LOAD_USER:
            return {
                ...state,
                isLoading: true,
                users: null,
                error: null,
            };
        case ActionTypes.LOAD_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                users: action.payload.users,
                error: null,
            };
        case ActionTypes.FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
}
