import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { ActionReducer, Action } from '@ngrx/store';
import { merge, pick } from 'lodash-es';

function setSavedState(state: any, localStorageKey: string) {
    localStorage.setItem(localStorageKey, JSON.stringify(state));
}
function getSavedState(localStorageKey: string): any {
    if (isPlatformBrowser(PLATFORM_ID)) {
        return JSON.parse(localStorage.getItem(localStorageKey));
    }

    return null;
}

// the keys from state which we'd like to save.
const stateKeys = ['auth'];
// the key for the local storage.
const localStorageKey = 'jwt_token';

export function storageMetaReducer<S, A extends Action = Action>(reducer: ActionReducer<S, A>) {
    let onInit = true; // after load/refresh…
    return function (state: S, action: A): S {
        // reduce the nextState.
        const nextState = reducer(state, action);
        // init the application state.
        if (onInit) {
            onInit = false;
            const jwtStorage = getSavedState(localStorageKey);
            return merge(nextState, {
                auth: {
                    isAuth: !!jwtStorage,
                    error: null,
                },
            });
        }
        // save the next state to the application storage.
        const stateToSave = pick(nextState, stateKeys);
        // setSavedState(stateToSave, localStorageKey);
        return nextState;
    };
}
