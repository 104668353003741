import { Component } from '@angular/core';

@Component({
    selector: 'app-server-error-page',
    templateUrl: './server-error-page.component.html',
    styleUrls: ['./server-error-page.component.css'],
})
export class ServerErrorPageComponent {
    constructor() {}
}
