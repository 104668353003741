import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

export const AlertModalIcons = {
    INFO: 1,
    SUCCESS: 2,
    ALERT: 3,
    USER: 4,
};

@Component({
    selector: 'app-alert-modal',
    templateUrl: './alert-modal.component.html',
    styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent {
    ICONS = AlertModalIcons;

    modalRef: BsModalRef;
    icon: number = AlertModalIcons.ALERT;
    title: string;
    description: string;
    buttonWidth = 150;

    constructor(public bsModalRef: BsModalRef) {}
}
