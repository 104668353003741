import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

import { ENDPOINTS } from 'app/constants/endpoint';
import { environment } from 'environments/environment';
import { TourwowBaseService } from './tourwow-base.service';
import { HttpService } from './base/http.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ProductService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router,
        private baseService: TourwowBaseService
    ) {
        super(platformId, http, router, baseService);
    }

    private httpOptions = {
        headers: new HttpHeaders({
            // tslint:disable-next-line:object-literal-key-quotes
            Accept: 'application/json',
            'Content-Type': 'application/json',
            // tslint:disable-next-line:object-literal-key-quotes
            Authorization: 'qWh9f6~(s6:u=LuNWxn*',
        }),
    };

    public getJoinPeriodOptions(programId: number): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.PRODUCT.PERIOD.GET_OPTIONS.replace('{supplier_slug}', this.baseService.slugUri).replace(
                '{program_id}',
                programId
            );

        return this.get(url, true);
    }

    public getJoinPeriodDetail(programId: number, periodId: number): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.PRODUCT.PERIOD.GET_DETAIL.replace('{supplier_slug}', this.baseService.slugUri)
                .replace('{program_id}', programId)
                .replace('{period_id}', periodId);

        return this.get(url, true);
    }

    public getJoinProgramPicture(programId: number): Observable<any> {
        return this.get(
            environment.apiProUrl +
                ENDPOINTS.PRODUCT.JOINS.GET_PICTURE_BASE64.replace('{supplier_slug}', this.baseService.slugUri).replace(
                    '{program_id}',
                    programId
                ),
            true
        );
    }

    public getJoinProgramPdf(programId: number): Observable<any> {
        return this.get(
            environment.apiProUrl +
                ENDPOINTS.PRODUCT.JOINS.GET_PDF_BASE64.replace('{supplier_slug}', this.baseService.slugUri).replace(
                    '{program_id}',
                    programId
                ),
            true
        );
    }
}
