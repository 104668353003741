export const PAGE_NAME = {
    ERROR_PAGE_401: {
        menu_name: 'error page',
        page_name: '401',
        page_slug: null,
    },
    ERROR_PAGE_404: {
        menu_name: 'error page',
        page_name: '404',
        page_slug: null,
    },
    ERROR_PAGE_500: {
        menu_name: 'error page',
        page_name: '500',
        page_slug: null,
    },
};
