export interface SupplierState {
    info: any;
    isLoading?: boolean;
    error?: any;
}

export const initialState: SupplierState = {
    info: null,
    isLoading: false,
    error: null,
};
