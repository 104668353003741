import { Action } from '@ngrx/store';
import { Sales } from 'app/shared/models/sales';

export enum ActionTypes {
    LOAD_PRODUCT_SUMMARIES = '[Sale] Load Product Summaries',
    LOAD_PRODUCT_SUMMARIES_SUCCESS = '[Sale] Load Product Summaries Success',
    SEARCH_CRITERIA_CHANGE = '[Sale] Search Criteria Change',
    LOAD_JOIN_TOUR_LIST = '[Sale] Load Join Tour List',
    LOAD_JOIN_TOUR_LIST_SUCCESS = '[Sale] Load Join Tour List Success',
    FAILURE = '[Sale] Failure',
}

export class ActionLoadJoinTourList implements Action {
    readonly type = ActionTypes.LOAD_JOIN_TOUR_LIST;
    constructor(public criteria, public clearList = false) {}
}

export class ActionLoadJoinTourListSuccess implements Action {
    readonly type = ActionTypes.LOAD_JOIN_TOUR_LIST_SUCCESS;
    constructor(public payload: { list: any[]; total: number }, public clearList = false) {}
}

export class ActionLoadProductSummaries implements Action {
    readonly type = ActionTypes.LOAD_PRODUCT_SUMMARIES;
    constructor(public supplier) {}
}

export class ActionSearchCriteriaChange implements Action {
    readonly type = ActionTypes.SEARCH_CRITERIA_CHANGE;
    constructor(public criteria: any) {}
}
export class ActionLoadProductSummariesSuccess implements Action {
    readonly type = ActionTypes.LOAD_PRODUCT_SUMMARIES_SUCCESS;
    constructor(public payload: { list: any[] }) {}
}

export class ActionFailure implements Action {
    readonly type = ActionTypes.FAILURE;
    constructor(public error: any) {}
}

export type Actions =
    | ActionLoadProductSummaries
    | ActionLoadProductSummariesSuccess
    | ActionSearchCriteriaChange
    | ActionLoadJoinTourList
    | ActionLoadJoinTourListSuccess
    | ActionFailure;
