import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

import { ENDPOINTS } from 'app/constants/endpoint';
import { TourwowBaseService } from './tourwow-base.service';
import { HttpService } from './base/http.service';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class OrderService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router,
        private baseService: TourwowBaseService
    ) {
        super(platformId, http, router, baseService);
    }

    public create(payload: any): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.ORDER.POST.replace('{supplier_slug}', this.baseService.slugUri);
        return this.post(url, payload, true);
    }

    public cancel(id: number): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.ORDER.CANCEL.replace('{supplier_slug}', this.baseService.slugUri).replace('{order_id}', id);
        return this.put(url, null, true);
    }

    public getOrder(id: any): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.ORDER.GET.replace('{supplier_slug}', this.baseService.slugUri).replace('{order_id}', id);

        return this.get(url, true);
    }

    public getList(criteria: any): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.ORDER.GET_LISTING.replace('{supplier_slug}', this.baseService.slugUri) +
            '?' +
            this.getQueryString(criteria);

        return this.get(url, true);
    }

    public getFile(orderId): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.ORDER.FILE.replace('{supplier_slug}', this.baseService.slugUri).replace('{order_id}', orderId);
        return this.get(url, true);
    }

    public getFileActive(orderId): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.ORDER.FILE_ACTIVE.replace('{supplier_slug}', this.baseService.slugUri).replace(
                '{order_id}',
                orderId
            );
        return this.get(url, true);
    }
}
