<div class="app-country-selector">
    <div class="app-country-selector-wrapper">
        <span
            class="app-country-selector-arrow-left"
            (click)="arrowClick('left')"
            [ngClass]="{ hide: !isShowArrow, disabled: isLeftArrowDisable }"
        >
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </span>

        <ul #countriesBox>
            <li *ngFor="let country of countries">
                <button
                    class="btn"
                    [ngClass]="{ active: country.id === selectedCountry }"
                    (click)="countryFilter($event, country)"
                >
                    <span class="text-truncate">{{ country.name }}</span>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                </button>
            </li>
        </ul>

        <span
            class="app-country-selector-arrow-right"
            (click)="arrowClick('right')"
            [ngClass]="{ hide: !isShowArrow, disabled: isRightArrowDisable }"
        >
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </span>
    </div>
</div>

<div class="app-product-selector" #programsBox [hidden]="!isShowModal">
    <div class="outer">
        <div class="inner">
            <span *ngIf="isRequestingPrograms">Loading...</span>
            <ul>
                <li *ngFor="let program of programs">
                    <span (click)="programFilter(program)">{{ program.name }}</span>
                </li>
            </ul>
        </div>
    </div>
</div>
