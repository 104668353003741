<div class="modal-body">
    <div>
        <button type="button" class="close pull-right close-position" aria-label="Close" (click)="closeModal()">
            <span style="font-size: 30px" aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="container-fluid payment-container">
        <div class="row mb-2">
            <div class="col-12 payment-title">แจ้งชำระเงิน</div>
            <div class="col-md-4 text-center">
                <ng-container *ngIf="!image">
                    <div class="upload-image mb-3" *ngIf="!uploadingImage">
                        <label for="file" *ngIf="!confirmCheckbox">แนบไฟล์รูปสลิป</label>
                    </div>
                    <img
                        class="loading"
                        *ngIf="uploadingImage"
                        src="./assets/images/file-upload-loading.gif"
                        alt="Uploading..."
                    />
                    <input
                        type="file"
                        accept="image/x-png,image/jpeg"
                        id="file"
                        class="d-none"
                        (change)="onUploadImage($event)"
                    />
                    <p class="label-image-orver-size" *ngIf="uploadImageOversize">กรุณาอัปโหลดรูปขนาดไม่เกิน 10 Mb</p>
                    <div class="upload-remark">การนำเอกสารที่เกี่ยวข้องกับการเงินไม่ตรงตามความเป็นจริงมีโทษทางอาญา</div>
                </ng-container>
                <ng-container *ngIf="image">
                    <div class="remove-file" [ngClass]="{ 'remove-file-disable': confirmCheckbox }">
                        <i class="fa fa-trash-o" (click)="removeFile()"></i>
                    </div>
                    <img [src]="image" /> <br />
                </ng-container>
            </div>
            <div class="col-md-8">
                <form [formGroup]="form">
                    <div class="amount-box">
                        <div class="form-group">
                            <label class="label-bold">ธนาคาร <i>*</i></label>
                            <select
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && form.get('bank_accounts_id').invalid }"
                                formControlName="bank_accounts_id"
                            >
                                <option [ngValue]="null" selected>กรุณาเลือกบัญชีธนาคาร</option>
                                <option [value]="bank.id" *ngFor="let bank of banks">
                                    {{ bank.bankName }} (บัญชี {{ bank.accountNumber }})
                                </option>
                            </select>
                        </div>
                        <div class="form-group mb-1">
                            <label class="label-bold">ยอดที่โอน <i>*</i></label>
                            <input
                                type="text"
                                appMycurrency
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && form.get('total').invalid }"
                                formControlName="total"
                                placeholder="กรุณากรอกยอดที่โอน"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-6 pr-0">
                            <label>งวด</label>
                            <select class="form-control" formControlName="payment_installments_ordinal">
                                <option *ngFor="let instalment of instalments" [value]="instalment.ordinal">
                                    งวดที่&nbsp;{{ instalment.ordinal }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-6 pl-1">
                            <label>วันที่ชำระ</label>
                            <app-datepicker
                                formControlName="paid_at"
                                placeholder="กรุณาเลือกวันที่ชำระ"
                                className="form-control"
                            ></app-datepicker>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>ข้อมูลเพิ่มเติม</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="note"
                            placeholder="กรอกข้อมูลเพิ่มเติม"
                        />
                    </div>

                    <div class="d-flex justify-content-center invalid-label" *ngIf="submitted && form.invalid">
                        กรุณากรอกข้อมูลให้ครบถ้วน
                    </div>
                    <div class="d-flex justify-content-center">
                        <button class="btn btn-save mr-3" (click)="confirm()">ยืนยันการชำระเงิน</button>
                        <button class="btn btn-cancel" (click)="closeModal()">ยกเลิก</button>
                    </div>
                </form>
            </div>
        </div>
        <app-payment-history [orderId]="orderId" [payment]="payment"></app-payment-history>

        <div class="container invoice-container">
            <p>
                รหัส Invoice : {{ invoiceCode }} <br />
                {{ programName }} <br />
                ผู้เดินทาง {{ passengerCount }} คน - ลูกค้า : {{ customerContact }}
            </p>
        </div>
    </div>
</div>
