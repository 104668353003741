import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { environment } from 'environments/environment';
import { TourwowBaseService } from 'app/services/tourwow-base.service';
import { Store, select } from '@ngrx/store';
import { State as RootState } from 'app/root-store/state';
import { selectSupplierInfo } from 'app/root-store/supplier-store/selectors';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { emailValidator, emptyValueValidator } from 'app/shared/validators/form.validator';
import { ActionOnLogin } from 'app/root-store/auth/actions';
import { selectLoginError, selectIsAuth } from 'app/root-store/auth/selectors';
import { filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

export enum State {
    LOGIN = 0,
    RESET_PASSWORD = 1,
    RESET_PASSWORD_SUCCESS = 2,
    REQUIRE_LOGIN = 3,
}

@Component({
    selector: 'app-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
    form: UntypedFormGroup;
    submitted = false;
    resetPasswordError: string;
    loginState = State;
    currentState = 0;
    cannotLogin = false;

    supplierInfo$: Observable<any>;

    constructor(
        public bsModalRef: BsModalRef,
        private route: Router,
        private fb: UntypedFormBuilder,
        private baseService: TourwowBaseService,
        private ngxUiLoaderService: NgxUiLoaderService,
        public store: Store<RootState>
    ) {}

    ngOnInit() {
        this.supplierInfo$ = this.store.pipe(
            select(selectSupplierInfo),
            filter((data) => data),
            take(1)
        );

        this.form = this.fb.group({
            email: [null, [emptyValueValidator()]],
            password: [null, emptyValueValidator()],
        });

        this.store.pipe(select(selectIsAuth)).subscribe((isAuth) => {
            if (isAuth) {
                this.cannotLogin = false;
            }
        });

        this.store.pipe(select(selectLoginError)).subscribe((error) => {
            if (error) {
                this.cannotLogin = true;
            }
        });
    }

    public login(): void {
        this.submitted = true;
        if (this.form.valid) {
            this.store.dispatch(
                new ActionOnLogin({
                    username: this.form.get('email').value,
                    password: this.form.get('password').value,
                    actionAfterLogin: () => {
                        this.bsModalRef.hide();
                    },
                })
            );
        }
    }

    signup() {
        this.bsModalRef.hide();
        this.route.navigateByUrl('/signup/register');
    }
    resetPassword() {
        this.submitted = false;
        this.form.get('email').setValidators([emptyValueValidator(), emailValidator()]);
        this.currentState = this.loginState.RESET_PASSWORD;
    }
    onResetPassword() {
        this.submitted = true;
        this.resetPasswordError = null;
        if (this.form.get('email').valid) {
            const url = environment.resetPasswordUrl.replace('{supplier_slug}', this.baseService.slugUri);
            this.ngxUiLoaderService.start();
        } else {
            this.resetPasswordError = 'กรุณากรอก Email ให้ถูกต้อง';
        }
    }
}
