import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './reducer';
import { PromotionStoreEffects } from './effects';

export const FEATURE_NAME = 'promotion';
@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(FEATURE_NAME, reducer),
        EffectsModule.forFeature([PromotionStoreEffects]),
    ],
    providers: [PromotionStoreEffects],
})
export class PromotionStoreModule {}
