import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
    Actions as AuthActions,
    ActionTypes as AuthActionTypes,
    ActionOnLogin,
    ActionOnLoginSuccess,
    ActionOnLoginFailure,
    ActionOnLogout,
    ActionOnLogoutSuccess,
    ActionCheckAuthentication,
    ActionCheckAuthenticationSuccess,
    ActionCheckAuthenticationFailure,
} from './actions';
import { AuthService } from 'app/services/auth.service';

import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticatedAgencyMember, AuthenticatedUser } from 'app/shared/models/auth.model';
import { TourwowBaseService } from 'app/services/tourwow-base.service';

@Injectable()
export class AuthStoreEffects {
    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private route: Router,
        private baseService: TourwowBaseService,
        private ngxUiLoaderService: NgxUiLoaderService
    ) {}

    
    onLogin = createEffect(() => () =>
        this.actions$.pipe(
            ofType<AuthActions>(AuthActionTypes.ON_LOGIN),
            switchMap((action: ActionOnLogin) => {
                this.ngxUiLoaderService.start();
                return this.authService.login(action.payload.username, action.payload.password).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status === 'error') {
                            throw resp;
                        }

                        let token: string = resp.data.token;
                        this.authService.saveJWTToken(token, this.baseService.slugUri);
                        const jwtPayload = JSON.parse(atob(token.split('.')[1]));
                        return new ActionOnLoginSuccess({
                            currentUser: new AuthenticatedUser().deserialize(jwtPayload.user),
                            currentAgencyMember: new AuthenticatedAgencyMember().deserialize(jwtPayload.agency_member),
                            actionAfterLogin: action.payload.actionAfterLogin,
                        });
                    }),
                    catchError((error) => {
                        this.ngxUiLoaderService.stop();
                        return of(new ActionOnLoginFailure({ error }));
                    })
                );
            })
        ));

    
    onLoginSuccess = createEffect(() => () =>
        this.actions$.pipe(
            ofType<AuthActions>(AuthActionTypes.ON_LOGIN_SUCCESS),
            tap((action: ActionOnLogin) => {
                action.payload.actionAfterLogin();
            })
        ), { dispatch: false });

    
    onLogout = createEffect(() => () =>
        this.actions$.pipe(
            ofType<AuthActions>(AuthActionTypes.ON_LOGOUT),
            map(() => {
                this.authService.deleteJWTToken(this.baseService.slugUri);
                return new ActionOnLogoutSuccess();
            })
        ));

    
    onLogoutSuccess = createEffect(() => () =>
        this.actions$.pipe(
            ofType<AuthActions>(AuthActionTypes.ON_LOGOUT_SUCCESS),
            tap(() => {
                this.route.navigateByUrl('sale');
            })
        ), { dispatch: false });

    
    onLCheckAuthentication = createEffect(() => () =>
        this.actions$.pipe(
            ofType<AuthActions>(AuthActionTypes.CHECK_AUTHENTICATION),
            switchMap((action: ActionCheckAuthentication) => {
                return this.authService.getJWTToken(this.baseService.slugUri).pipe(
                    map((token) => {
                        if (token) {
                            const jwtPayload = JSON.parse(atob(token.split('.')[1]));
                            return new ActionCheckAuthenticationSuccess({
                                currentUser: new AuthenticatedUser().deserialize(jwtPayload.user),
                                currentAgencyMember: new AuthenticatedAgencyMember().deserialize(
                                    jwtPayload.agency_member
                                ),
                            });
                        } else {
                            return new ActionCheckAuthenticationFailure(null);
                        }
                    }),
                    catchError((error) => {
                        return of(new ActionCheckAuthenticationFailure(error));
                    })
                );
            })
        ));
}
