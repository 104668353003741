<div id="nav-menu" [ngClass]="{ 'expand-menu': isExpandedNav }">
    <a (click)="onToggleExpandNav()" [ngClass]="{ 'expand-burger': isExpandedNav }" class="burger-btn">
        <i class="fa" [ngClass]="{ 'fa-angle-left': isExpandedNav, 'fa-bars': !isExpandedNav }" aria-hidden="true"></i>
    </a>

    <nav class="twp-text-l">
        <ul>
            <li>
                <a class="color-white" [routerLink]="['/']">
                    <i class="icon-member-white"></i>
                    <span class="menu-text" [ngClass]="{ hide: !isExpandedNav }">Profile</span>
                </a>
            </li>
            <li>
                <a class="color-white" [routerLink]="['/sale']">
                    <i class="icon-online-booking-white"></i>
                    <span class="menu-text" [ngClass]="{ hide: !isExpandedNav }">Online Booking</span>
                </a>
            </li>
            <li>
                <a class="color-white" [routerLink]="['/order/history']">
                    <i class="icon-order-history-white"></i>
                    <span class="menu-text" [ngClass]="{ hide: !isExpandedNav }">Order History</span>
                </a>
            </li>
            <li>
                <a class="color-white" [routerLink]="['/']">
                    <i class="icon-payment-white"></i>
                    <span class="menu-text" [ngClass]="{ hide: !isExpandedNav }">Payment</span>
                </a>
            </li>
            <li>
                <a class="color-white" [routerLink]="['/']">
                    <i class="icon-passenger-white"></i>
                    <span class="menu-text" [ngClass]="{ hide: !isExpandedNav }">Passenger</span>
                </a>
            </li>
            <li>
                <a class="color-white" [routerLink]="['/']">
                    <i class="icon-logout-white"></i>
                    <span class="menu-text" [ngClass]="{ hide: !isExpandedNav }">Logout</span>
                </a>
            </li>
        </ul>
    </nav>
</div>
