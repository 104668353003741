import { initialState, MemberState } from './state';
import { Actions, ActionTypes } from './actions';

export function reducer(state: MemberState = initialState, action: Actions): MemberState {
    switch (action.type) {
        case ActionTypes.LOAD_MEMBER_LISTING:
            return {
                ...state,
                isLoading: true,
                listing: null,
            };
        case ActionTypes.LOAD_MEMBER_LISTING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                listing: action.listing,
            };

        case ActionTypes.LOAD_MEMBER_INFO:
            return {
                ...state,
                isLoading: true,
                info: null,
            };
        case ActionTypes.LOAD_MEMBER_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                info: action.info,
            };

        default:
            return state;
    }
}
