import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Store, select } from '@ngrx/store';
import { State } from 'app/root-store/state';
import { ActionCancelOrder } from 'app/root-store/order-store/actions';
import { selectIsCancelOrderSuccess } from 'app/root-store/order-store/selectors';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface ICancelOrderModel {
    orderId: number;
    createdAt: string;
    itemDetail: string;
    totalPrice: string;
    numberOfPassenger: number;
    status: string;
}

export class ICancelOrderModel {
    public orderId: number;
    public createdAt: string;
    public itemDetail: string;
    public totalPrice: string;
    public numberOfPassenger: number;
    public status: string;
}

@Component({
    selector: 'app-cancel-order-modal',
    templateUrl: './cancel-order-modal.component.html',
    styleUrls: ['./cancel-order-modal.component.scss'],
})
export class CancelOrderModalComponent implements OnInit {
    public model: ICancelOrderModel;
    public modalRef: BsModalRef;
    public onClose: Subject<boolean>;

    private unsubscribe$: Subject<void> = new Subject<void>();
    constructor(public bsModalRef: BsModalRef, public store: Store<State>) {}

    ngOnInit() {
        this.onClose = new Subject();
    }

    public onCancelOrder() {
        this.store.dispatch(new ActionCancelOrder(this.model.orderId));
        this.store
            .pipe(
                select(selectIsCancelOrderSuccess),
                map((data) => {
                    if (data) {
                        this.onClose.next(true);
                        this.bsModalRef.hide();
                    }
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }
}
