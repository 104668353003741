import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './reducer';
import { JoinProgramStoreEffects } from './effects';

export const FEATURE_NAME = 'joinProgram';
@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(FEATURE_NAME, reducer),
        EffectsModule.forFeature([JoinProgramStoreEffects]),
    ],
    providers: [JoinProgramStoreEffects],
})
export class JoinProgramStoreModule {}
