import { Component, OnDestroy, Input, TemplateRef, HostListener, ViewChild, ElementRef } from '@angular/core';
import { NextPeriodMonthlyListPipe } from 'app/shared/pipes/period.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ProductService } from 'app/services/product.service';
import { Base64Util } from 'app/shared/utils/base64.util';
import { PreviewPdfModalComponent } from 'app/shared/components/preview-pdf-modal/preview-pdf-modal.component';

@Component({
    selector: 'app-program-box',
    templateUrl: './program-box.component.html',
    styleUrls: ['./program-box.component.scss'],
    providers: [NextPeriodMonthlyListPipe],
})
export class ProgramBoxComponent implements OnDestroy {
    @Input() program: any;
    @Input() programIndex: any;
    nextPeriodMonth = [];
    showMorePeriod = [];
    modalRef: BsModalRef;

    @ViewChild('programbanner') programBanner: ElementRef;
    @ViewChild('previewPdfModal') previewPdfModal: PreviewPdfModalComponent;

    constructor(
        private nextPeriodMonthlyListPipe: NextPeriodMonthlyListPipe,
        private modalService: BsModalService,
        private base64Util: Base64Util,
        private productService: ProductService
    ) {}

    ngOnDestroy(): void {
        this.previewPdfModal.closeModal();
    }

    getCountriesText(countries: any[]): string {
        return countries.map((c) => c.name_th).join(' - ');
    }

    getPeriodByMonthList(periods: any[], nextMonth: number): void {
        return this.nextPeriodMonthlyListPipe.transform(periods, nextMonth);
    }

    openModal(template: TemplateRef<any>, additionClass: string = ''): void {
        this.modalRef = this.modalService.show(template, Object.assign({}, { class: additionClass }));
    }

    downloadPdf(programId: number, fileName: string): void {
        let file = fileName;
        this.productService.getJoinProgramPdf(programId).subscribe((response) => {
            if (response.status === 'success') {
                const buffer = this.base64Util.toArrayBuffer(
                    response.data.substr('data:application/pdf;base64,'.length)
                );
                const blob = new Blob([buffer], { type: 'application/pdf' });
                const blobUrl = URL.createObjectURL(blob);

                const downloadLink = document.createElement('a');
                const fileName = file;

                downloadLink.href = blobUrl;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        });
    }

    downloadPicture(programId: number, fileName: string): void {
        let file = fileName;
        this.productService.getJoinProgramPicture(programId).subscribe((response) => {
            if (response.status === 'success') {
                // console.log(response.data);
                const downloadLink = document.createElement('a');
                const fileName = file;

                downloadLink.href = response.data;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        });
    }

    previewPDF(): void {
        if (this.program.program_pdf) {
            this.previewPdfModal.open(this.program.program_pdf);
        }
    }

    timeout = null;
    @HostListener('window:scroll')
    onScroll() {
        // this.timeout = setTimeout(() => {
        //   clearTimeout(this.timeout);
        //   const headerHeight = $('.app-header').height();
        //   const scrollOffsetTop = $(window).scrollTop() + headerHeight;
        //   const bannerOffsetTop = $(this.programBanner.nativeElement).offset().top;
        //   const bannerHeight = $(this.programBanner.nativeElement).height();
        //   const isFooterDisPlayOnScreen = scrollOffsetTop > ($('.footer-top').offset().top - $(window).height()) ? true : false;
        //   // console.log('(scrollOffsetTop, bannerOffsetTop):' + scrollOffsetTop + ':' + bannerOffsetTop);
        //   // console.log('foogter display onscereen: ' + isFooterDisPlayOnScreen);
        //   if (scrollOffsetTop >= bannerOffsetTop) {
        //     const box = $(this.programBanner.nativeElement).closest('.home-program-box');
        //     const offsetBottom = box.offset().top + box.height();
        //     if (scrollOffsetTop > offsetBottom) {
        //       $(this.programBanner.nativeElement).css('height', null);
        //       $(this.programBanner.nativeElement).removeClass('sticky');
        //     }
        //     else {
        //       // $(this.programBanner.nativeElement).height(bannerHeight);
        //       if (!isFooterDisPlayOnScreen) {
        //         $(this.programBanner.nativeElement).addClass('sticky');
        //       }
        //     }
        //   } else {
        //     $(this.programBanner.nativeElement).css('height', null);
        //     $(this.programBanner.nativeElement).removeClass('sticky');
        //   }
        // }, 50);
    }
}
