import { initialState, PromotionState } from './state';
import { Actions, ActionTypes } from './actions';

export function reducer(state: PromotionState = initialState, action: Actions): PromotionState {
    switch (action.type) {
        case ActionTypes.LOAD_COUNTRY_DEALS:
            return {
                ...state,
                isLoading: true,
                countries: state.countries || [],
                error: null,
            };
        case ActionTypes.LOAD_COUNTRY_DEALS_SUCCESS:
            if (!state.countries || action.clearList) {
                state.countries = [];
            }
            state.countries = state.countries.concat(action.payload.items);
            return {
                ...state,
                isLoading: false,
                countries: state.countries,
                error: null,
            };
        case ActionTypes.LOAD_PROGRAM_DEALS:
            return {
                ...state,
                isLoading: true,
                programs: state.programs || [],
                error: null,
            };
        case ActionTypes.LOAD_PROGRAM_DEALS_SUCCESS:
            if (!state.programs || action.clearList) {
                state.programs = [];
            }
            state.programs = state.programs.concat(action.payload.items);
            return {
                ...state,
                isLoading: false,
                programs: state.programs,
                error: null,
            };
        case ActionTypes.LOAD_PROMOTIONS:
            return {
                ...state,
                isLoading: true,
                items: state.items || [],
                totalItems: 0,
                error: null,
            };
        case ActionTypes.LOAD_PROMOTIONS_SUCCESS:
            if (!state.items || action.clearList) {
                state.items = [];
            }
            state.items = state.items.concat(action.payload.items);
            return {
                ...state,
                isLoading: false,
                items: state.items,
                totalItems: action.payload.total,
                error: null,
            };
        case ActionTypes.FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
}
