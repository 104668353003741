import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

import { IUser } from 'app/shared/models/user';
import { ENDPOINTS } from 'app/constants/endpoint';
import { environment } from '../../environments/environment';
import { TourwowBaseService } from './tourwow-base.service';
import { HttpService } from './base/http.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class PaymentService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router,
        private baseService: TourwowBaseService
    ) {
        super(platformId, http, router, baseService);
    }

    public createPaymentTransaction(orderId, body): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.ORDER.PAYMENT.POST.replace('{supplier_slug}', this.baseService.slugUri).replace(
                '{order_id}',
                orderId
            );
        return this.post(url, body, true);
    }

    public deletePaymentTransaction(orderId, paymentId): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.ORDER.PAYMENT.DELETE.replace('{supplier_slug}', this.baseService.slugUri)
                .replace('{order_id}', orderId)
                .replace('{payment_id}', paymentId);

        return this.delete(url, true);
    }
}
