import { IUser } from 'app/shared/models/user';

export interface UserState {
    users: IUser[];
    isLoading?: boolean;
    error?: any;
}

export const initialState: UserState = {
    users: null,
    isLoading: false,
    error: null,
};
