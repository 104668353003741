<div class="modal-header pb-0">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <i class="fa fa-times" aria-hidden="true"></i>
    </button>
</div>
<div class="modal-body text-center">
    <h4 class="twp-text-xl-bold color-text-title">
        <i *ngIf="icon === ICONS.ALERT" class="icon-alert-modal mb-3"></i>
        <i *ngIf="icon === ICONS.INFO" class="icon-info-modal mb-3"></i>
        <i *ngIf="icon === ICONS.SUCCESS" class="icon-success-modal mb-3"></i>
        <i *ngIf="icon === ICONS.USER" class="icon-member-modal mb-3"></i>
        <div [innerHTML]="title"></div>
    </h4>
    <p class="description" [innerHTML]="description"></p>
    <button
        (click)="bsModalRef.hide()"
        type="button"
        class="btn btn-primary twp-text-l"
        style="width: {{ buttonWidth }}px; height: 35px"
    >
        ปิด
    </button>
</div>
