import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SaleStoreModule } from './sale-store/sale-store.module';
import { PromotionStoreModule } from './promotion-store/promotion-store.module';
import { OrderStoreModule } from './order-store/order-store.module';
import { AgencyStoreModule } from './agency-store/agency-store.module';
import { UserStoreModule } from './user-store/user-store.module';
import { JoinProgramStoreModule } from './join-program-store/join-program-store.module';
import { SupplierStoreModule } from './supplier-store/supplier-store.module';
import { environment } from 'environments/environment';
import { SignupStoreModule } from './signup/signup-store.module';
import { AuthStoreModule } from './auth/auth-store.module';
import { MemberStoreModule } from './member-store/member-store.module';
import { reducers } from './reducers';
import { storageMetaReducer } from './storage.metareducer';

@NgModule({
    imports: [
        CommonModule,

        OrderStoreModule,
        SaleStoreModule,
        PromotionStoreModule,
        AgencyStoreModule,
        UserStoreModule,
        JoinProgramStoreModule,
        SupplierStoreModule,
        SignupStoreModule,
        AuthStoreModule,
        MemberStoreModule,

        StoreModule.forRoot(reducers, {
            metaReducers: [storageMetaReducer],
            runtimeChecks: {
                strictStateImmutability: false,
            },
        }),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
    ],
    declarations: [],
})
export class RootStoreModule {}
