import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LoginModalComponent } from 'app/components/modal/login-modal/login-modal.component';
import { Store, select } from '@ngrx/store';
import { State as RootState } from 'app/root-store/state';
import { selectIsAuth } from 'app/root-store/auth/selectors';
import { AlertModalComponent, AlertModalIcons } from 'app/components/modal/alert-modal/alert-modal.component';
import { take, filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { selectSupplierInfo } from 'app/root-store/supplier-store/selectors';

@Component({
    selector: 'app-period-box',
    templateUrl: './period-box.component.html',
    styleUrls: ['./period-box.component.scss'],
})
export class PeriodBoxComponent implements OnInit, OnDestroy {
    @Input() period: any;
    @Input() periodIndex: any;

    authenticated = false;
    showSkus = [];

    private supplierInfo: any;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(private route: Router, private modalService: BsModalService, public store: Store<RootState>) {}

    ngOnInit() {
        this.store
            .select(selectIsAuth)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((authenticated) => {
                this.authenticated = authenticated;
            });

        this.store
            .pipe(
                select(selectSupplierInfo),
                filter((data) => data),
                take(1),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((data) => (this.supplierInfo = data));

        this.store.pipe(select(selectIsAuth)).subscribe((isAuth) => {
            this.authenticated = isAuth;
        });
    }

    reserve(programId, periodId) {
        if (!this.authenticated) {
            this.modalService.show(LoginModalComponent, {
                class: 'modal-sm',
                initialState: {
                    currentState: 3,
                },
            });
        } else if (!this.period.is_allow_create_order) {
            const initialState = {
                title: 'รอ Verify',
                description:
                    '<i class="fa fa-exclamation-triangle fa-warning" ></i> Account ของคุณยังไม่สามารถเห็นค่าคอมหรือทำรายการจองได้ เนื่องจากรอ Verify จาก ' +
                    this.supplierInfo.name_en,
                icon: AlertModalIcons.USER,
            };

            this.modalService.show(AlertModalComponent, Object.assign({ initialState }, { class: 'modal-sm' }));

            this.modalService.onHide
                .pipe(take(1), takeUntil(this.unsubscribe$))
                .subscribe((data) => window.location.reload());
        } else {
            this.route.navigate(['/sale/', programId, periodId]);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
