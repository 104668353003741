import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TourwowBaseService {
    public get slugUri(): string {
        let slugUri = document.location.pathname.split('/').filter((x) => x !== '')[0];

        if (!slugUri) {
            document.location.href = environment.redirectUrl;
        }

        return slugUri;
    }
}
