import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable, of, pipe } from 'rxjs';
import { TourwowBaseService } from './tourwow-base.service';
import { switchMap, tap } from 'rxjs/operators';
import { HttpService } from './base/http.service';
import { HttpClient } from '@angular/common/http';
import { ENDPOINTS } from 'app/constants/endpoint';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthService extends HttpService {
    protected localStorageTokenKey = 'jwt_token_{supplier_slug}';

    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router,
        private baseService: TourwowBaseService
    ) {
        super(platformId, http, router, baseService);
    }

    saveJWTToken(token: string, supplierSlug: string): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(this.localStorageTokenKey.replace('{supplier_slug}', this.baseService.slugUri), token);
        }
    }

    getJWTToken(supplierSlug: string): Observable<string> {
        return new Observable((observer) => {
            if (isPlatformBrowser(this.platformId)) {
                const token = localStorage.getItem(
                    this.localStorageTokenKey.replace('{supplier_slug}', this.baseService.slugUri)
                );
                observer.next(token ? token : null);
            } else {
                // if platform browser is not met
                observer.next(null);
            }
            observer.complete();
        });
    }

    deleteJWTToken(supplierSlug: string): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem(this.localStorageTokenKey.replace('{supplier_slug}', this.baseService.slugUri));
        }
    }

    login(username, password): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.AUTH.LOGIN.replace('{supplier_slug}', this.baseService.slugUri);

        return this.getRequestOptions(false).pipe(
            switchMap((headers) => this.http.post(url, { username, password }, headers))
        );
    }
}
