import { initialState, JoinProgramState } from './state';
import { Actions, ActionTypes } from './actions';

export function reducer(state: JoinProgramState = initialState, action: Actions): JoinProgramState {
    switch (action.type) {
        case ActionTypes.LOAD_JOIN_PROGRAM_WITH_FIRST_PERIOD:
            return {
                ...state,
                isLoading: true,
                joinProgram: null,
                periodOptions: [],
                error: null,
            };
        case ActionTypes.LOAD_JOIN_PROGRAM_WITH_FIRST_PERIOD_SUCCESS:
            return {
                ...state,
                isLoading: true,
                joinProgram: action.payload.joinProgram,
                periodOptions: action.payload.periods,
                error: null,
            };
        case ActionTypes.LOAD_JOIN_PROGRAM:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case ActionTypes.LOAD_JOIN_PROGRAM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                joinProgram: action.payload.joinProgram,
                error: null,
            };
        case ActionTypes.LOAD_PERIOD_OPTIONS:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case ActionTypes.LOAD_PERIOD_OPTIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                periodOptions: action.payload.periods,
                error: null,
            };
        case ActionTypes.FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
}
