import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpService } from './base/http.service';
import { environment } from 'environments/environment';
import { ENDPOINTS } from 'app/constants/endpoint';
import { TourwowBaseService } from './tourwow-base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { switchMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AgencyService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router,
        private baseService: TourwowBaseService
    ) {
        super(platformId, http, router, baseService);
    }

    public getInfo(): Observable<any> {
        const url =
            environment.apiProUrl + ENDPOINTS.AGENCY.GET_INFO.replace('{supplier_slug}', this.baseService.slugUri);
        return this.get(url, true);
    }

    public checkContactEmail(): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.AGENCY.VERIFY_EMAIL_CONTACT.replace('{supplier_slug}', this.baseService.slugUri);
        return this.get(url, true);
    }

    public updateContactEmail(email: string): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.AGENCY.UPDATE_EMAIL_CONTACT.replace('{supplier_slug}', this.baseService.slugUri);
        return this.put(url, { email: email }, true);
    }

    public update(agencyId: number, body: AgencyUpdatePayload): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.AGENCY.PUT.replace('{supplier_slug}', this.baseService.slugUri).replace('{agency_id}', agencyId);
        return this.put(url, body, true);
    }
}

export interface AgencyUpdatePayload {
    company_name: string;
    company_license: string;
    email: string;
    address: string;
    tel: string;
    company_license_document: {
        base64_data: string;
        is_same: boolean;
    };
    tourism_license_document: {
        base64_data: string;
        is_same: boolean;
    };
}
