import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
    Actions as OrderActions,
    ActionTypes as OrderActionTypes,
    ActionFailure,
    ActionLoadOrder,
    ActionLoadOrderSuccess,
    ActionLoadOrderHistory,
    ActionLoadOrderHistorySuccess,
    ActionCreateOrder,
    ActionCreateOrderSuccess,
    ActionCreateOrderFailed,
    ActionCancelOrder,
    ActionCancelOrderSuccess,
} from './actions';
import { OrderService } from 'app/services/order.service';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class OrderStoreEffects {
    constructor(private actions$: Actions, private orderService: OrderService) {}

    
    createOrder = createEffect(() => () =>
        this.actions$.pipe(
            ofType<OrderActions>(OrderActionTypes.CREATE_ORDER),
            switchMap((action: ActionCreateOrder) => {
                return this.orderService.create(action.payload).pipe(
                    map((resp) => {
                        if (resp.status === 'error' && resp.error.code === '[422]') {
                            return new ActionCreateOrderFailed({ info: resp.error });
                        }

                        return new ActionCreateOrderSuccess({
                            orderId: resp.data.order_id,
                            orderMessage: resp.data.message,
                        });
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));

    
    cancelOrder = createEffect(() => () =>
        this.actions$.pipe(
            ofType<OrderActions>(OrderActionTypes.CANCEL_ORDER),
            switchMap((action: ActionCancelOrder) => {
                return this.orderService.cancel(action.id).pipe(
                    map((resp) => {
                        return new ActionCancelOrderSuccess(action.id);
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));

    
    loadOrder = createEffect(() => () =>
        this.actions$.pipe(
            ofType<OrderActions>(OrderActionTypes.LOAD_ORDER),
            switchMap((action: ActionLoadOrder) => {
                return this.orderService.getOrder(action.id).pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }
                        return new ActionLoadOrderSuccess({
                            order: resp.data,
                        });
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));

    
    loadOrderHistory = createEffect(() => () =>
        this.actions$.pipe(
            ofType<OrderActions>(OrderActionTypes.LOAD_ORDER_HISTORY),
            switchMap((action: ActionLoadOrderHistory) => {
                return this.orderService.getList(action.criteria).pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }
                        let orders = resp.data.orders;
                        let total = resp.data.total;

                        return new ActionLoadOrderHistorySuccess(
                            {
                                total: total,
                                orders: orders,
                            },
                            action.clearList
                        );
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));
}
