import { Component } from '@angular/core';

@Component({
    selector: 'app-search-template',
    templateUrl: './search-template.component.html',
    styleUrls: ['./search-template.component.scss'],
})
export class SearchTemplateComponent {
    constructor() {}
}
