<div class="modal-body">
    <div>
        <button type="button" class="close pull-right close-position" aria-label="Close" (click)="closeModal()">
            <span style="font-size: 30px" aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="container-fluid payment-container">
        <div class="col-12 payment-title">ประวัติการชำระเงิน</div>
        <app-payment-history [orderId]="orderId" [payment]="payment"></app-payment-history>
        <div class="container invoice-container">
            <p>
                รหัส Invoice : {{ invoiceCode }} <br />
                {{ programName }} <br />
                ผู้เดินทาง {{ passengerCount }} คน - ลูกค้า : {{ customerContact }}
            </p>
        </div>
    </div>
</div>
