import { Action } from '@ngrx/store';
import { IUser } from 'app/shared/models/user';

export enum ActionTypes {
    LOAD_USER = '[User] Load User',
    LOAD_USER_SUCCESS = '[User] Load User Success',
    FAILURE = '[User] Failure',
}

export class ActionLoadUser implements Action {
    readonly type = ActionTypes.LOAD_USER;
    constructor(public payload: { statusCode: number }) {}
}

export class ActionLoadUserSuccess implements Action {
    readonly type = ActionTypes.LOAD_USER_SUCCESS;
    constructor(public payload: { users: IUser[] }) {}
}

export class ActionFailure implements Action {
    readonly type = ActionTypes.FAILURE;
    constructor(public error: any) {}
}

export type Actions = ActionLoadUser | ActionLoadUserSuccess | ActionFailure;
