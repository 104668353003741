import { Component, OnInit, OnDestroy, Input, HostListener, OnChanges } from '@angular/core';
import { LoginModalComponent } from 'app/components/modal/login-modal/login-modal.component';
import { Store, select } from '@ngrx/store';
import { State } from 'app/root-store/state';

import { ActionLoadJoinTourList, ActionSearchCriteriaChange } from 'app/root-store/sale-store/actions';
import { CountryService } from 'app/services/country.service';
import { selectProductSummaries } from 'app/root-store/sale-store/selectors';
import { Router } from '@angular/router';

import { Member } from 'app/shared/models/member.model';
import { selectSupplierInfo } from 'app/root-store/supplier-store/selectors';
import { ActionOnLogout } from 'app/root-store/auth/actions';
import { SaleService } from 'app/services/sale.service';
import { BreadcrumbService } from 'app/services/breadcrumb.service';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { AlertModalComponent, AlertModalIcons } from 'app/components/modal/alert-modal/alert-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { selectCurrentAgencyMember } from 'app/root-store/auth/selectors';
import { environment } from 'environments/environment';

declare var $;
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {
    public version = environment.appVersion;
    supplierInfo$: Observable<any>;
    countries$: Observable<any>;
    programs$: Observable<any>;
    productSummaries$: Observable<any>;
    dropdownLabel = '';
    dropdownProgramLabel = '';
    showDropdownItems = false;
    showDropdownProgramItems = false;
    showUserMenu = false;
    formCriteria = {
        programId: null,
        country: null,
        countryText: null,
        periodStart: null,
        price: null,
    };
    page;

    productCate: string;
    productCateTotal: number = null;
    member: Member;
    breadcrumbText: string;

    collapsedMobileMenu = true;

    private supplierInfo: any;
    private reloadProgram = true;

    public showHeaderBannerWhenScroll = true;
    @Input() isHeaderShowBreadcrumb = false;
    @Input() isHeaderShowBanner = false;
    @Input() isHeaderShowSearchCountrires = false;
    @Input() isHeaderShowSearchResultTabs = false;
    @Input() isHeaderShowCountrySelector = false;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private route: Router,
        private modalService: BsModalService,
        private countryService: CountryService,
        private saleService: SaleService,
        private breadcrumbService: BreadcrumbService,
        public store: Store<State>
    ) {}

    ngOnInit(): void {
        this.supplierInfo$ = this.store.pipe(
            select(selectSupplierInfo),
            filter((data) => data),
            take(1)
        );

        this.store
            .pipe(
                select(selectSupplierInfo),
                filter((data) => data),
                take(1),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((data) => (this.supplierInfo = data));

        this.setMemberInfoWhenUserHasAuthenticated();

        this.breadcrumbService.text.subscribe((updatedTitle) => {
            this.breadcrumbText = updatedTitle;
        });

        if (this.route.url.indexOf('promotions') !== -1) {
            this.productCate = 'Promotion';
        } else {
            this.productCate = 'Join Tour';
        }
    }

    ngOnChanges(): void {
        if (this.isHeaderShowSearchCountrires) {
            this.initCountryFilter();
        }

        if (this.isHeaderShowSearchResultTabs) {
            this.initProductSummaryFilter();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    timeout = null;
    @HostListener('window:scroll')
    onScroll() {
        if (this.isHeaderShowBanner) {
            this.timeout = setTimeout(() => {
                const scroll = $(window).scrollTop();
                this.showHeaderBannerWhenScroll = scroll === 0;
                if (this.showHeaderBannerWhenScroll) {
                    $('.listing-container').removeClass('sm');
                } else {
                    $('.listing-container').addClass('sm');
                }
            }, 50);
        }
    }

    private initCountryFilter(): void {
        this.countries$ = this.countryService.getList().pipe(map((resp) => resp.data));
    }

    private initProductSummaryFilter(): void {
        this.productSummaries$ = this.store.pipe(
            select(selectProductSummaries),
            filter((data) => data != null),
            take(1)
        );
    }

    signup(): void {
        if (!this.member) {
            this.modalService.show(LoginModalComponent, {
                class: 'modal-sm',
                ignoreBackdropClick: false,
            });

            this.modalService.onHide.pipe(take(1), takeUntil(this.unsubscribe$)).subscribe(() => {
                this.setMemberInfoWhenUserHasAuthenticated();
                this.store
                    .select(selectCurrentAgencyMember)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((currentAgencyMember) => {
                        this.member = currentAgencyMember
                            ? new Member().fillFromAuthenticatedAgencyMember(currentAgencyMember)
                            : null;
                        if (this.member && this.member.channel_ob_status_code !== 'available') {
                            this.showModalWarningVerifyAccount();
                        }
                    });
            });
        }
    }

    private setMemberInfoWhenUserHasAuthenticated(): void {
        this.store
            .select(selectCurrentAgencyMember)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((currentAgencyMember) => {
                this.member = currentAgencyMember
                    ? new Member().fillFromAuthenticatedAgencyMember(currentAgencyMember)
                    : null;
            });
    }

    private showModalWarningVerifyAccount(): void {
        const initialState = {
            title: 'รอ Verify',
            description:
                '<i class="fa fa-exclamation-triangle fa-warning" ></i> Account ของคุณยังไม่สามารถเห็นค่าคอมหรือทำรายการจองได้ เนื่องจากรอ Verify จาก ' +
                this.supplierInfo.name_en,
            icon: AlertModalIcons.USER,
        };

        this.modalService.show(AlertModalComponent, Object.assign({ initialState }, { class: 'modal-sm' }));

        this.modalService.onHide
            .pipe(take(1), takeUntil(this.unsubscribe$))
            .subscribe((data) => window.location.reload());
    }

    toggleDropdown(): void {
        this.showDropdownItems = !this.showDropdownItems;
        this.showDropdownProgramItems = false;
    }

    toggleProgramDropdown(): void {
        this.showDropdownProgramItems = !this.showDropdownProgramItems;
        this.showDropdownItems = false;

        if (this.reloadProgram) {
            this.getProgram();
        }
    }

    selectDropdownItem(item: any): void {
        this.selectDropdownProgramItem(null);
        this.reloadProgram = true;
        //
        this.dropdownLabel = item ? item.name_th : 'ทุกประเทศ';
        this.formCriteria.country = item ? item.id : null;
        this.formCriteria.countryText = item ? item.name_th : null;
        this.showDropdownItems = false;
        this.store.dispatch(new ActionSearchCriteriaChange(this.formCriteria));
        this.search();
    }

    selectDropdownProgramItem(programItem: any): void {
        this.dropdownProgramLabel = programItem
            ? programItem.tour_code + ': ' + programItem.name
            : 'โปรแกรมทัวร์ทั้งหมด';
        this.formCriteria.programId = programItem ? programItem.id : null;
        this.showDropdownProgramItems = false;
        this.store.dispatch(new ActionSearchCriteriaChange(this.formCriteria));
        this.search();
    }

    private getProgram(): void {
        const params = this.initSearchCriteriaRequest();
        this.programs$ = this.saleService.getJoinActive(params).pipe(map((resp) => resp.data));
        this.reloadProgram = false;
    }

    private search(): void {
        this.page = 1;
        this.getJoinTourList(true);
    }

    private getJoinTourList(clearList = false): void {
        const params = this.initSearchCriteriaRequest();
        this.store.dispatch(new ActionLoadJoinTourList(params, clearList));

        let navigateCommand = ['sale'];
        if (this.route.url.indexOf('promotions') !== -1) {
            navigateCommand.push('promotions');
        }

        this.route.navigate(navigateCommand, {
            queryParams: {
                redirectFilter: true,
            },
        });
    }

    onProductCatePromoClick(promoTotal): void {
        this.productCate = 'Promotion';
        this.productCateTotal = promoTotal;
        this.route.navigate(['sale', 'promotions']);
    }

    onProductCateClick(productCate): void {
        this.productCate = productCate.category_product_name;
        this.productCateTotal = productCate.total;
        this.route.navigate(['sale']);
    }

    private initSearchCriteriaRequest(): any {
        let filter: any = {};
        if (this.formCriteria.country) {
            filter.country_id = this.formCriteria.country;
        }

        if (this.formCriteria.programId) {
            filter.program_id = this.formCriteria.programId;
        }

        return {
            page: this.page > 0 ? this.page : 1,
            filters: {
                ...filter,
            },
        };
    }

    logout(): void {
        this.showUserMenu = false;
        this.member = null;
        this.store.dispatch(new ActionOnLogout());
    }
}
