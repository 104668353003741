import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

const thaiMonthsAbbreviation = [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.',
];

@Pipe({
    name: 'nextPeriodMonthly',
})
export class NextPeriodMonthlyPipe implements PipeTransform {
    transform(nextMonth: any, args?: any): any {
        const th = moment().locale('th');
        return thaiMonthsAbbreviation[th.add(nextMonth, 'months').month()];
    }
}

@Pipe({
    name: 'nextPeriodMonthlyLength',
})
export class NextPeriodMonthlyLengthPipe implements PipeTransform {
    transform(periods: any[], nextMonth: any): any {
        const monthly = moment().add(nextMonth, 'months').format('YYYY-MM');
        const periodsMonthly = periods.filter((m) => m.monthly === monthly);
        if (!periodsMonthly.length) {
            return '0';
        }

        return periodsMonthly.length;
    }
}

@Pipe({
    name: 'nextPeriodMonthlyList',
})
export class NextPeriodMonthlyListPipe implements PipeTransform {
    transform(periods: any[], nextMonth: any): any {
        const monthly = moment().add(nextMonth, 'months').format('YYYY-MM');

        return periods.filter((m) => m.monthly === monthly);
    }
}

@Pipe({
    name: 'ProgramGoFlightNumber',
})
export class ProgramGoFlightNumberPipe implements PipeTransform {
    transform(periods: any[]): any {
        if (periods) {
            return periods[0].go_flight_number ? periods[0].go_flight_number : '-';
        } else {
            return '-';
        }
    }
}

@Pipe({
    name: 'ProgramBackFlightNumber',
})
export class ProgramBackFlightNumberPipe implements PipeTransform {
    transform(periods: any[]): any {
        if (periods) {
            return periods[0].back_flight_number ? periods[0].back_flight_number : '-';
        } else {
            return '-';
        }
    }
}

@Pipe({
    name: 'reOrderPeriodSKUs',
})
export class ReOrderPeriodSKUsPipe implements PipeTransform {
    transform(sku: any[]): any {
        if (sku.length === 0) {
            return sku;
        }

        let skusReorder = [];
        const format = [1, 2, 5, 3, 4, 6, 7, 9];
        format.forEach((element) => {
            sku.forEach((i) => {
                if (i.sku * 1 === element) {
                    skusReorder.push(i);
                    return;
                }
            });
        });
        return skusReorder;
    }
}

@Pipe({
    name: 'filterPeriodPriceSKUs',
})
export class FilterPeriodPriceSKUsPipe implements PipeTransform {
    transform(sku: any[]): any {
        if (sku.length === 0) {
            return sku;
        }

        // Filter price > 0
        sku = sku.filter((s) => s.price > 0);
        return sku;
    }
}
