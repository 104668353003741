import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './state';
import { FEATURE_NAME } from './auth-store.module';

export const selectAuthState = createFeatureSelector<AuthState>(FEATURE_NAME);

export const selectIsAuth = createSelector(selectAuthState, (state: AuthState) => state.isAuth);

export const selectLoginError = createSelector(selectAuthState, (state: AuthState) => state.error);

export const selectCurrentUser = createSelector(selectAuthState, (state: AuthState) => state.currentUser);

export const selectCurrentAgencyMember = createSelector(
    selectAuthState,
    (state: AuthState) => state.currentAgencyMember
);
