import { Component } from '@angular/core';

@Component({
    selector: 'app-unauthorized-page',
    templateUrl: './unauthorized-page.component.html',
    styleUrls: ['./unauthorized-page.component.css'],
})
export class UnauthorizedPageComponent {
    constructor() {}
}
