import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {
    public text = new BehaviorSubject('Tourwow Pro Online Booking');

    setBreadcrumb(breadcrumb: string) {
        this.text.next(breadcrumb);
    }
}
