import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BreadcrumbComponent } from '../layout/breadcrumb/breadcrumb.component';

import { MainTemplateComponent } from './main-template/main-template.component';
import { SearchTemplateComponent } from './search-template/search-template.component';
import { MainTemplateComponent as MainTemplateV2Component } from './v2/main-template/main-template.component';

@NgModule({
    declarations: [BreadcrumbComponent, SearchTemplateComponent, MainTemplateComponent, MainTemplateV2Component],
    imports: [CommonModule, RouterModule],
    exports: [SearchTemplateComponent, MainTemplateComponent, MainTemplateV2Component],
})
export class TemplateModule {}
