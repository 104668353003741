import { Component, ViewChild, Input, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-preview-pdf-modal',
    templateUrl: './preview-pdf-modal.component.html',
    styleUrls: ['./preview-pdf-modal.component.css'],
})
export class PreviewPdfModalComponent {
    @Input() title = 'Preview Invoice';
    resource: string;
    modalRef: any;

    @ViewChild('modal') modal: TemplateRef<any>;

    constructor(private modalService: BsModalService) {}

    open(src: any): void {
        this.resource = src;
        this.modalRef = this.modalService.show(this.modal, {
            class: 'modal-lg',
        });
    }

    closeModal(): void {
        if (this.modalRef) {
            this.modalRef.hide();
        }
    }
}
