<div class="app-root">
    <app-header
        [isHeaderShowBanner]="isHeaderShowBanner"
        [isHeaderShowSearchCountrires]="isHeaderShowSearchCountrires"
        [isHeaderShowSearchResultTabs]="isHeaderShowSearchResultTabs"
    >
    </app-header>

    <div id="wrapper">
        <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
</div>
