import { AuthenticatedAgencyMember, AuthenticatedUser } from 'app/shared/models/auth.model';

export interface AuthState {
    isAuth: boolean;
    currentUser: AuthenticatedUser;
    currentAgencyMember: AuthenticatedAgencyMember;
    error?: any;
}

export const initialState: AuthState = {
    isAuth: false,
    error: null,
    currentUser: null,
    currentAgencyMember: null,
};
