import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
    ActionCreateMember,
    ActionCreateMemberFailure,
    ActionCreateMemberSuccess,
    ActionDeleteMember,
    ActionDeleteMemberFailure,
    ActionDeleteMemberSuccess,
    ActionFailure,
    ActionLoadMemberInfo,
    ActionLoadMemberInfoSuccess,
    ActionLoadMemberListing,
    ActionLoadMemberListingSuccess,
    Actions as MemberActions,
    ActionTypes as MemberActionTypes,
    ActionUpdateMember,
    ActionUpdateMemberFailure,
    ActionUpdateMemberSuccess,
} from './actions';

import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { MemberService } from 'app/services/member.service';
import { MemberListing } from 'app/shared/models/members/member-listing.model';
import { MemberInfo } from 'app/shared/models/members/member-info.model';

@Injectable()
export class MemberStoreEffects {
    constructor(private actions$: Actions, private memberService: MemberService) {}

    
    loadMemberListing = createEffect(() => () =>
        this.actions$.pipe(
            ofType<MemberActions>(MemberActionTypes.LOAD_MEMBER_LISTING),
            switchMap((action: ActionLoadMemberListing) => {
                return this.memberService.getListing().pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }
                        return new ActionLoadMemberListingSuccess(new MemberListing().deserialize(resp.data));
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));

    
    loadMemberInfo = createEffect(() => () =>
        this.actions$.pipe(
            ofType<MemberActions>(MemberActionTypes.LOAD_MEMBER_INFO),
            switchMap((action: ActionLoadMemberInfo) => {
                return this.memberService.getInfo().pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }
                        return new ActionLoadMemberInfoSuccess(new MemberInfo().deserialize(resp.data));
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));

    
    createMember = createEffect(() => () =>
        this.actions$.pipe(
            ofType<MemberActions>(MemberActionTypes.CREATE_MEMBER),
            switchMap((action: ActionCreateMember) => {
                return this.memberService.create(action.payload.requestBody).pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }
                        return new ActionCreateMemberSuccess({ memberId: resp.data.id });
                    }),
                    catchError((error) => {
                        return of(new ActionCreateMemberFailure({ error: error }));
                    })
                );
            })
        ));

    
    updateMember = createEffect(() => () =>
        this.actions$.pipe(
            ofType<MemberActions>(MemberActionTypes.UPDATE_MEMBER),
            switchMap((action: ActionUpdateMember) => {
                return this.memberService.update(action.payload.memberId, action.payload.requestBody).pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }
                        return new ActionUpdateMemberSuccess({ memberId: resp.data.id });
                    }),
                    catchError((error) => {
                        return of(new ActionUpdateMemberFailure({ error: error }));
                    })
                );
            })
        ));

    
    deleteMember = createEffect(() => () =>
        this.actions$.pipe(
            ofType<MemberActions>(MemberActionTypes.DELETE_MEMBER),
            switchMap((action: ActionDeleteMember) => {
                return this.memberService.cancel(action.payload.memberId).pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }
                        return new ActionDeleteMemberSuccess({ memberId: action.payload.memberId });
                    }),
                    catchError((error) => {
                        return of(new ActionDeleteMemberFailure({ memberId: action.payload.memberId, error: error }));
                    })
                );
            })
        ));
}
