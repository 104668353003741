import { MemberInfo } from 'app/shared/models/members/member-info.model';
import { MemberListing } from 'app/shared/models/members/member-listing.model';

export interface MemberState {
    listing: MemberListing;
    info: MemberInfo;
    isLoading?: boolean;
}

export const initialState: MemberState = {
    listing: null,
    info: null,
    isLoading: false,
};
