import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
    Actions as AgencyActions,
    ActionTypes as AgencyActionTypes,
    ActionFailure,
    ActionLoadAgency,
    ActionLoadAgencySuccess,
    ActionVerifyContactEmail,
    ActionVerifyContactEmailSuccess,
    ActionUpdateContactEmail,
    ActionUpdateContactEmailSuccess,
    ActionUpdateAgency,
    ActionUpdateAgencySuccess,
    ActionUpdateAgencyFailure,
} from './actions';
import { OrderService } from 'app/services/order.service';
import { AgencyService } from 'app/services/agency.service';

import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable()
export class AgencyStoreEffects {
    constructor(
        private actions$: Actions,
        private orderService: OrderService,
        private agencyService: AgencyService,
        private router: Router,
        private ngxUiLoaderService: NgxUiLoaderService
    ) {}

    
    loadAgency = createEffect(() => () =>
        this.actions$.pipe(
            ofType<AgencyActions>(AgencyActionTypes.LOAD_AGENCY),
            switchMap((action: ActionLoadAgency) => {
                return this.orderService.getOrder(action.id).pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }
                        return new ActionLoadAgencySuccess({
                            agency: resp.data,
                        });
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));

    
    checkContactEmail = createEffect(() => () =>
        this.actions$.pipe(
            ofType<AgencyActions>(AgencyActionTypes.VERIFY_CONTACT_EMAIL),
            switchMap((action: ActionVerifyContactEmail) => {
                return this.agencyService.checkContactEmail().pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }
                        return new ActionVerifyContactEmailSuccess({
                            hasEmailContact: true,
                        });
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));

    
    updateContactEmail = createEffect(() => () =>
        this.actions$.pipe(
            ofType<AgencyActions>(AgencyActionTypes.UPDATE_CONTACT_EMAIL),
            switchMap((action: ActionUpdateContactEmail) => {
                return this.agencyService.updateContactEmail(action.email).pipe(
                    map((resp) => {
                        if (resp.status === 'error') {
                            throw resp;
                        }
                        return new ActionUpdateContactEmailSuccess();
                    }),
                    catchError((error) => {
                        return of(new ActionFailure(error));
                    })
                );
            })
        ));

    
    updateCAgency = createEffect(() => () =>
        this.actions$.pipe(
            ofType<AgencyActions>(AgencyActionTypes.UPDATE_AGENCY),
            switchMap((action: ActionUpdateAgency) => {
                this.ngxUiLoaderService.start();
                return this.agencyService.update(action.payload.agencyId, action.payload.body).pipe(
                    map((resp) => {
                        this.ngxUiLoaderService.stop();
                        if (resp.status === 'error') {
                            throw resp;
                        }
                        return new ActionUpdateAgencySuccess({ agencyId: action.payload.agencyId });
                    }),
                    catchError((error) => {
                        return of(new ActionUpdateAgencyFailure({ error: error }));
                    })
                );
            })
        ));
}
