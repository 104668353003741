import { Action } from '@ngrx/store';
import { AgencyUpdatePayload } from 'app/services/agency.service';
import { IAgency } from 'app/shared/models/agency.model';

export enum ActionTypes {
    LOAD_AGENCY = '[Agency] Load Agency',
    LOAD_AGENCY_SUCCESS = '[Agency] Load Agency Success',
    VERIFY_CONTACT_EMAIL = '[Agency] Verify check agency contact email',
    VERIFY_CONTACT_EMAIL_SUCCESS = '[Agency] Verify check agency contact email success.',
    UPDATE_CONTACT_EMAIL = '[Agency] Update agency contact email.',
    UPDATE_CONTACT_EMAIL_SUCCESS = '[Agency] Update agency contact email success.',
    UPDATE_AGENCY = '[Agency] Update agency.',
    UPDATE_AGENCY_SUCESS = '[Agency] Update agency success.',
    UPDATE_AGENCY_FAILURE = '[Agency] Update agency failure.',
    FAILURE = '[Agency] Failure',
}

/** Load agency */
export class ActionLoadAgency implements Action {
    readonly type = ActionTypes.LOAD_AGENCY;
    constructor(public id: any) {}
}

export class ActionLoadAgencySuccess implements Action {
    readonly type = ActionTypes.LOAD_AGENCY_SUCCESS;
    constructor(public payload: { agency: IAgency[] }) {}
}

/** Verify contact email */
export class ActionVerifyContactEmail implements Action {
    readonly type = ActionTypes.VERIFY_CONTACT_EMAIL;
    constructor() {}
}

export class ActionVerifyContactEmailSuccess implements Action {
    readonly type = ActionTypes.VERIFY_CONTACT_EMAIL_SUCCESS;
    constructor(public payload: { hasEmailContact: boolean }) {}
}

/** Update contact email */
export class ActionUpdateContactEmail implements Action {
    readonly type = ActionTypes.UPDATE_CONTACT_EMAIL;
    constructor(public email: string) {}
}

export class ActionUpdateContactEmailSuccess implements Action {
    readonly type = ActionTypes.UPDATE_CONTACT_EMAIL_SUCCESS;
    constructor() {}
}

/** Update agency */
export class ActionUpdateAgency implements Action {
    readonly type = ActionTypes.UPDATE_AGENCY;
    constructor(public payload: { agencyId: number; body: AgencyUpdatePayload }) {}
}

export class ActionUpdateAgencySuccess implements Action {
    readonly type = ActionTypes.UPDATE_AGENCY_SUCESS;
    constructor(public payload: { agencyId: number }) {}
}

export class ActionUpdateAgencyFailure implements Action {
    readonly type = ActionTypes.UPDATE_AGENCY_FAILURE;
    constructor(public payload: { error: any }) {}
}

/** etc */
export class ActionFailure implements Action {
    readonly type = ActionTypes.FAILURE;
    constructor(public error: any) {}
}

export type Actions =
    | ActionLoadAgency
    | ActionLoadAgencySuccess
    | ActionVerifyContactEmail
    | ActionVerifyContactEmailSuccess
    | ActionUpdateContactEmail
    | ActionUpdateContactEmailSuccess
    | ActionUpdateAgency
    | ActionUpdateAgencySuccess
    | ActionUpdateAgencyFailure
    | ActionFailure;
